import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Footer from '../component/HomeFooter';
import Navbar from '../component/Navbar';
import { Link } from 'react-router-dom';
import Topbar from '../component/Topbar';
import Nav from '../component/Nav';

class Policy extends Component {
    render() {
        return (
            <div>
                <div>
                    <Topbar />
                    <Navbar />
                    <Nav />

                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                        <div className='font-semibold text-xl'>{this.props.t("Policy.Privacy Policy")}</div>
                        <div className='text-sm text-[#363231] mb-2'>{this.props.t("Policy.Privacy Policy1")}</div>
                        <div className='my-2'>
                            <ol className='list-disc ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Privacy Policy Changes")}</div><div className='my-1'>{this.props.t("Policy.Privacy Policy Changes1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Consent and Agreement")}</div><div className='my-1'>{this.props.t("Policy.Consent and Agreement1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Use of the Website")}</div><div className='my-1'>{this.props.t("Policy.Use of the Website1")}</div></div></li>
                            </ol>
                        </div>
                        <div className='my-2'>
                            <ol className='list-decimal ml-4 text-sm'>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Collection Personally Information")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information1")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information2")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information3")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information4")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information5")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information6")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information7")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information8")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Collection Personally Information9")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Profile Data")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Profile Data1")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Profile Data2")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Profile Data3")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Profile Data4")}</div>
                                    <div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Cookies")}</div><div className='my-1'>{this.props.t("Policy.Cookies1")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Sharing of Personal Information")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information1")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information2")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information3")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Sharing of Personal Information4")}</div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Links to Other Sites")}</div><div className='my-1'>{this.props.t("Policy.Links to Other Sites1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Security Precautions")}</div><div className='my-1'>{this.props.t("Policy.Security Precautions1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Choice/Opt-Out")}</div><div className='my-1'>{this.props.t("Policy.Choice/Opt-Out1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Advertisements")}</div><div className='my-1'>{this.props.t("Policy.Advertisements1")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Your Consent")}</div><div className='my-1'>{this.props.t("Policy.Your Consent1")}</div><div className='my-1'>{this.props.t("Policy.Your Consent2")}</div></div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Grievance Officer")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer1")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer2")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer3")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer4")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer5")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer6")}</div>
                                    <div className='my-1'>{this.props.t("Policy.Grievance Officer7")} <Link className='text-blue-500'>{this.props.t("Policy.Grievance Officer8")}</Link></div>
                                </div></li>
                                <li className='my-2'><div className=''><div className='font-semibold whitespace-nowrap'>{this.props.t("Policy.Questions")}</div><div className='my-1'>{this.props.t("Policy.Questions1")}</div></div></li>
                            </ol>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default withTranslation()(Policy);