import React, { Component } from 'react'
import AppRoutes from './AppRoutes'
import './App.css'
import { connect } from 'react-redux';
import { getAffiliateAddresses, getAffiliateInfo, getCategories, getTrackingId, setToken } from './actions';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCompleted: false
    }
  }
  componentDidMount() {
    let DukandaarAffiliate = window.localStorage.getItem('Dukandaar-Affiliate');
    if (DukandaarAffiliate) {
      DukandaarAffiliate = JSON.parse(DukandaarAffiliate);
      let token = DukandaarAffiliate.token;
      if (token) {
        this.props.loginDispatch(token);
      }
      else {
        setTimeout(() => {
          this.setState({ loadingCompleted: true });
        }, 1500);
      }
    }
    else {
      setTimeout(() => {
        this.setState({ loadingCompleted: true });
      }, 1500);
    }
  }
  componentDidUpdate() {
    if (this.props.token && !this.props.affiliate_info) {
      this.props.getAffiliateInfoDispatch(this.props.token);
    }
    if (this.props.token && !this.props.getCategories) {
      this.props.getAllCategoriesDispatch(this.props.token);
    }
    if (this.props.token && this.props.affiliate_info && !this.props.all_addresses) {
      this.props.getAllAddressesDispatch(this.props.token);
    }
    if (this.props.token && this.props.affiliate_info && !this.props.tracking_id) {
      this.props.getTrackingIdDispatch(this.props.token);
    }
    if (this.props.token && this.props.affiliate_info && this.props.all_addresses && this.props.tracking_id && !this.state.loadingCompleted) {
      this.setState({ loadingCompleted: true });
    }
  }
  render() {
    return (
      <div>
        <AppRoutes token={this.props.token} loadingCompleted={this.state.loadingCompleted} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  const loginDispatch = (token) => {
    dispatch(setToken(token));
  };
  const getAffiliateInfoDispatch = (token) => {
    dispatch(getAffiliateInfo(token));
  }
  const getAllAddressesDispatch = (token) => {
    dispatch(getAffiliateAddresses(token));
  }
  const getTrackingIdDispatch = (token) => {
    dispatch(getTrackingId(token));
  }
  const getAllCategoriesDispatch = (token) => {
    dispatch(getCategories(token));
  }
  return {
    loginDispatch,
    getAffiliateInfoDispatch,
    getAllAddressesDispatch,
    getTrackingIdDispatch,
    getAllCategoriesDispatch
  };
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
    affiliate_info: state.affiliate_info,
    all_addresses: state.all_addresses,
    tracking_id: state.tracking_id
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)((App));