import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class ConsolSummery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: {

                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            },
            showCalendar: false,
            product: '',
            loading: true
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    handleProductChange = (event) => {
        this.setState({ product: event.target.value });
    };
    handleDateRangeChange = (dateRange) => {
        this.setState({ dateRange });
    };

    toggleCalendar = () => {
        this.setState((prevState) => ({
            showCalendar: !prevState.showCalendar
        }));
    };
    render() {
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                <Notice loading={this.state.loading} />
                                {this.state.loading ?
                                    <>

                                        <div className="w-[30%] my-7"><Skeleton /></div>
                                        <div className='w-full h-[300px] md:h-[450px]'><Skeleton className='w-full h-[300px] md:h-[450px]' /></div>

                                    </>
                                    :
                                    <>
                                        <div className='my-7'>
                                            <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                <Link to="/report" className="text-[#407BF9] text-xs font-medium">
                                                    {this.props.t("Report")}
                                                </Link>
                                                <Link
                                                    className="text-[#407BF9] text-xs font-bold"
                                                    style={{ color: "#000000" }}
                                                >
                                                    {this.props.t("Consolidated Summary")}
                                                </Link>
                                            </Breadcrumbs>
                                        </div>
                                        <div className='bg-[#D7D7D72E] border w-full h-[300px] md:h-[450px] border-[#F89C15] rounded-xl '>
                                            <div className='border-b border-[#B3B3B3] p-2 md:p-4'>
                                                <div className=' flex justify-between'>
                                                    <div><h1 className='md:text-2xl text-sm font-medium'>{this.props.t("Consolidated Summary")}</h1></div>
                                                    <div className='pt-4 md1:flex hidden'>
                                                        <div className=''>
                                                            <button className='bg-[#D9DEE4] font-bold text-xs p-2 rounded-2xl flex text-[#565656]' onClick={this.toggleCalendar}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                                                    <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z" />
                                                                    <path fillRule="evenodd" d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z" clipRule="evenodd" />
                                                                </svg>

                                                                {this.props.t("ConsolSummary.Date Range")} <span className='font-normal'>{this.props.t("ConsolSummary.Yesterday")}</span></button>

                                                            <div className=''>
                                                                {this.state.showCalendar && (
                                                                    <div className='absolute'>
                                                                        <DateRangePicker
                                                                            ranges={[this.state.dateRange]}
                                                                            onChange={this.handleDateRangeChange}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='ml-1'>
                                                            <select
                                                                value={this.state.product}
                                                                onChange={this.handleProductChange}
                                                                className='p-2 rounded-2xl bg-[#D9DEE4] text-[#565656] text-xs drop-shadow-[#00000040]'
                                                            >
                                                                <option>{this.props.t("ConsolSummary.Local Currency")} </option>
                                                                <option>{this.props.t("ConsolSummary.BRl - Brazilian Real")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                                <option>{this.props.t("ConsolSummary.AED - Emirati Dirham")}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=''><span className='border-b-2 border-[#F89C15] text-xs md:text-sm font-medium'>{this.props.t("ConsolSummary.Total")}</span></div>
                                            </div>
                                            <div>
                                                <div className='text-right m-2 md1:hidden block'>
                                                    <select
                                                        value={this.state.product}
                                                        onChange={this.handleProductChange}
                                                        className='md:p-2 p-1 rounded-2xl bg-[#D9DEE4] text-[#565656] text-[10px] md:text-xs drop-shadow-[#00000040]'
                                                    >
                                                        <option>{this.props.t("ConsolSummary.Date Range Yesterday")}</option>
                                                        <option>{this.props.t("ConsolSummary.Yesterday")}</option>
                                                        <option>{this.props.t("ConsolSummary.Last 7 Days")}</option>
                                                        <option>{this.props.t("ConsolSummary.Last 30 Days")}</option>
                                                        <option>{this.props.t("ConsolSummary.This Week")}</option>
                                                        <option>{this.props.t("ConsolSummary.Last Week")}</option>
                                                        <option>{this.props.t("ConsolSummary.This Month")}</option>
                                                        <option>{this.props.t("ConsolSummary.Last Month")}</option>
                                                        <option>{this.props.t("ConsolSummary.This Quarter")}</option>
                                                        <option>{this.props.t("ConsolSummary.Last Quarter")}</option>
                                                    </select>
                                                </div>

                                                <div className='flex justify-center items-center'>
                                                    <span className='text-xs mt-24'>{this.props.t("ConsolSummary.Note")}</span></div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
export default withTranslation()(ConsolSummery);