import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import AlertIcon from '../assets/icons/Alert.png'
import leftIcon from '../assets/icons/left-chevron.png'
import rightIcon from '../assets/icons/right-chevron.png'
import captcha from '../assets/images/captcha.png'
import { connect } from 'react-redux'
import { GrClose } from 'react-icons/gr'
import { toast } from 'react-hot-toast'
import { getAffiliateAddresses, getAffiliateInfo } from '../actions'
import { ThreeDots } from 'react-loader-spinner'
import { Navigate } from 'react-router-dom'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'
function preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
}

class OnBoarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: null, //ACCOUNT_INFORMATION , WEB_APP_LIST, PROFILE, START
            completedTabs: [],
            currentAddress: null,
            modelOpen: false,
            workingAddress: null,
            workingType: null,
            name: "",
            flat_house_building: "",
            area: "",
            landmark: "",
            town_city: "",
            state: "",
            pincode: "",
            address_type: "",
            disableProceedButton: false,
            nextButtonDisabled: false,
            websiteLinks: [],
            appLinks: [],
            webLink: "",
            appLink: "",
            registeringAsCompany: false,
            associate_id: "",
            companyName: "",
            companyDescription: "",
            hearAboutUs: "",
            websiteType: "",
            navigate: null,
            loading: true
        }
    }
    componentDidMount() {
        document.querySelector("body").removeEventListener("wheel", preventScroll, { passive: false });
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
        if (this.props.affiliate_info && !this.state.activeTab) {
            if (this.props.affiliate_info.affiliate_profile_info) {
                if (this.props.affiliate_info.affiliate_bank_details_info) {
                    this.setState({ navigate: '/dashboard' });
                }
                else {
                    this.setState({ navigate: '/banking-details' });
                }
            }
            else if (this.props.affiliate_info.affiliate_web_app_info) this.setState({ activeTab: "PROFILE", completedTabs: ['ACCOUNT_INFORMATION', 'WEB_APP_LIST'] });
            else if (this.props.affiliate_info.affiliate_account_info) this.setState({ activeTab: "WEB_APP_LIST", completedTabs: ['ACCOUNT_INFORMATION'] });
            else this.setState({ activeTab: "ACCOUNT_INFORMATION" });
        }
        if (!this.props.token) {
            this.setState({ navigate: '/' });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
        if (this.props.affiliate_info && !this.state.activeTab) {
            if (this.props.affiliate_info.affiliate_profile_info) {
                this.setState({ activeTab: "START", completedTabs: ['ACCOUNT_INFORMATION', 'WEB_APP_LIST', 'PROFILE'] });
            }
            else if (this.props.affiliate_info.affiliate_web_app_info) this.setState({ activeTab: "PROFILE", completedTabs: ['ACCOUNT_INFORMATION', 'WEB_APP_LIST'] });
            else if (this.props.affiliate_info.affiliate_account_info) this.setState({ activeTab: "WEB_APP_LIST", completedTabs: ['ACCOUNT_INFORMATION'] });
            else this.setState({ activeTab: "ACCOUNT_INFORMATION" });
        }
        if (!this.state.currentAddress && this.props.all_addresses && this.props.all_addresses.length > 0) {
            this.setState({
                currentAddress: this.props.all_addresses[0]
            })
        }
        if (this.state.modelOpen) {
            document.querySelector("body").addEventListener("wheel", preventScroll, { passive: false });
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
        if (!this.state.modelOpen) {
            document.querySelector("body").removeEventListener("wheel", preventScroll, { passive: false });
        }
        if (!this.state.modelOpen && (this.state.name !== "" ||
            this.state.flat_house_building !== "" ||
            this.state.area !== "" ||
            this.state.landmark !== "" ||
            this.state.town_city !== "" ||
            this.state.state !== "" ||
            this.state.pincode !== "" ||
            this.state.address_type !== "")) {
            this.setState({
                name: "",
                flat_house_building: "",
                area: "",
                landmark: "",
                town_city: "",
                state: "",
                pincode: "",
                address_type: ""
            })
        }
    }
    render() {
        const proceed = () => {
            if (this.state.workingType === "new-address") {
                if (
                    this.state.name === "" ||
                    this.state.flat_house_building === "" ||
                    this.state.area === "" ||
                    this.state.landmark === "" ||
                    this.state.town_city === "" ||
                    this.state.state === "" ||
                    this.state.pincode === "" ||
                    this.state.address_type === ""
                ) {
                    toast.error("Please fill the form correctly")
                }
                else {
                    this.setState({ disableProceedButton: true })
                    var myHeaders = new Headers();
                    myHeaders.append("token", this.props.token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "country": "India",
                        "name": this.state.name,
                        "state": this.state.state,
                        "town_city": this.state.town_city,
                        "area": this.state.area,
                        "flat_house_building": this.state.flat_house_building,
                        "pincode": this.state.pincode,
                        "address_type": this.state.address_type,
                        "landmark": this.state.landmark,
                        "default_address": false
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/customer_add_address`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    toast.success(result.msg)
                                    this.props.getAllAddressesDispatch(this.props.token);
                                    this.setState({
                                        modelOpen: false,
                                        workingAddress: null,
                                        workingType: null,
                                        name: "",
                                        flat_house_building: "",
                                        area: "",
                                        landmark: "",
                                        town_city: "",
                                        state: "",
                                        pincode: "",
                                        address_type: ""
                                    });
                                }
                                else {
                                    toast.error(result.msg)
                                }
                                this.setState({ disableProceedButton: false })
                            }
                        })
                        .catch(error => {
                            toast.error("Server Error !")
                        });
                }
            }
            else {
                if (
                    this.state.name === "" ||
                    this.state.flat_house_building === "" ||
                    this.state.area === "" ||
                    this.state.landmark === "" ||
                    this.state.town_city === "" ||
                    this.state.state === "" ||
                    this.state.pincode === "" ||
                    this.state.address_type === ""
                ) {
                    toast.error("Please fill the form correctly")
                }
                else {
                    this.setState({ disableProceedButton: true })
                    var myHeaders = new Headers();
                    myHeaders.append("token", this.props.token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "country": "India",
                        "name": this.state.name,
                        "state": this.state.state,
                        "town_city": this.state.town_city,
                        "area": this.state.area,
                        "flat_house_building": this.state.flat_house_building,
                        "pincode": this.state.pincode,
                        "address_type": this.state.address_type,
                        "landmark": this.state.landmark,
                        "default_address": false
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/customer_edit_address/${this.state.workingAddress.customers_billing_address_id}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    toast.success(result.msg)
                                    this.props.getAllAddressesDispatch(this.props.token);
                                    var myHeaders = new Headers();
                                    myHeaders.append("token", this.props.token);

                                    var requestOptions = {
                                        method: 'GET',
                                        headers: myHeaders,
                                        redirect: 'follow'
                                    };

                                    fetch(`${process.env.REACT_APP_BASE_URL}/customer_single_address/${this.state.workingAddress.customers_billing_address_id}`, requestOptions)
                                        .then(response => response.json())
                                        .then(result => {
                                            if (result.code === 401 || result.code === 407) {
                                                this.props.logoutDispatch();
                                            }
                                            else {
                                                if (result.status === "Success" || result.status === "success") {
                                                    this.setState({
                                                        currentAddress: result.data,
                                                        modelOpen: false,
                                                        workingAddress: null,
                                                        workingType: null,
                                                        name: "",
                                                        flat_house_building: "",
                                                        area: "",
                                                        landmark: "",
                                                        town_city: "",
                                                        state: "",
                                                        pincode: "",
                                                        address_type: ""
                                                    })
                                                }
                                            }
                                        })
                                        .catch(error => console.log('error', error));
                                }
                                else {
                                    toast.error(result.msg)
                                }
                                this.setState({ disableProceedButton: false });
                            }
                        })
                        .catch(error => {
                            toast.error("Server Error !")
                        });
                }
            }
        }
        const changeCurrentAddress = (address) => {
            this.setState({ currentAddress: address })
        }
        const moveToNextStep = () => {
            if (this.state.activeTab === "ACCOUNT_INFORMATION") {
                if (this.state.currentAddress) {
                    this.setState({ nextButtonDisabled: true });
                    var myHeaders = new Headers();
                    myHeaders.append("token", this.props.token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "customers_billing_address_id": this.state.currentAddress.customers_billing_address_id
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_onboard_account_info`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    toast.success(result.msg)
                                    this.setState({ activeTab: "WEB_APP_LIST", nextButtonDisabled: false });
                                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                    this.props.getAffiliateInfoDispatch(this.props.token);
                                }
                                else {
                                    toast.error(result.msg)
                                    this.setState({ nextButtonDisabled: false });
                                }
                            }
                        })
                        .catch(error => console.log('error', error));
                }
                else {
                    toast.error("Please select current address !")
                }
            }
            else if (this.state.activeTab === "WEB_APP_LIST") {
                this.setState({ nextButtonDisabled: true });
                let allLinks = [...this.state.websiteLinks, ...this.state.appLinks];
                if (allLinks.length > 0) {

                    var myHeaders = new Headers();
                    myHeaders.append("token", this.props.token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "web_app_links": allLinks
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_web_app_info`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    toast.success(result.msg)
                                    this.setState({ activeTab: "PROFILE", nextButtonDisabled: false });
                                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                    this.props.getAffiliateInfoDispatch(this.props.token);
                                }
                                else {
                                    toast.error(result.msg)
                                    this.setState({ nextButtonDisabled: false });
                                }
                            }
                        })
                        .catch(error => console.log('error', error));
                }
                else {
                    toast.error("Please Provide Web Links!")
                    this.setState({ nextButtonDisabled: false })
                }
            }
            else if (this.state.activeTab === "PROFILE") {
                this.setState({ nextButtonDisabled: true });
                let data = {};
                if (this.state.registeringAsCompany) {
                    if (this.state.associate_id === "" || this.state.websiteType === "" || this.state.companyDescription === "" || this.state.companyName === "" || this.state.hearAboutUs === "") {
                        console.log(this.state.associate_id, this.state.websiteType, this.state.companyDescription, this.state.companyName, this.state.hearAboutUs);
                        toast.error("Please enter all details !")
                        this.setState({ nextButtonDisabled: false });
                    }
                    else {
                        data = {
                            type_company: true,
                            company: this.state.companyName,
                            description: this.state.companyDescription,
                            hear_about_us: this.state.hearAboutUs,
                            affiliate_store_id: this.state.associate_id,
                            website_type: this.state.websiteType,
                        }
                        var myHeaders = new Headers();
                        myHeaders.append("token", this.props.token);
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify(data);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_profile_info`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 401 || result.code === 407) {
                                    this.props.logoutDispatch();
                                }
                                else {
                                    console.log(result);
                                    if (result.status === "Success" || result.status === "success") {
                                        toast.success(result.msg)
                                        this.setState({ activeTab: "START", nextButtonDisabled: false });
                                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                        this.props.getAffiliateInfoDispatch(this.props.token);
                                    }
                                    else {
                                        toast.error(result.msg)
                                        this.setState({ nextButtonDisabled: false });
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                }
                else {
                    if (this.state.associate_id === "") {
                        toast.error("Please enter preferred Associate id !")
                        this.setState({ nextButtonDisabled: false });
                    }
                    else {
                        data = {
                            type_company: false,
                            affiliate_store_id: this.state.associate_id,
                        }
                        var myHeaders = new Headers();
                        myHeaders.append("token", this.props.token);
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify(data);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_profile_info`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 401 || result.code === 407) {
                                    this.props.logoutDispatch();
                                }
                                else {
                                    console.log(result);
                                    if (result.status === "Success" || result.status === "success") {
                                        toast.success(result.msg)
                                        this.setState({ activeTab: "START", nextButtonDisabled: false });
                                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                                        this.props.getAffiliateInfoDispatch(this.props.token);
                                    }
                                    else {
                                        toast.error(result.msg)
                                        this.setState({ nextButtonDisabled: false });
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                }
            }
            else if (this.state.activeTab === "START") {
                this.setState({ navigate: "/dashboard" });
            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <>
                            {this.state.navigate && <Navigate to={this.state.navigate} />}
                            {this.props.affiliate_info &&
                                <>
                                    {
                                        this.state.modelOpen &&
                                        <div className='w-[100%] h-[1000vh] top-0 left-0 absolute bg-[#00000050] z-[80]'>
                                            <div className='w-[100%] h-[100vh] flex justify-center items-center'>
                                                <div className='w-full max-w-[450px] bg-white h-fit m-auto px-4 py-2 rounded-md'>
                                                    <div>
                                                        <div className='flex justify-between items-center'>
                                                            <p className='text-lg font-medium'>{this.state.workingType === "new-address" ? 'Add New Address' : 'Edit Address'}</p>
                                                            <GrClose size={15} color='black' className='cursor-pointer' onClick={() => this.setState({ modelOpen: false, workingType: null, workingAddress: null })} />
                                                        </div>
                                                        <div className='p-2'>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="name">{this.props.t("OnBoarding.Name")}</label>
                                                                <input type="text" name='name' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="flat_house">{this.props.t("OnBoarding.Flat, House no., Building, Company, Apartment")}</label>
                                                                <input type="text" name='flat_house' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.flat_house_building} onChange={(e) => this.setState({ flat_house_building: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="area">{this.props.t("OnBoarding.Area, Street, Sector, Village")}</label>
                                                                <input type="text" name='area' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.area} onChange={(e) => this.setState({ area: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="landmark">{this.props.t("OnBoarding.Landmark")}</label>
                                                                <input type="text" name='landmark' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.landmark} onChange={(e) => this.setState({ landmark: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="town_city">{this.props.t("OnBoarding.Town/City")}</label>
                                                                <input type="text" name='town_city' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.town_city} onChange={(e) => this.setState({ town_city: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="state">{this.props.t("OnBoarding.State")}</label>
                                                                <input type="text" name='state' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="pincode">{this.props.t("OnBoarding.Pincode")}</label>
                                                                <input type="number" name='pincode' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.pincode} onChange={(e) => this.setState({ pincode: e.target.value })} />
                                                            </div>
                                                            <div>
                                                                <label className='text-xs font-medium ml-1 mb-1' htmlFor="address_type">{this.props.t("OnBoarding.Address Type")}</label>
                                                                <input type="text" name='address_type' className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]' value={this.state.address_type} onChange={(e) => this.setState({ address_type: e.target.value })} />
                                                            </div>
                                                            <button type="submit" className={`px-2 py-1 text-xs text-black rounded-full font-medium box-shadow mt-2 relative ${this.state.disableProceedButton ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={() => proceed()} >
                                                                <div className={`${!this.state.disableProceedButton ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    {this.props.t("OnBoarding.Proceed")}
                                                                </div>
                                                                <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.disableProceedButton ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    <ThreeDots
                                                                        height="25"
                                                                        width="30"
                                                                        radius="9"
                                                                        color="#000"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div>
                                        <Topbar />
                                        <div className='mt-[48px] sm:mt-[68px] mb-[20px] px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%]'>
                                            <div>
                                                <div className='my-[20px] text-2xl font-medium text-center'>{this.props.t("OnBoarding.Creating Your Dukkandaar Associate Account")}</div>
                                                <div className='relative sm:block hidden'>
                                                    <div className='bg-[#697475] h-[1px] w-full'></div>
                                                    <div className='flex w-full justify-between items-center absolute top-[50%] left-0 translate-y-[-50%] text-xs font-medium'>
                                                        <div className={`px-2 bg-white ${this.state.activeTab === "ACCOUNT_INFORMATION" && 'text-[#FF9500]'} ${this.state.completedTabs.includes('ACCOUNT_INFORMATION') && 'text-[#128400]'}`}>{this.props.t("OnBoarding.Account Information")}</div>
                                                        <div className={`px-2 bg-white ${this.state.activeTab === "WEB_APP_LIST" && 'text-[#FF9500]'} ${this.state.completedTabs.includes('WEB_APP_LIST') && 'text-[#128400]'}`}>{this.props.t("OnBoarding.Website and Mobile App List")}</div>
                                                        <div className={`px-2 bg-white ${this.state.activeTab === "PROFILE" && 'text-[#FF9500]'} ${this.state.completedTabs.includes('PROFILE') && 'text-[#128400]'}`}>{this.props.t("OnBoarding.Profile")}</div>
                                                        <div className={`px-2 bg-white ${this.state.activeTab === "START" && 'text-[#FF9500]'} ${this.state.completedTabs.includes('START') && 'text-[#128400]'}`}>{this.props.t("OnBoarding.Start Using Associates Central")}</div>
                                                    </div>
                                                </div>
                                                <div className='my-[20px] p-[20px] bg-[#407BF92E] border-2 border-[#F89C15] rounded-xl'>
                                                    <div className='flex items-center text-xs'>
                                                        <img src={AlertIcon} className='w-[15px]' alt="" />
                                                        <p className='ml-[7px] font-medium'>{this.props.t("OnBoarding.Importance Notice")}</p>
                                                    </div>
                                                    <ul className='ml-[40px] list-disc text-[10px] font-medium leading-4 mt-1'>
                                                        <li>{this.props.t("OnBoarding.Notice1")} <u>{this.props.t("Tax Information")}</u>, {this.props.t("OnBoarding.note1")}.</li>
                                                        <li>{this.props.t("OnBoarding.Notice1")} <u>{this.props.t("Payment Information")}</u>, {this.props.t("OnBoarding.note2")}.</li>
                                                    </ul>
                                                </div>
                                                {
                                                    this.state.activeTab !== "START" &&
                                                    <div className='my-[20px]'>
                                                        <div className='text-[#D90000] text-sm font-medium mx-3'>
                                                            {this.state.activeTab === "ACCOUNT_INFORMATION" && "Your Account Information"}
                                                            {this.state.activeTab === "WEB_APP_LIST" && "Your Websites and Apps"}
                                                            {this.state.activeTab === "PROFILE" && "PROFILE"}
                                                        </div>
                                                        <div className='my-[5px] h-[1px] bg-[#697475] w-full'></div>
                                                        <div className='text-sm font-medium mx-3'>{this.props.t("OnBoarding.Important")}: <span className='text-[#407BF9]'>{this.props.t("OnBoarding.instructions")}.</span></div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='pt-[10px] px-3'>
                                                {
                                                    this.state.activeTab === "ACCOUNT_INFORMATION" &&
                                                    <div className='text-sm'>
                                                        <div>
                                                            <div className='font-medium'>{this.props.t("OnBoarding.Ques1")}?</div>
                                                            <div className='text-[#697475] text-xs'>{this.props.t("OnBoarding.Ans1")}.</div>
                                                        </div>
                                                        <div className='my-[20px] w-full sm:flex justify-between'>
                                                            <div className='w-full h-fit sm:w-[200px] sm:h-[200px] border border-[#697475] rounded-md text-xs mb-2 sm:mb-0'>
                                                                <div className='font-medium p-1 border-b border-[#697475]'>{this.props.t("OnBoarding.Current Address")}</div>
                                                                {
                                                                    this.state.currentAddress &&
                                                                    <div className='p-1'>
                                                                        <div className='font-medium'>{this.state.currentAddress.name || this.props.affiliate_info.name},</div>
                                                                        <div>{this.state.currentAddress.flat_house_building}, {this.state.currentAddress.landmark},</div>
                                                                        <div>{this.state.currentAddress.area},</div>
                                                                        <div>{this.state.currentAddress.town_city}, {this.state.currentAddress.state} - {this.state.currentAddress.pincode}</div>
                                                                        <div>{this.state.currentAddress.country}</div>
                                                                        <div>{this.props.t("OnBoarding.Phone number")}: {this.state.currentAddress.number}</div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <div className='w-full sm:w-[calc(100%-210px)] flex-shrink h-[200px] overflow-hidden border border-[#697475] rounded-md flex flex-col'>
                                                                <div className='text-xs font-medium p-1 border-b border-[#697475] flex justify-between items-center'>
                                                                    <p>{this.props.t("OnBoarding.Select Another Address")}</p>
                                                                    <button onClick={() => this.setState({ modelOpen: true, workingType: "new-address" })} className='text-[10px] bg-[#FFD814] py-1 px-2 rounded-md'>{this.props.t("OnBoarding.Add Address")}</button>
                                                                </div>
                                                                <div className='text-xs h-full flex-shrink overflow-scroll no-scrollbar'>
                                                                    {
                                                                        (this.props.all_addresses && this.props.all_addresses.length > 0) ? this.props.all_addresses.map((address, i) => {
                                                                            if (i % 2 === 0) return (
                                                                                <div className='border-b border-[#697475] p-1 bg-[#eeeeee80] line-clamp-2 relative addressCard' key={address.customers_billing_address_id}>
                                                                                    <div>
                                                                                        <b>{address.name || this.props.affiliate_info.name}</b>, {address.flat_house_building}, {address.area}, {address.landmark}, {address.town_city}, {address.state} - {address.pincode}, {address.country}
                                                                                    </div>
                                                                                    <div className='absolute top-0 left-0 w-full h-full bg-[#ffffff90] flex justify-center items-center opacity-0 hoverVisible'>
                                                                                        <div className='px-2 text-xs border border-white bg-[#FFD814] text-black font-medium cursor-pointer rounded-full' onClick={() => changeCurrentAddress(address)} key={address.customers_billing_address_id}>{this.props.t("OnBoarding.Select")}</div>
                                                                                        <div className='px-2 text-xs border border-white bg-blue-600 text-white font-medium cursor-pointer rounded-full ml-2' onClick={() => {
                                                                                            this.setState({
                                                                                                modelOpen: true, workingType: "edit-address", workingAddress: address,
                                                                                                name: address.name || this.props.affiliate_info.name,
                                                                                                flat_house_building: address.flat_house_building,
                                                                                                area: address.area,
                                                                                                landmark: address.landmark,
                                                                                                town_city: address.town_city,
                                                                                                state: address.state,
                                                                                                pincode: address.pincode,
                                                                                                address_type: address.address_type,
                                                                                            });
                                                                                        }}>{this.props.t("OnBoarding.Edit")}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            else return (
                                                                                <div className='border-b border-[#697475] p-1 line-clamp-2 relative addressCard' key={address.customers_billing_address_id}>
                                                                                    <div>
                                                                                        <b>{address.name || this.props.affiliate_info.name}</b>, {address.flat_house_building}, {address.area}, {address.landmark}, {address.town_city}, {address.state} - {address.pincode}, {address.country}
                                                                                    </div>
                                                                                    <div className='absolute top-0 left-0 w-full h-full bg-[#ffffff90] flex justify-center items-center opacity-0 hoverVisible'>
                                                                                        <div className='px-2 text-xs border border-white bg-[#FFD814] text-black font-medium cursor-pointer rounded-full' onClick={() => changeCurrentAddress(address)} key={address.customers_billing_address_id}>{this.props.t("OnBoarding.Select")}</div>
                                                                                        <div className='px-2 text-xs border border-white bg-blue-600 text-white font-medium cursor-pointer rounded-full ml-2' onClick={() => {
                                                                                            this.setState({
                                                                                                modelOpen: true, workingType: "edit-address", workingAddress: address,
                                                                                                name: address.name || this.props.affiliate_info.name,
                                                                                                flat_house_building: address.flat_house_building,
                                                                                                area: address.area,
                                                                                                landmark: address.landmark,
                                                                                                town_city: address.town_city,
                                                                                                state: address.state,
                                                                                                pincode: address.pincode,
                                                                                                address_type: address.address_type,
                                                                                            });
                                                                                        }}>{this.props.t("OnBoarding.Edit")}</div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }) :
                                                                            <div className='p-1'>{this.props.t("OnBoarding.No address found")}</div>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.activeTab === "WEB_APP_LIST" &&
                                                    <div>
                                                        <div className='text-xs text-[#697475]'>{this.props.t("OnBoarding.plan")}.</div>
                                                        <div className='sm:border border-[#697475] rounded-lg sm:my-[20px] flex flex-wrap max-w-[400px] sm:max-w-none'>
                                                            <div className='py-4 sm:p-4 w-full max-w-[400px] sm:max-w-none sm:w-[50%]'>
                                                                <div className='text-sm font-medium'>{this.props.t("OnBoarding.Enter Your Website(s)")}</div>
                                                                <div className='my-[5px] flex text-[10px]'>
                                                                    <input className='flex-shrink py-1 px-2 border border-[#B3B3B3] bg-[#F0F2F2] rounded-full w-full box-shadow' placeholder='Website link' value={this.state.webLink} onChange={(e) => this.setState({ webLink: e.target.value })} />
                                                                    <div className='rounded-full border border-[#B3B3B3] py-1 px-4 ml-2 cursor-pointer box-shadow font-medium' onClick={() => {
                                                                        if (this.state.webLink !== "") {
                                                                            let links = this.state.websiteLinks;
                                                                            links.push(this.state.webLink)
                                                                            this.setState({ websiteLinks: links, webLink: "" });
                                                                        };
                                                                    }}>{this.props.t("OnBoarding.Add")}</div>
                                                                </div>
                                                                <div className='w-full aspect-[2.5] overflow-y-scroll rounded-lg mt-[10px] bg-[#F0F2F2] border border-[#B3B3B3]'>
                                                                    {
                                                                        this.state.websiteLinks.map((link, i) => <a className='px-2 py-1 max-w-full text-xs line-clamp-1 border-b border-[#B3B3B3] break-words' key={i} href={link} target='_blank'>{link}</a>)
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='py-4 sm:p-4 w-full max-w-[400px] sm:max-w-none sm:w-[50%]'>
                                                                <div className='text-sm font-medium'>{this.props.t("OnBoarding.Enter Your Mobile App URL(s)")}</div>
                                                                <div className='my-[5px] flex text-[10px]'>
                                                                    <input className='flex-shrink py-1 px-2 border border-[#B3B3B3] bg-[#F0F2F2] rounded-full w-full box-shadow' placeholder='App link' value={this.state.appLink} onChange={(e) => this.setState({ appLink: e.target.value })} />
                                                                    <div className='rounded-full border border-[#B3B3B3] py-1 px-4 ml-2 cursor-pointer box-shadow font-medium' onClick={() => {
                                                                        if (this.state.appLink !== "") {
                                                                            let links = this.state.appLinks;
                                                                            links.push(this.state.appLink)
                                                                            this.setState({ appLinks: links, appLink: "" });
                                                                        };
                                                                    }}>{this.props.t("OnBoarding.Add")}</div>
                                                                </div>
                                                                <div className='w-full aspect-[2.5] overflow-y-scroll rounded-lg mt-[10px] bg-[#F0F2F2] border border-[#B3B3B3]'>
                                                                    {
                                                                        this.state.appLinks.map((link, i) => <a className='px-2 py-1 max-w-full text-xs line-clamp-1 border-b border-[#B3B3B3] break-words' key={i} href={link} target='_blank'>{link}</a>)
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.activeTab === "PROFILE" &&
                                                    <div className='text-sm'>
                                                        <div>
                                                            <div>
                                                                <div className='font-medium'>{this.props.t("OnBoarding.Ques2")}?</div>
                                                                <div className='flex justify-start items-center gap-2 mb-[10px] mt-1'>
                                                                    <div className={`border hover:bg-green-700 hover:text-white border-green-700 px-2 py-1 text-xs font-medium duration-300 cursor-pointer ${this.state.registeringAsCompany ? 'bg-green-700 text-[#ffffff]' : 'text-green-800'}`} onClick={() => {
                                                                        this.setState({
                                                                            registeringAsCompany: true,
                                                                            associate_id: "",
                                                                        });
                                                                    }}>{this.props.t("OnBoarding.Yes")}</div>
                                                                    <div className={`border hover:bg-red-700 hover:text-white border-red-700 px-2 py-1 text-xs font-medium duration-300 cursor-pointer ${!this.state.registeringAsCompany ? 'bg-red-700 text-[#ffffff]' : 'text-red-800'}`} onClick={() => {
                                                                        this.setState({
                                                                            registeringAsCompany: false,
                                                                            associate_id: "",
                                                                            companyName: "",
                                                                            companyDescription: "",
                                                                            hearAboutUs: "",
                                                                            websiteType: ""
                                                                        });
                                                                    }}>{this.props.t("OnBoarding.No")}</div>
                                                                </div>
                                                            </div>
                                                            <div className='font-medium'>{this.props.t("OnBoarding.Ques3")}?* </div>
                                                            <input type="text" className={`bg-[#F0F2F2] rounded-full border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[400px] mt-1 mb-[10px] box-shadow ${!this.state.registeringAsCompany && 'mb-[20px]'}`} placeholder='Associate Store id' value={this.state.associate_id} onChange={(e) => this.setState({ associate_id: e.target.value })} />
                                                            {/* <div className='flex items-center'>
                                            <input type="text" className='bg-[#F0F2F2] rounded-full border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[400px] my-[10px] box-shadow' placeholder='Associate Store id' />
                                            <div className='rounded-full border border-[#B3B3B3] py-1 px-4 ml-2 cursor-pointer box-shadow text-[10px] font-medium'>Add</div>
                                        </div> */}
                                                            {/* <div className='bg-[#F0F2F2] rounded-lg aspect-[2.5] border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[400px] my-[10px] box-shadow'></div> */}
                                                            {
                                                                this.state.registeringAsCompany &&
                                                                <>
                                                                    <div className='font-medium'>{this.props.t("OnBoarding.Company Name")}?* </div>
                                                                    <input type="text" className={`bg-[#F0F2F2] rounded-full border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[400px] mt-1 mb-[10px] box-shadow ${!this.state.registeringAsCompany && 'mb-[20px]'}`} placeholder='Company Name' value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} />
                                                                    <div className='font-medium'>{this.props.t("OnBoarding.Company Description")}?* </div>
                                                                    <textarea type="text" className={`bg-[#F0F2F2] rounded-lg border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[400px] mt-1 mb-[10px] box-shadow ${!this.state.registeringAsCompany && 'mb-[20px]'}`} placeholder='Company Description' value={this.state.companyDescription} onChange={(e) => this.setState({ companyDescription: e.target.value })} />
                                                                    <div className='my-[20px]'>
                                                                        <div className='text-xs text-[#697475]'>{this.props.t("OnBoarding.Ques4")}?</div>
                                                                        <div className='font-medium my-[10px]'>{this.props.t("OnBoarding.Ques5")}?* </div>
                                                                        <div className='w-full text-xs text-[#697475]'>
                                                                            <div className='flex my-[5px]'>
                                                                                <input className='accent-[#697475] bg-[#D9D9D9]' type="radio" value="Blog" name="type_of_web_app" checked={this.state.websiteType === "Blog"} onChange={() => this.setState({ websiteType: "Blog" })} />
                                                                                <p className='ml-2 mb-[2px]'>{this.props.t("OnBoarding.Blog")}</p>
                                                                            </div>
                                                                            <div className='flex my-[5px]'>
                                                                                <input className='accent-[#697475] bg-[#D9D9D9]' type="radio" value="Content Website" name="type_of_web_app" checked={this.state.websiteType === "Content"} onChange={() => this.setState({ websiteType: "Content" })} />
                                                                                <p className='ml-2 mb-[2px]'>{this.props.t("OnBoarding.Content Website")}</p>
                                                                            </div>
                                                                            <div className='flex my-[5px]'>
                                                                                <input className='accent-[#697475] bg-[#D9D9D9]' type="radio" value="Manufacturer/Author with product Listed on Dukkandaar.com" name="type_of_web_app" checked={this.state.websiteType === "Manufacturer/Author with product Listed on Dukkandaar.com"} onChange={() => this.setState({ websiteType: "Manufacturer/Author with product Listed on Dukkandaar.com" })} />
                                                                                <p className='ml-2 mb-[2px]'>{this.props.t("OnBoarding.Manufacturer/Author with product Listed on Dukkandaar.com")}</p>
                                                                            </div>
                                                                            <div className='flex my-[5px]'>
                                                                                <input className='accent-[#697475] bg-[#D9D9D9]' type="radio" value="Social Media Page/Group" name="type_of_web_app" checked={this.state.websiteType === "Social Media Page/Group"} onChange={() => this.setState({ websiteType: "Social Media Page/Group" })} />
                                                                                <p className='ml-2 mb-[2px]'>{this.props.t("OnBoarding.Social Media Page/Group")}</p>
                                                                            </div>
                                                                            <div className='flex my-[5px]'>
                                                                                <input className='accent-[#697475] bg-[#D9D9D9]' type="radio" value="Other" name="type_of_web_app" checked={this.state.websiteType === "Other"} onChange={() => this.setState({ websiteType: "Other" })} />
                                                                                <p className='ml-2 mb-[2px]'>{this.props.t("OnBoarding.Other")}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='my-[20px]'>
                                                                        <div className='font-medium my-[10px]'>{this.props.t("OnBoarding.Ques6")}?* </div>
                                                                        <select name="hear_About_us" id="hear_about_us" className='box-shadow rounded-full focus:border-none px-2 py-1 text-xs bg-[#F0F2F2] w-full max-w-[400px]' value={this.state.hearAboutUs} onChange={(e) => this.setState({ hearAboutUs: e.target.value })}>
                                                                            <option value="">{this.props.t("OnBoarding.Select from the options")}</option>
                                                                            <option value="Search Engine">{this.props.t("OnBoarding.Search Engine")}</option>
                                                                            <option value="Social Media">{this.props.t("OnBoarding.Social Media")}</option>
                                                                            <option value="Website/Blog">{this.props.t("OnBoarding.Website/Blog")}</option>
                                                                            <option value="Online Community/Forum">{this.props.t("OnBoarding.Online Community/Forum")}</option>
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            }
                                                            {/* <div className='my-[20px]'>
                                            <div className='text-xs text-[#697475]'>Bu typing the character you see in the box, you help Dukkandaar prevent automated or scripted from submissions. This enables us to prevent fraud and abuse so that we may continue to ensure a high quality of service to all customers.</div>
                                            <div className='my-[10px]'>
                                                <img src={captcha} className='h-[50px] w-auto' alt="" />
                                            </div>
                                            <div className='font-medium my-[10px]'>Type the characters in the above image * </div>
                                            <input type="text" className='rounded-full border border-[#B3B3B3] text-xs py-1 px-2 w-full max-w-[200px] box-shadow' placeholder='' />
                                        </div>
                                        <div className='my-[20px] flex'>
                                            <input type="checkbox" name="terms_check" className='accent-[#F89C15]' />
                                            <p className='text-xs text-[#697475] ml-2'>You agree to the terms and conditions of the Associates Program Operating Agreement</p>
                                        </div> */}
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.activeTab === "START" &&
                                                    <>
                                                        <div className='w-full h-[1px] bg-[#697475]'></div>
                                                        <div className='px-3'>
                                                            <div className='text-xl text-[#89C443] font-medium my-[5px]'>{this.props.t("OnBoarding.Congrats")}, {this.props.affiliate_info.name || ""}</div>
                                                            <div className='text-sm font-medium my-[5px]'>{this.props.t("OnBoarding.Note1")}</div>
                                                            <div className='text-xs font-medium my-[5px]'>{this.props.t("OnBoarding.Your unique Associate iD is")} <span className='text-[#D90000]'> {this.props.affiliate_info.affiliate_store_id || ""}</span></div>
                                                            <div className='text-sm font-medium mt-[20px]'>{this.props.t("OnBoarding.Enter  your Payment and Tax Information")}</div>
                                                            <div className="flex gap-3">
                                                                <div className='bg-[#FFD814] rounded-full box-shadow my-[10px] px-2 py-1 text-xs font-semibold w-fit cursor-pointer' onClick={() => {
                                                                    this.setState({ navigate: "/banking-details" })
                                                                }}>{this.props.t("OnBoarding.Enter Now")}</div>
                                                                <div className='bg-[#407BF9] text-white rounded-full box-shadow my-[10px] px-2 py-1 text-xs font-semibold w-fit cursor-pointer' onClick={() => {
                                                                    this.setState({ navigate: "/dashboard" })
                                                                }}>{this.props.t("OnBoarding.Later")}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div>
                                                <div className='py-[20px] border-t border-[#697475] w-full flex justify-end items-center text-xs'>
                                                    {/* {
                                    !this.state.activeTab === "ACCOUNT_INFORMATION" ?
                                        <div className='rounded-full px-3 py-[2px] bg-white box-shadow border border-[#B3B3B3] flex items-center cursor-pointer'>
                                            <img src={leftIcon} className='w-[5px]' alt="" />
                                            <p className='ml-1'>Previous</p>
                                        </div>
                                        :
                                        <div>
                                        </div>
                                } */}
                                                    <div className={`rounded-full px-3 py-[3px] bg-[#FFD814] box-shadow border border-[#B3B3B3] cursor-pointer relative ${this.state.nextButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={() => moveToNextStep()}>
                                                        <div className={`${!this.state.nextButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in flex items-center`}>
                                                            <p className='mr-1 font-medium'>{this.props.t("OnBoarding.Next")}</p>
                                                            <img src={rightIcon} className='w-[5px] mt-[1px]' alt="" />
                                                        </div>
                                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.nextButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            <ThreeDots
                                                                height="24"
                                                                width="30"
                                                                radius="9"
                                                                color="#000"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                        </>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info,
        all_addresses: state.all_addresses
    };
};
const mapDispatchToProps = (dispatch) => {
    const getAllAddressesDispatch = (token) => {
        dispatch(getAffiliateAddresses(token));
    }
    const getAffiliateInfoDispatch = (token) => {
        dispatch(getAffiliateInfo(token));
    }
    return {
        getAllAddressesDispatch,
        getAffiliateInfoDispatch
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(OnBoarding));