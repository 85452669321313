import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import LinkPage from '../component/LinkPage'
import { Link } from 'react-router-dom'
import Footer from '../component/HomeFooter';
import { connect } from 'react-redux'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-hot-toast'
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class ProductLinking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: '',
            linkName: '',
            showFilters: false,
            getButtonDisabled: false,
            getCopyButtonDisabled: false,
            currentHTML: null,
            currentPage: "product-linking",
            url: '',
            loading: true
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleCategoryChange = (event) => {
        this.setState({ category: event.target.value });
    };
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (
            this.state.showFilters &&
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.setState({ showFilters: false });
        };
    }
    render() {
        const showFilter = () => {
            this.setState({ showFilters: true });
        };
        const getHTML = () => {
            if (!this.state.getButtonDisabled) {
                this.setState({ getButtonDisabled: true });
                if (this.state.currentPage === "product-linking") {
                    if (this.state.category === "") {
                        toast.error("Please select the category!")
                        this.setState({ getButtonDisabled: false });
                    }
                    else if (this.state.name === "") {
                        toast.error("Please enter name for the link")
                        this.setState({ getButtonDisabled: false });
                    }
                    else {
                        var myHeaders = new Headers();
                        myHeaders.append("token", this.props.token);
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "category_1_id": this.state.category,
                            "name": this.state.linkName
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_BASE_URL}/generate_category_link`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 401 || result.code === 407) {
                                    this.props.logoutDispatch();
                                }
                                else {
                                    if (result.status === "Success" || result.status === "success") {
                                        toast.success('Successfully Generated!')
                                        this.setState({ currentHTML: result.HTML, getButtonDisabled: false });
                                    }
                                    else {
                                        toast.error(result.msg)
                                        this.setState({ getButtonDisabled: false });
                                    }
                                }
                            })
                            .catch(error => {
                                toast.error("Server Error!")
                                this.setState({ getButtonDisabled: false });
                            });
                    }
                }
                else if (this.state.currentPage === "image-linking") {
                    if (this.state.url === "") {
                        toast.error("Please enter the URL!")
                        this.setState({ getButtonDisabled: false });
                    }
                    else {
                        var myHeaders = new Headers();
                        myHeaders.append("token", this.props.token);
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "url": this.state.url,
                            "type": "image"
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_BASE_URL}/generate_product_link`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 401 || result.code === 407) {
                                    this.props.logoutDispatch();
                                }
                                else {
                                    if (result.status === "Success" || result.status === "success") {
                                        toast.success('Successfully Generated!')
                                        this.setState({ currentHTML: result.HTML, getButtonDisabled: false });
                                    }
                                    else {
                                        toast.error("Please enter the URL!")
                                        this.setState({ getButtonDisabled: false });
                                    }
                                }
                            })
                            .catch(error => {
                                toast.error("Server Error!")
                                this.setState({ getButtonDisabled: false });
                            })
                    }
                }
                else {
                    if (this.state.url === "") {
                        toast.error("Please enter the URL!")
                        this.setState({ getButtonDisabled: false });
                    }
                    else if (this.state.linkName === "") {
                        toast.error("Please enter name for the link")
                        this.setState({ getButtonDisabled: false });
                    }
                    else {
                        var myHeaders = new Headers();
                        myHeaders.append("token", this.props.token);
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                            "url": this.state.url,
                            "name": this.state.linkName
                        });

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };

                        fetch(`${process.env.REACT_APP_BASE_URL}/generate_product_link`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 401 || result.code === 407) {
                                    this.props.logoutDispatch();
                                }
                                else {
                                    if (result.status === "Success" || result.status === "success") {
                                        toast.success('Successfully Generated!')
                                        this.setState({ currentHTML: result.HTML, getButtonDisabled: false });
                                    }
                                    else {
                                        toast.error("Please enter the URL!")
                                        this.setState({ getButtonDisabled: false });
                                    }
                                }
                            })
                            .catch(error => {
                                toast.error("Server Error!")
                                this.setState({ getButtonDisabled: false });
                            });
                    }
                }
            }
        }
        const copyHTML = () => {
            if (this.state.currentHTML) {
                this.setState({ getCopyButtonDisabled: true });
                let x = document.querySelector('#html-code');
                navigator.clipboard.writeText(x.value);
                toast.success("HTML Copied!")
                setTimeout(() => {
                    this.setState({ getCopyButtonDisabled: false })
                }, 1000);
            }
            else {
                setTimeout(() => {
                    this.setState({ getCopyButtonDisabled: false })
                }, 1000);
                toast.error("Please Fill Field")

            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            {this.state.loading ?
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice loading={this.state.loading} />
                                        <LinkPage loading={this.state.loading} />
                                        <div className='bg-[#D9D9D97A] mb-20 md:mb-0 mt-4 p-3 rounded-xl'>
                                            <div className='border text-xs items-center font-medium'>
                                                <div><Skeleton height={300} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice />
                                        <LinkPage />
                                        <div className='bg-[#D9D9D97A] mb-20 md:mb-0 mt-4 p-3 rounded-xl'>
                                            <div className='border-[#697475] border text-xs items-center font-medium rounded-t-xl bg-white'>
                                                <ul className="hidden text-xs md:text-sm font-medium text-center text-black divide-x divide-black rounded-xl md:flex">
                                                    <li className="w-full">
                                                        <div onClick={() => this.setState({ currentPage: "product-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full p-2 hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "product-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Favorited Designation")}</div>
                                                    </li>
                                                    <li className="w-full">
                                                        <div onClick={() => this.setState({ currentPage: "image-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full p-2 hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "image-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Image")}</div>
                                                    </li>
                                                    <li className="w-full">
                                                        <div onClick={() => this.setState({ currentPage: "page-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full p-2 hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "page-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Any Page")}</div>
                                                    </li>
                                                </ul>
                                                <div className='md:hidden flex justify-between text-sm items-center font-medium rounded-t-xl bg-white'>
                                                    <div className='text-[#D90000] p-1 ml-2'>{this.props.t("ProductLinking.Link To Favorited Designation")}</div>
                                                    <div className='mr-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => showFilter()} viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v4.59L7.3 9.24a.75.75 0 00-1.1 1.02l3.25 3.5a.75.75 0 001.1 0l3.25-3.5a.75.75 0 10-1.1-1.02l-1.95 2.1V6.75z" clipRule="evenodd" />
                                                        </svg>
                                                        {this.state.showFilters && (
                                                            <div
                                                                ref={this.wrapperRef}
                                                                className="absolute rounded-xl right-3.5 bg-[#F0F2F2] z-50"
                                                            >
                                                                <div className="w-[223px] h-fit text-[#0B0909] text-sm font-medium">
                                                                    <ul className='block text-right'>
                                                                        <li className='p-1 rounded-xl hover:bg-[#BEBEBE] hover:text-[#fff]'><div onClick={() => this.setState({ currentPage: "product-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "product-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Favorited Designation")}</div></li>
                                                                        <li className='p-1 rounded-xl hover:bg-[#BEBEBE] hover:text-[#fff]'><div onClick={() => this.setState({ currentPage: "image-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "image-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Image")}</div></li>
                                                                        <li className='p-1 rounded-xl hover:bg-[#BEBEBE] hover:text-[#fff]'><div onClick={() => this.setState({ currentPage: "page-linking", category: '', linkName: '', url: '', currentHTML: '' })} className={`inline-block w-full hover:text-[#D90000] duration-300 cursor-pointer ${this.state.currentPage === "page-linking" ? 'text-[#D90000]' : 'text-black'}`}>{this.props.t("ProductLinking.Link To Any Page")}</div></li>

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='border border-[#697475] xxs1:p-4 xxs1:space-x-2 text-xs  font-normal rounded-b-xl border-t-0'>
                                                <div className='pl-3 pt-2'>{this.props.t("ProductLinking.Create links to popular pages at Dukkandaar")}</div>
                                                <div className='mdd:flex block w-full'>
                                                    {
                                                        this.state.currentPage === "product-linking" &&
                                                        <div className=' mdd:w-2/5 block mdd:border-r-2 border-[#697475] my-2 p-2'>
                                                            <div className='my-3'>
                                                                <div><span>{this.props.t("ProductLinking.Select a Product Line")} <span className='text-[#D90000]'>*</span></span></div>
                                                                <div className='mt-1'>
                                                                    <select
                                                                        value={this.state.product}
                                                                        onChange={this.handleCategoryChange}
                                                                        className='py-1 w-full xxs1:w-[calc(100%-60px)] px-1 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                                    >
                                                                        <option value="">{this.props.t("Select category")}</option>
                                                                        {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category) => <option value={category.category_1_id}>{category.name}</option>)}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>
                                                                <div><span>{this.props.t("ProductLinking.Name Your Link")} <span className='text-[#D90000]'>*</span></span></div>
                                                                <div className='mt-1'>
                                                                    <input type="text" className='py-1 w-full xxs1:w-[calc(100%-60px)] px-3 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]' value={this.state.linkName} onChange={(e) => this.setState({ linkName: e.target.value })} />
                                                                </div>
                                                            </div>
                                                            <div className='my-3'>
                                                                <button className={`border border-[#B3B3B3] py-2 text-[#565656] px-3 rounded-full relative ${this.state.getButtonDisabled ? 'bg-[#ffffff90]' : 'bg-[#FFFFFF]'}`} onClick={() => getHTML('product')}>
                                                                    <div className={`${!this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        {this.props.t("Get HTML")}
                                                                    </div>
                                                                    <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        <ThreeDots
                                                                            height="32"
                                                                            width="30"
                                                                            radius="9"
                                                                            color="#000"
                                                                            ariaLabel="three-dots-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClassName=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.currentPage === "image-linking" &&
                                                        <div className=' mdd:w-2/5 block mdd:border-r-2 border-[#697475] my-2 p-2'>
                                                            <form>
                                                                <label className="block">
                                                                    <span className="block text-xs font-medium my-2">{this.props.t("ProductLinking.Enter the URL")}<span className='text-[#D90000]'>*</span></span>
                                                                    <input type="text" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} className="py-2 block w-full xxs1:w-[calc(100%-30px)] px-2 rounded-2xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040] text-xs placeholder-slate-400" placeholder='Example: https://www.dukkandaar.com/home/textlink/general' />
                                                                </label>
                                                                <span className='text-[9px] text-[#565656]'>{this.props.t("ProductLinking.HintClick")} <Link to={'https://www.dukkandaar.com/'} className='underline font-extralight'>{this.props.t("here")}</Link> {this.props.t("ProductLinking.Note")}.</span>
                                                            </form>

                                                            <div className='my-3'>
                                                                <button className={`border border-[#B3B3B3] py-2 text-[#565656] px-3 rounded-full relative ${this.state.getButtonDisabled ? 'bg-[#ffffff90]' : 'bg-[#FFFFFF]'}`} onClick={() => getHTML('product')}>
                                                                    <div className={`${!this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        {this.props.t("Get HTML")}
                                                                    </div>
                                                                    <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        <ThreeDots
                                                                            height="32"
                                                                            width="30"
                                                                            radius="9"
                                                                            color="#000"
                                                                            ariaLabel="three-dots-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClassName=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.currentPage === "page-linking" &&
                                                        <div className=' mdd:w-2/5 block mdd:border-r-2 border-[#697475] my-2 p-2'>
                                                            <form>
                                                                <label className="block">
                                                                    <span className="block text-xs font-medium my-2">{this.props.t("ProductLinking.Enter the URL")}<span className='text-[#D90000]'>*</span></span>
                                                                    <input type="text" value={this.state.url} onChange={e => this.setState({ url: e.target.value })} className="py-2 block w-full xxs1:w-[calc(100%-30px)] px-2 rounded-2xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040] text-xs placeholder-slate-400" placeholder='Example: https://www.dukkandaar.com/home/textlink/general' />
                                                                </label>
                                                                <span className='text-[9px] text-[#565656]'>{this.props.t("ProductLinking.HintClick")} <Link to={'https://www.dukkandaar.com/'} className='underline font-extralight'>{this.props.t("here")}</Link> {this.props.t("ProductLinking.Note")}.</span>
                                                                <label className="block my-2">
                                                                    <span className="block text-xs font-medium my-2">{this.props.t("ProductLinking.Name Your Link")}<span className='text-[#D90000]'>*</span></span>
                                                                    <input type="text" value={this.state.name} onChange={e => this.setState({ linkName: e.target.value })} className="py-2 block w-full xxs1:w-[calc(100%-30px)] px-2 rounded-2xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040] text-[10px] placeholder-slate-400" placeholder="Example: Harry Potter Store" />
                                                                </label>
                                                            </form>

                                                            <div className='my-3'>
                                                                <button className={`border border-[#B3B3B3] py-2 text-[#565656] px-3 rounded-full relative ${this.state.getButtonDisabled ? 'bg-[#ffffff90]' : 'bg-[#FFFFFF]'}`} onClick={() => getHTML('product')}>
                                                                    <div className={`${!this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        {this.props.t("Get HTML")}
                                                                    </div>
                                                                    <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                        <ThreeDots
                                                                            height="32"
                                                                            width="30"
                                                                            radius="9"
                                                                            color="#000"
                                                                            ariaLabel="three-dots-loading"
                                                                            wrapperStyle={{}}
                                                                            wrapperClassName=""
                                                                            visible={true}
                                                                        />
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className=' mdd:w-3/5 self-center p-2'>

                                                        <label htmlFor="html-code" className="block mb-2 text-xs font-bold text-center">{this.props.t("ProductLinking.Preview")}</label>
                                                        <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.currentHTML || ""}></textarea>
                                                        <div className='w-full text-center my-2'>
                                                            <button className={`border border-[#B3B3B3] py-2 text-[#565656] px-3 rounded-3xl bg-[#FFD814] relative ${this.state.getCopyButtonDisabled ? 'bg-[#FFD814]' : 'bg-[#FFD814]'}`} onClick={() => copyHTML()}>
                                                                <div className={`${!this.state.getCopyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    {this.props.t("Copy HTML")}
                                                                </div>
                                                                <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getCopyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    <ThreeDots
                                                                        height="32"
                                                                        width="30"
                                                                        radius="9"
                                                                        color="#000"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>
                                                        {
                                                            this.state.currentHTML &&
                                                            <span className='text-[10px] xxs1:text-xs'>{this.props.t("Note")} : {this.props.t("Your Associates ID")} {this.props.affiliate_info.affiliate_store_id} {this.props.t("ProductLinking.is already embedded in the code")}.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='border-[#697475] border text-xs items-center font-medium mt-2 rounded-xl bg-white'>
                            <ul className=" text-xs mdd:text-sm font-medium text-left text-black divide-x border-b border-black divide-black flex">
                                <li className="w-full">
                                    <Link className="inline-block w-full p-2 text-xs pl-8 font-bold text-[#D90000] active ">Link To Favorited Designation</Link>
                                </li>
                            </ul>
                            <div className='block lg:flex p-2 items-center'>
                                <div className='text-[10px]'>Link Checker lets you test your links to make sure they are correctly tagging to your Associate ID or one of your Tracking IDs.</div>
                                <div className='px-1'>
                                    <button className='border border-[#B3B3B3] py-1 text-[#565656] px-2 mt-1 drop-shadow-[#00000040] text-[10px] rounded-3xl bg-[#FFFFFF]'>
                                        Get HTML
                                    </button>
                                </div>
                            </div>
                        </div> */}
                                        </div>
                                    </div>
                                </>
                            }
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

const mapStateToProps = (state) => {
    return {
        token: state.token,
        categories: state.categories,
        affiliate_info: state.affiliate_info
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(ProductLinking));