import { AFFILIATE_INFO, ALL_ADDRESSES, CATEGORY, LOGIN, TRACKING_ID } from "../constants"
import CryptoJS from "crypto-js";

export const setToken = (token) => {
    return async function (dispatch) {
        dispatch({
            type: LOGIN,
            payload: token
        })
    }
}
export const logout = () => {
    return async function (dispatch) {
        window.localStorage.removeItem('Dukandaar-Affiliate');
        dispatch({
            type: LOGIN,
            payload: null,
        });
    };
};
export const getAffiliateInfo = (token) => {
    return async function (dispatch) {
        var myHeaders = new Headers();
        myHeaders.append("token", token);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_info`, requestOptions)
            .then(response => response.json())
            .then(res => {
                if (res.code === 401 || res.code === 407) {
                    dispatch(logout());
                }
                else {
                    if (res.status === "Success" || res.status === "success") {
                        const ciphertext = res.data;
                        const bytes = CryptoJS.AES.decrypt(
                            ciphertext,
                            process.env.REACT_APP_SECRET_KEY
                        );
                        const plaintext = bytes.toString(CryptoJS.enc.Utf8);
                        var dt = JSON.parse(plaintext);
                        dispatch({
                            type: AFFILIATE_INFO,
                            payload: dt,
                        });
                    }
                    else {
                        dispatch({
                            type: AFFILIATE_INFO,
                            payload: null,
                        });
                        dispatch({
                            type: LOGIN,
                            payload: null
                        })
                        window.localStorage.removeItem("Dukandaar-Affiliate");
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
}
export const getAffiliateAddresses = (token) => {
    return async function (dispatch) {
        var myHeaders = new Headers();
        myHeaders.append("token", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/customer_all_address`, requestOptions)
            .then(response => response.json())
            .then(res => {
                if (res.code === 401 || res.code === 407) {
                    dispatch(logout());
                }
                else {
                    if (res.status === "Success" || res.status === "success") {
                        dispatch({
                            type: ALL_ADDRESSES,
                            payload: res.data,
                        });
                    }
                    else {
                        dispatch({
                            type: ALL_ADDRESSES,
                            payload: null,
                        });
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
}

export const getTrackingId = (token) => {
    return async function (dispatch) {
        var myHeaders = new Headers();
        myHeaders.append("token", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/customer_all_tracking_tag`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    dispatch(logout());
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        dispatch({
                            type: TRACKING_ID,
                            payload: result.data,
                        });
                    }
                    else {
                        dispatch({
                            type: TRACKING_ID,
                            payload: null,
                        });
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
}

export const getCategories = (token) => {
    return async function (dispatch) {
        var myHeaders = new Headers();
        myHeaders.append("token", token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/get_all_category`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    dispatch(logout());
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        dispatch({
                            type: CATEGORY,
                            payload: result.category
                        });
                    }
                    else {
                        dispatch({
                            type: CATEGORY,
                            payload: null
                        });
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
}