import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import ReactApexChart from 'react-apexcharts'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class Fees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: '',
            showFilters: false,
            options: {
                series: [
                    {
                        name: 'Fees',
                        type: 'line',
                        data: ['0', '0.50', '1.00', '1.50', '0.50', '1.00', '1.50', '0.50', '1.00', '1.50', '0.50'],
                        style: {
                            color: "#275272"
                        }
                    }],
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                color: '#275272',
                stroke: {
                    width: [3, 0],
                    curve: 'straight'
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1]
                },
                labels: ['1', '3', '6', '9', '12', '15', '18', '21', '24', '27', '30'],
                xaxis: {
                    type: 'numbers',
                    labels: {
                        style: {
                            colors: '#F0B83A',
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: '#F0B83A'
                    },
                },
                yaxis: [
                    {
                        axisBorder: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: '#275272',
                            }
                        },
                    }, {
                        opposite: true,
                        labels: {
                            style: {
                                colors: '#275272',
                            },
                            formatter: function (val) {
                                return val.toFixed(0);
                            }
                        },
                    }],
            },
            loading: true
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleProductChange = (event) => {
        this.setState({ product: event.target.value });
    };
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (
            this.state.showFilters &&
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.setState({ showFilters: false });
        };
    }
    render() {
        const showFilter = () => {
            this.setState({ showFilters: true });
        };
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div className="w-full  px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] mb-6 md:mt-[150px] rounded-[10px] md:rounded-[20px]">
                                <Notice />
                                <div className='bg-[#FEECB3] mt-4 p-2 px-4'>
                                    <Link className='flex text-[#347AE2] '>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="md:w-5 md:h-5 w-4 h-4">
                                            <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                        </svg>
                                        <div className='text-xs md:text-sm font-medium'>{this.props.t("Consolidated Summary")}</div>
                                    </Link>
                                </div>
                                <div className='bg-[#D7D7D72E] border-2 mt-4 rounded-[20px] p-2 md:pl-8 border-[#F89C15]'>
                                    <div className='flex justify-between'>
                                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                                            <Link to="/report" className="text-[#407BF9] text-xs font-medium">
                                                {this.props.t("Report")}
                                            </Link>
                                            <Link
                                                className="text-[#407BF9] text-xs font-medium"
                                                style={{ color: "#000000" }}
                                            >
                                                {this.props.t("Summary")}
                                            </Link>
                                        </Breadcrumbs>
                                        <div className='block md:hidden '>
                                            <div className='mr-2'>
                                                <svg width="110" onClick={() => showFilter()} height="31" viewBox="0 0 143 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g filter="url(#filter0_d_271_710)">
                                                        <rect x="4" width="135" height="33" rx="8" fill="#D9DEE4" />
                                                    </g>
                                                    <defs>
                                                        <filter id="filter0_d_271_710" x="0" y="0" width="143" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                            <feOffset dy="4" />
                                                            <feGaussianBlur stdDeviation="2" />
                                                            <feComposite in2="hardAlpha" operator="out" />
                                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_271_710" />
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_271_710" result="shape" />
                                                        </filter>
                                                    </defs>
                                                </svg>

                                                {this.state.showFilters && (
                                                    <div
                                                        ref={this.wrapperRef}
                                                        className="absolute rounded-xl right-3.5 bg-[#F0F2F2] z-50"
                                                    >
                                                        <div className="w-[140px] h-fit text-[#0B0909] text-xs font-medium">
                                                            <ul className='block my-1'>
                                                                <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Fee Schedule")}</li>
                                                                <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Payment History")}</li>
                                                                <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Download Reports")}</li>
                                                                <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Feedback")}</li>
                                                                <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Help")}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='md:block hidden border-l-2 -mr-1 border-b-2 place-self-center -mt-4 border-[#F89C15] bg-[#D7D7D72E] rounded-tr-xl rounded-bl-xl'>
                                            <ul className='flex text-xs text-[#565656]'>
                                                <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                    <span> {this.props.t("Fee Schedule")}</span>
                                                </li>
                                                <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                    <span>{this.props.t("Payment History")}</span>
                                                </li>
                                                <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                    <span>{this.props.t("Download Reports")}</span>
                                                </li>
                                                <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                    <span>{this.props.t("Feedback")}</span>
                                                </li>
                                                <li className='p-1 px-1'>
                                                    <span>{this.props.t("Help")}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='flex text-[10px] md:text-xs text-[#565656]'>
                                            <span>{this.props.t("May 25 2023 - Jun 23 2023 / Last 30 Days")}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                            <span>{this.props.t("Tracking ID")}: {this.props.t("All")}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <div>
                                            <span className='text-xs hidden md:block text-[#565656]'>{this.props.t("Last Updated")}: {this.props.t("Jun 23 2023 + 00:00")}</span>
                                        </div>
                                    </div>
                                    <div className='flex text-xs mt-2'>
                                        <Link to={'/report'} className='md:w-1/6 w-1/3'>
                                            <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                <div className='text-[#565656]'>{this.props.t("Summary")}</div>
                                                <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                            </div>
                                        </Link>
                                        <Link to={'/fees'} className='md:w-1/6 w-1/3'>
                                            <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                <div className='text-[#565656]'>{this.props.t("Fees")}</div>
                                                <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                            </div>
                                        </Link>
                                        <Link to={'/bounties'} className='md:w-1/6 w-1/3'>
                                            <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                <div className='text-[#565656]'>{this.props.t("Bounties")}</div>
                                                <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='md:pr-8 '>
                                        <div id="chart">
                                            <ReactApexChart options={this.state.options} series={this.state.options.series} type="line" height={350} />
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-[#FFFFFF] text-[10px] md:text-xs text-[#275272] block md:hidden font-medium mt-4'>
                                    <div>
                                        <ul className='flex w-full border mt-4 rounded-lg border-[#F89C15]'>
                                            <li className='border-[#F89C15] w-1/3 border-r'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Clicks")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("1")}</div>
                                                </div>
                                            </li>
                                            <li className='border-[#F89C15] w-1/3 border-r'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Ordered")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("0")}</div>
                                                </div>
                                            </li>
                                            <li className='border-[#F89C15] w-1/3'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Shipped Items")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("0")}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='flex w-full border mt-2 rounded-lg border-[#F89C15]'>
                                            <li className='border-[#F89C15] w-1/3 border-r'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Return Items")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("0")}</div>
                                                </div>
                                            </li>
                                            <li className='border-[#F89C15] w-1/3 border-r'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Conversion")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("0.00")}%</div>
                                                </div>
                                            </li>
                                            <li className='w-1/3'>
                                                <div className='block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Shipped Items Revenue")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("₹0.00")}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul className='flex w-full mt-4 '>
                                            <li className=' border rounded-lg border-[#F89C15] w-1/3'>
                                                <div className=' block'>
                                                    <div className='p-1 px-2'>{this.props.t("Fee.Total Earnings")}</div>
                                                    <div className='p-1 px-2'>{this.props.t("₹0.00")}</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='bg-[#FFFFFF] text-xs text-[#275272] md:flex hidden font-medium border mt-4 rounded-lg border-[#F89C15]'>
                                    <ul className='flex w-full'>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Clicks")}</div>
                                                <div className='p-2 px-4'>{this.props.t("1")}</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Ordered")}</div>
                                                <div className='p-2 px-4'>{this.props.t("0")}</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Shipped Items")}</div>
                                                <div className='p-2 px-4'>{this.props.t("0")}</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Return Items")}</div>
                                                <div className='p-2 px-4'>{this.props.t("0")}</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Conversion")}</div>
                                                <div className='p-2 px-4'>{this.props.t("0.00")}%</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4 border-r'>
                                            <div className='block'>
                                                <div className='p-2'>{this.props.t("Fee.Shipped Items Revenue")}</div>
                                                <div className='p-2 px-4'>{this.props.t("₹0.00")}</div>
                                            </div>
                                        </li>
                                        <li className='border-[#275272] w-1/4'>
                                            <div className=' block'>
                                                <div className='p-2 px-4'>{this.props.t("Fee.Total Earnings")}</div>
                                                <div className='p-2 px-4'>{this.props.t("₹0.00")}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='bg-[#D7D7D72E] text-sm text-[#275272] font-medium border-2 mt-2 rounded-lg border-[#F89C15]'>
                                    <div>
                                        <div className='p-4 text-xs md:text-sm flex justify-between'>
                                            <div className='flex text-[#275272] md:w-8/12 text-center'>
                                                <div className='border border-[#F89C15] rounded-md p-2 md:w-1/6'><span>{this.props.t("Fee.Orders")}</span></div>
                                                <div className='border border-[#F89C15] bg-[#D9D9D9] rounded-md ml-1 p-2 md:w-1/6'><span>{this.props.t("Fee.Earnings")}</span></div>
                                                <div className='border border-[#F89C15] bg-[#D9D9D9] rounded-md ml-1 p-2 md:w-1/6'><span>{this.props.t("Fee.Link Type")}</span></div>
                                            </div>
                                            <div className='flex items-center'>
                                                <div className='md:block hidden'>{this.props.t("Fee.Group by")}</div>
                                                <div className='ml-1'>
                                                    <select
                                                        value={this.state.product}
                                                        onChange={this.handleProductChange}
                                                        className='p-1 rounded-lg w-[4rem] bg-[#D9DEE4] text-[#565656] drop-shadow-[#00000040]'
                                                    >
                                                        <option>{this.props.t("Fee.None")}</option>
                                                        <option>{this.props.t("Fee.Category")}</option>
                                                        <option>{this.props.t("Fee.Track Id")}</option>
                                                        <option>{this.props.t("Fee.Date")}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:px-4'>
                                            <ul className='md:flex hidden w-full text-xs border mb-2 rounded border-[#F89C15]'>
                                                <li className='border-[#275272] w-3/4 border-r'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Title")}</div>
                                                    </div>
                                                </li>
                                                <li className='border-[#275272] w-5/12 border-r'>
                                                    <div className=' block pb-6'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Links Clicks")}</div>
                                                    </div>
                                                </li>
                                                <li className='border-[#275272] w-5/12 border-r'>
                                                    <div className=' block pb-6'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Links Conversion")}</div>
                                                    </div>
                                                </li>
                                                <li className='border-[#275272] w-5/12 border-r'>
                                                    <div className=' block pb-6'>
                                                        <div className='p-2'>{this.props.t("Fee.Ordered Through Product Links")}</div>
                                                    </div>
                                                </li>
                                                <li className='border-[#275272] w-5/12 border-r'>
                                                    <div className=' block pb-6'>
                                                        <div className='p-2'>{this.props.t("Fee.All Other Items Ordered")}</div>
                                                    </div>
                                                </li>
                                                <li className='border-[#275272] w-5/12'>
                                                    <div className=' block pb-6'>
                                                        <div className='p-2'>{this.props.t("Fee.Total Items Ordered")}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className='block md:hidden w-full text-[#275272] text-xs mb-2 '>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Title")}</div>
                                                    </div>
                                                </li>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Links Clicks")}</div>
                                                    </div>
                                                </li>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Product Links Conversion")}</div>
                                                    </div>
                                                </li>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Ordered Through Product Links")}</div>
                                                    </div>
                                                </li>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.All Other Items Ordered")}</div>
                                                    </div>
                                                </li>
                                                <li className='border border-[#F89C15] m-1'>
                                                    <div className=' block'>
                                                        <div className='p-2'>{this.props.t("Fee.Total Items Ordered")}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul className='md:hidden flex w-full text-sm text-[#000000] items-center justify-center mb-2 p-1 rounded'>
                                                <li className=' border border-[#F89C15] w-full p-4 text-center'>{this.props.t("Fee.No Data Found")}</li>
                                            </ul>
                                            <ul className='md:flex hidden w-full text-sm text-[#000000] items-center justify-center mb-2 p-1 rounded border border-[#F89C15]'>
                                                <li>{this.props.t("Fee.No Data Found")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='bg-[#407BF92E] p-3 md:p-5 border-2 rounded-[20px] border-[#F89C15]'>
                                        <div className='flex'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                            </svg>
                                            <span className='text-xs font-medium mt-[2px] ml-1'>{this.props.t("Note")}</span>
                                        </div>
                                        <div className='md:px-8 px-4'>
                                            <ul className='list-disc'>
                                                <li className='md:text-xs text-[10px] font-medium'> {this.props.t("Fee.Note1")} <Link className='underline'>{this.props.t("Fee.Note2")}.</Link>{this.props.t("Fee.Note3")}. <Link className='underline'>{this.props.t("Learn More")}</Link></li>
                                                <li className='md:text-xs font-medium text-[10px]'>{this.props.t("Fee.Note4")}. <Link className='underline'>{this.props.t("Learn More")}</Link>  {this.props.t("Fee.Note5")} <Link className='underline'>{this.props.t("Payment Information")}</Link>, {this.props.t("Fee.Note6")}.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
export default withTranslation()(Fees);