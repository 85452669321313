import React, { Component } from 'react'

export default class Carousel extends Component {
    render() {
        return (
            <div>
                <img src={this.props.carousel.image} alt='image' />
                <div className='absolute top-0'>
                    <div className=' bg-[rgba(0,0,0,.4)] pb-[100%]'>
                        <p className=' md:p-8  p-4 text-[white] text-xs md:text-xl md:w-[70%]'>{this.props.carousel.para}</p>
                        <p className=' md:pl-8 pl-4 text-[white] text-xs md:text-xl md:w-[70%]'>{this.props.carousel.name}</p>
                    </div>
                </div>
            </div>
        )
    }
}
