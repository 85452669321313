import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class Schedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            {this.state.loading ?
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice loading={this.state.loading} />
                                        <div className='text-[10px] md:text-sm font-medium md:p-8 p-2 md:pl-12'>
                                            <div className="w-[40%] "><Skeleton /></div>
                                            <div className='font-normal'>
                                                <div className='my-3'>
                                                    <div><Skeleton count={3} /></div>
                                                </div>
                                                <div className='my-3'>
                                                    <div><Skeleton count={3} /></div>
                                                </div>
                                                <div className='my-3'>
                                                    <div><Skeleton count={5} /></div>
                                                </div>
                                                <div className='my-3'>
                                                    <div><Skeleton count={3} /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='border-b border-[#B3B3B3]'>
                                            <div className='text-xs md:text-sm font-medium px-2 md:pr-8 md:pl-12'>
                                                <div><Skeleton /></div>
                                                <div className='flex justify-between mt-4'>

                                                    <div className="w-[30%] "><Skeleton /></div>
                                                    <div className="w-[30%] max-w-[70px]"><Skeleton /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:pl-12 pt-2 text-[10px] md:text-xs text-[#565656] md:w-4/5 font-medium'>
                                            <div className='flex justify-between p-1'>
                                                <div className="w-[70%] "><Skeleton count={25} className='my-1' /></div>
                                                <div className="w-[30%] max-w-[70px]"><Skeleton count={25} className='my-1' /></div>
                                            </div>
                                            <div className='text-[#0B0909] mt-4 text-[10px] md:text-sm'>
                                                <div className="w-[60%] "><Skeleton count={9} /></div>
                                            </div>
                                            <div className='text-[#0B0909] mt-4 text-[10px] md:text-sm'>
                                                <div className="w-[60%] "><Skeleton /></div>
                                                <div className="w-[40%] "><Skeleton count={4} /></div>
                                            </div>
                                            <div className='p-3 text-[10px] md:text-sm text-[#0B0909]'>
                                                <div className='text-[#D90000] text-xs md:text-[16px] pt-1 font-medium'><div><Skeleton /></div></div>
                                                <div className='pt-2 pb-4'><div><Skeleton /></div></div>
                                                <div className='w-[30%]'><Skeleton count={10} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice />
                                        <div className='text-[10px] md:text-sm font-medium md:p-8 p-2 md:pl-12'>
                                            <div className='text-[#D90000] text-xs md:text-sm'>{this.props.t("Schedule.note")}</div>
                                            <div className='font-normal'>
                                                <p className='my-3'>
                                                    {this.props.t("Schedule.note1")}.
                                                </p>
                                                <p className='my-3'>
                                                    {this.props.t("Schedule.note2")}
                                                </p>
                                                <p className='my-3'>
                                                    {this.props.t("Schedule.note3")}
                                                </p>
                                                <p className='my-3'>
                                                    {this.props.t("Schedule.note4")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='border-b border-[#B3B3B3]'>
                                            <div className='text-xs md:text-sm font-medium px-2 md:pr-8 md:pl-12'>
                                                <p>{this.props.t("Schedule.Table")}</p>
                                                <div className='flex justify-between mt-4'>
                                                    <p>{this.props.t("Schedule.Product Category")}</p>
                                                    <p>{this.props.t("Schedule.Fixed Advertising Rates")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='md:pl-12 pt-2 text-[10px] md:text-xs text-[#565656] md:w-4/5 font-medium'>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate1")}</p>
                                                <p>{this.props.t("9")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate2")}</p>
                                                <p>{this.props.t("9")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate3")}</p>
                                                <p>{this.props.t("8")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate4")}</p>
                                                <p>{this.props.t("7")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate5")}</p>
                                                <p>{this.props.t("6")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate6")}</p>
                                                <p>{this.props.t("6")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate7")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate8")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate9")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate10")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate11")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate12")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate13")}</p>
                                                <p>{this.props.t("4")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate14")}</p>
                                                <p>{this.props.t("2.5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate15")}</p>
                                                <p>{this.props.t("2")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate16")}</p>
                                                <p>{this.props.t("1")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>*{this.props.t("Schedule.Rate17")}</p>
                                                <p>{this.props.t("0.2")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate18")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate19")}</p>
                                                <p>{this.props.t("6")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate20")}</p>
                                                <p>{this.props.t("6")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate21")}</p>
                                                <p>{this.props.t("8")}%</p>
                                            </div>
                                            <div className='flex justify-between p-1'>
                                                <p className='flex justify-between p-1'>{this.props.t("Schedule.Rate22")}</p>
                                                <p>{this.props.t("5")}%</p>
                                            </div>
                                            <div className='text-[#0B0909] mt-4 text-[10px] md:text-sm'>
                                                <h3 className='text-xs md:text-[16px] font-medium p-3'>{this.props.t("Schedule.MobilePhones1")}</h3>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("1")}. {this.props.t("Schedule.MobilePhones11")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("2")}. {this.props.t("Schedule.MobilePhones12")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("3")}. {this.props.t("Schedule.MobilePhones13")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("4")}. {this.props.t("Schedule.MobilePhones14")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("5")}. {this.props.t("Schedule.MobilePhones15")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("6")}. {this.props.t("Schedule.MobilePhones16")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("7")}. {this.props.t("Schedule.MobilePhones17")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("8")}. {this.props.t("Schedule.MobilePhones18")}</p>
                                            </div>
                                            <div className='text-[#0B0909] mt-4 text-[10px] md:text-sm'>
                                                <h3 className='text-xs md:text-[16px] font-medium p-3'>{this.props.t("Schedule.MobilePhones2")}</h3>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("1")}. {this.props.t("Schedule.MobilePhones21")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("2")}. {this.props.t("Schedule.MobilePhones22")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("3")}. {this.props.t("Schedule.MobilePhones23")}</p>
                                                <p className='font-normal p-1 ml-1'>{this.props.t("4")}. {this.props.t("Schedule.MobilePhones24")}</p>
                                            </div>
                                            <div className='p-3 text-[10px] md:text-sm text-[#0B0909]'>
                                                <h3 className='text-[#D90000] text-xs md:text-[16px] pt-1 font-medium'>{this.props.t("Schedule.Excluded Products")}</h3>
                                                <p className='pt-2 pb-4'>{this.props.t("Schedule.Products")}:</p>
                                                <p>{this.props.t("1")} {this.props.t("Schedule.Products1")}</p>
                                                <p>{this.props.t("2")} {this.props.t("Schedule.Products2")}</p>
                                                <p>{this.props.t("3")} {this.props.t("Schedule.Products3")}</p>
                                                <p>{this.props.t("4")} {this.props.t("Schedule.Products4")}</p>
                                                <p>{this.props.t("5")} {this.props.t("Schedule.Products5")}</p>
                                                <p>{this.props.t("6")} {this.props.t("Schedule.Products6")}</p>
                                                <p>{this.props.t("7")} {this.props.t("Schedule.Products7")}</p>
                                                <p>{this.props.t("8")} {this.props.t("Schedule.Products8")}</p>
                                                <p>{this.props.t("9")} {this.props.t("Schedule.Products9")}</p>
                                                <p>{this.props.t("10")} {this.props.t("Schedule.Products10")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <Footer />
                        </div >
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
export default withTranslation()(Schedule);