import React, { Component } from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { ThreeDots } from 'react-loader-spinner';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

class ProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: '',
            link: '',
            html: '',
            get: '',
            selectedSeller: 0,
            getButtonDisabled: false,
            getCopyButtonDisabled: false,
        }
    }
    render() {
        const language = this.props.t('language');
        const genlink = () => {
            this.setState({ getButtonDisabled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                product_id: this.props.product.product_id,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/generate_ihub_link`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            this.setState({ link: result.link, html: result.HTML, getButtonDisabled: false })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }

        const page = () => {
            this.setState({ page: "get-link" });
            window.scrollTo(400, 400)
        }
        const copyHTML = () => {
            this.setState({ getCopyButtonDisabled: true });
            let x = document.querySelector('#html-code');
            navigator.clipboard.writeText(x.value);
            toast.success("Copied!")
            setTimeout(() => {
                this.setState({ getCopyButtonDisabled: false })
            }, 1000);
        }

        return (
            <>
                <>
                    {this.props.product.seller_products_variants[this.state.selectedSeller] ?
                        <div className='drop-shadow rounded-xl bg-white'>
                            {!this.props.loading ?

                                <>

                                    <div>
                                        <img className="bg-[#FDF2FF] w-full aspect-square rounded-t-lg" alt='im' src={this.props.product.products_images[0].url} />
                                        <div className='px-2 py-1'>
                                            <div className='text-[10px] font-semibold line-clamp-1'>{this.props.product.product_name[language]}</div>
                                            <div className='flex items-center text-sm text-[#D90000] font-medium mt-1'>{this.props.t("Rs")}.{this.props.product?.seller_products_variants[this.state.selectedSeller]?.product_variant.product_mrp}</div>
                                            <s className='text-[10px] font-semibold line-clamp-1'>{this.props.t("Rs")}.{this.props.product?.seller_products_variants[this.state.selectedSeller].price}</s>
                                            <div className='text-[10px] font-semibold line-clamp-1'>{this.props.product.product_description[language]}</div>
                                            <div className='text-[10px] font-semibold line-clamp-1'>********** {this.props.t("Idea Hub.4,654")}</div>
                                            <div className='text-[10px] text-gray-600 line-clamp-1'>{this.props.product?.seller_products_variants[this.state.selectedSeller].createdAt.substring(0, 10)}, {this.props.product?.seller_products_variants[this.state.selectedSeller].createdAt.substring(11, 19)}</div>
                                            {/* <div className='text-[10px] text-gray-600 line-clamp-1'>Ends: Jun 30,2023, 11:59 PM</div> */}
                                        </div>
                                        <div className='flex p-2 justify-between'>
                                            <div onClick={() => genlink()}>
                                                <button onClick={() => page()} className={`text-[#565656] bg-[#FFD814] drop-shadow-[#00000040]  text-[11px] py-1 px-2 rounded-2xl relative ${this.state.getButtonDisabled ? 'bg-[#FFD814]' : 'bg-[#FFD814]'}`}>
                                                    <div className={`${!this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                        {this.props.t("Get Link")}
                                                    </div>
                                                    <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                        <ThreeDots
                                                            height="32"
                                                            width="30"
                                                            radius="9"
                                                            color="#000"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClassName=""
                                                            visible={true}
                                                        />
                                                    </div>
                                                </button>
                                            </div>
                                            {/* <div className='flex'>
                                        <div className='bg-[#D9D9D9] rounded-full mr-1 p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                                            </svg>
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-full ml-1 p-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
                                            </svg>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        <Skeleton className="w-full aspect-square" />
                                        <div className='px-2'>
                                            <div className=""><Skeleton count={6} /></div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        : ""}
                </>
                {this.state.page &&
                    <div className=' absolute z-[1] bg-white rounded-xl mb-6 border border-gray-300 drop-shadow shadow-[#00000040]'>
                        <div className='bg-[#D9D9D97A]   p-4 rounded-t-xl'>
                            <span className='text-[#D90000] text-sm font-bold'>{this.props.t("Get Link")}</span>
                        </div>
                        <div className='flex text-center justify-center'>
                            <div className='text-xs items-center md:w-4/6 p-4'>
                                <span className='font-bold'>{this.props.t("Idea Hub.Directions")}:</span>
                                <span className='font-medium'> {this.props.t("Idea Hub.Directions1")}</span>
                            </div>
                        </div>
                        <div className='block'>
                            <div className='flex'>
                                <div className='text-center my-2 w-full md:pr-8'>
                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("HTML")}</span>
                                    <div className='my-4'>
                                        <div className="flex text-center justify-center">
                                            <div className="flex items-center mr-4">
                                                <input id="inline-radio" type="radio" value="" onClick={() => this.setState({ get: "get-html" })} name="inline-radio-group" className={`w-[10px] bg-[#D9D9D9] h-[10px] ${this.state.get === "get-html"}`} />
                                                <label htmlFor="inline-radio" className="ml-1 text-xs font-bold text-gray-900">{this.props.t("HTML")}</label>
                                            </div>
                                            <div className="flex items-center mr-4">
                                                <input id="inline-2-radio" type="radio" value="" onClick={() => this.setState({ get: "get-link" })} name="inline-radio-group" className={`w-[10px] bg-[#D9D9D9] h-[10px] ${this.state.get === "get-link"}`} />
                                                <label htmlFor="inline-2-radio" className="ml-1 text-xs font-bold text-gray-900">{this.props.t("LINK")}</label>
                                            </div>
                                            {/* <div className="flex items-center mr-4">
                                                <input id="inline-3-radio" type="radio" value="" name="inline-radio-group" className="w-[10px] bg-[#D9D9D9] h-[10px]" />
                                                <label htmlFor="inline-3-radio" className="ml-1 text-xs font-bold text-gray-900">Short Link</label>
                                            </div> */}
                                        </div>
                                    </div>
                                    {this.state.get === "" && <div className='flex items-center justify-center my-2 md:px-8 px-4'>
                                        <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html || ""}></textarea>
                                    </div>}
                                    {this.state.get === "get-html" && <div className='flex items-center justify-center my-2 md:px-8 px-4'>
                                        <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html || ""}></textarea>
                                    </div>}
                                    {this.state.get === "get-link" && <div className='flex items-center justify-center my-2 md:px-8 px-4'>
                                        <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.link || ""}></textarea>
                                    </div>}
                                </div>
                            </div>
                            <div className='w-full text-center border-t border-[#697475] text-sm my-2'>
                                <button className={`border border-[#B3B3B3] py-2 my-4 text-xs text-[#565656] px-2 rounded-3xl bg-[#FFD814] relative ${this.state.getCopyButtonDisabled ? 'bg-[#FFD814]' : 'bg-[#FFD814]'}`} onClick={() => copyHTML()} >
                                    <div className={`${!this.state.getCopyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                        {this.props.t("Copy Code")}
                                    </div>
                                    <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getCopyButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                        <ThreeDots
                                            height="32"
                                            width="30"
                                            radius="9"
                                            color="#000"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClassName=""
                                            visible={true}
                                        />
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>}
            </>
        )
    }
}
export default withTranslation()(ProductCard);