import React, { Component } from 'react'
import Select from 'react-select';
import india from '../assets/icons/Indian.png'
import uk from '../assets/icons/UK.png'
import usa from '../assets/icons/USA.png'
import canada from '../assets/icons/Canada.png'
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.png'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Topbar extends Component {
    constructor(props) {
        super(props);
        const storedOption = localStorage.getItem('selectedLanguage');
        this.state = {
            selectedCountry: { value: 'India', label: 'India', icon: <img className='w-5 h-5' src={india} alt='icon' /> },
            selectedLanguage: storedOption ? storedOption : 'en',
        };
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
    }
    handleCountryChange = (selectedOption) => {
        this.setState({ selectedCountry: selectedOption });
    }
    handleLanguageChange = (event) => {
        const { i18n } = this.props;
        i18n.changeLanguage(event.target.value);
        const selectedLanguage = event.target.value;
        this.setState({ selectedLanguage });
        localStorage.setItem('selectedLanguage', selectedLanguage);
    }
    render() {
        const countryOptions = [
            { value: 'India', label: 'India', icon: <img className='w-5 h-5' src={india} alt='icon' /> },
            // { value: 'USA', label: 'USA', icon: <img className='w-5 h-5' src={usa} alt='icon' /> },
            // { value: 'CAN', label: 'Canada', icon: <img className='w-5 h-5' src={canada} alt='icon' /> },
            // { value: 'UK', label: 'UK', icon: <img className='w-5 h-5' src={uk} alt='icon' /> },
        ];
        const customStyles = {
            control: (provided) => ({
                ...provided,
                background: 'transparent',
                border: 'none',
                boxShadow: 'none',
            }),
            indicatorSeparator: () => null,
        };
        return (
            <div className="flex w-full fixed top-0 md:top-0 text-[#000000] font-medium left-0 items-center px-[2%] sm:px-[5%] mdd:px-[15%] lg1:px-[20%] text-[9px] md:text-xs z-20 bg-[#EAEDED]">
                <div className='flex w-full'>
                    <div className='md:hidden flex  items-center'>
                        <div className="w-[80px] overflow-hidden">
                            <Link to={"/Dashboard"}>
                                <img src={logo} alt="logo" className="w-full" />
                            </Link>
                        </div>
                    </div>
                    <div className='flex ml-auto'>
                        <div className="flex flex-col">
                            <Select
                                id="country"
                                name="country"
                                value={this.state.selectedCountry}
                                onChange={this.handleCountryChange}
                                options={countryOptions}
                                getOptionLabel={(option) => (
                                    <div className='flex'>
                                        <span className="mr-1">{option.icon}</span>
                                        <span className='mt-1 text-[#000000] font-medium'>{option.label}</span>
                                    </div>
                                )}
                                classNamePrefix="react-select"
                                className=" px-1"
                                styles={customStyles}
                            />
                        </div>
                        <div className="flex">
                            <select
                                id="language"
                                name="language"
                                value={this.state.selectedLanguage}
                                onChange={this.handleLanguageChange}
                                className="bg-transparent"
                            >
                                <option value="en">English-EN</option>
                                <option value="hi">हिंदी-HI</option>
                                <option value="pa">पंजाबी-PA</option>
                            </select>
                        </div>
                        <div className='flex ml-1 items-center'>
                            {this.props.token === null ?
                                <Link to={'/signin'}><h3>{this.props.t("SignIn")}</h3></Link>
                                :
                                <div className='flex'>
                                    <h1>{this.props.t("Store")}: </h1>
                                    <h3 className='ml-1'>{this.props.affiliate_info?.affiliate_store_id}</h3>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info,
    };
};

export default connect(mapStateToProps, null)(withTranslation()(Topbar));