import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

class Nav extends Component {
    render() {
        return (
            <div className='hidden md:block w-full fixed top-[90px] z-[9] text-[8px] sm1:text-xs lg1:text-sm'>
                <nav className="bg-[#407BF9]">
                    <div className=" px-[2%] sm:px-[5%] mdd:px-[15%] lg1:px-[20%] mx-auto">
                        <div className="flex items-center">
                            <ul className="flex flex-row font-medium mt-0 mr-6 space-x-2 text-sm ">
                                <li>
                                    <Link to={'/dashboard'}><button className="bg-blue-500 text-white py-3 px-2 ">{this.props.t("Home")}</button></Link>
                                </li>
                                <li>
                                    <div className="relative group">
                                        <button className="bg-blue-500 text-white py-3 px-2 flex"><span>{this.props.t("Nav.Product Linking")}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block">
                                            <Link to={'/product-linking'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Product Links")}</Link>
                                            <Link to={'/banner'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Banners")}</Link>
                                            {/* <Link to={'/product-linking'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Link to Any Page")}</Link>
                                            <Link className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Dukkandaar Bounty Program")}</Link> */}
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="relative group">
                                        <button className="bg-blue-500 text-white py-3 px-2 flex">{this.props.t("Nav.Promotions")}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block">
                                            <Link to={'/ideahub'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Idea Hub")}</Link>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="relative group">
                                        <button className="bg-blue-500 text-white py-3 px-2 flex">{this.props.t("Nav.Tools")}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block">
                                            <Link to={'/sitestripe'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.SiteStripe")}</Link>
                                            <Link to={'/linkchecker'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Link Checker")}</Link>
                                            {/* <Link className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Product Advertising API")}</Link> */}
                                        </div>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className="relative group">
                                        <button className="bg-blue-500 text-white py-3 px-2 flex">{this.props.t("Nav.Reports")}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block">
                                            <Link to={'/summary'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Consolidated Summary")}</Link>
                                            <Link to={'/report'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Earnings Reports")}</Link>
                                        </div>
                                    </div>
                                </li> */}
                                <li>
                                    <div className="relative group">
                                        <button className="bg-blue-500 text-white py-3 px-2 flex">{this.props.t("Nav.Help")}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block">
                                            {/* <Link className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Product Help")}</Link>
                                            <Link className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Resource Center")}</Link>
                                            <Link className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Operating Agreement")}</Link> */}
                                            {/* <Link to={'/schedule'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Advertising Fee Schedule")}</Link> */}
                                            <Link to={'/contact'} className="block px-4 py-2 text-gray-800 hover:bg-blue-500 hover:text-white">{this.props.t("Nav.Contact Us")}</Link>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
        )
    }
}
export default withTranslation()(Nav);