import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Footer from '../component/HomeFooter';
import Notice from '../component/Notice'
import Chart from 'react-apexcharts'
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'
import toast from 'react-hot-toast'

class Dashborad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: '40%'
          }
        },
        // fill: {
        //   colors: ['#9D9D9D']
        // },
        colors: ['#58E2C2'],
        stroke: {
          width: [3, 0],
          curve: 'straight'
        },
        title: {
          text: 'Earning Overview',
          align: 'left',
          style: {
            fontWeight: "Normal",
            color: "#fff"
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#fff',
            }
          },
        },
        yaxis: [{
          axisBorder: {
            show: true,
            color: '#475157'
          },
          labels: {
            style: {
              colors: '#fff',
            }
          },
        }],
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          labels: {
            colors: '#fff',
            useSeriesColors: false
          },
          markers: {
            width: 8,
            height: 8,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: ['#58E2C2', '#9D9D9D'],
            radius: 6
          },
        }
      },
      series: [{
        name: 'Revenue',
        data: [] // Initially empty, to be filled with API data
      }],
      loading: true,
      total_clicks: '',
      total_orders: '',
      total_revenue: '',
    }
  }
  componentDidMount() {
    if (this.state.loading && this.props.loadingCompleted) {
      this.setState({ loading: false });
    }
    if (this.props.token && this.state.loading === true) {
      this.fetchSummaryData();
    }
    if (this.props.token && this.state.loading === true) {
      this.fetchWeeklyData();
    }
  }
  componentDidUpdate() {
    if (this.state.loading && this.props.loadingCompleted) {
      this.setState({ loading: false });
    }
    // console.log(this.props);
    if (this.props.token && this.state.loading === true) {
      this.fetchSummaryData();
    }
    if (this.props.token && this.state.loading === true) {
      this.fetchWeeklyData();
    }
  }
  fetchWeeklyData = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", this.props.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/graph_data`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.code === 401 || result.code === 407) {
          this.props.logoutDispatch();
        }
        else {
          if (result.status === "Success" || result.status === "success") {
            // toast.success(result.msg)
            const labels = result.data.map(item => item.label);
            const revenueData = result.data.map(item => item.revenue);

            // Update the state with the new chart data
            this.setState({
              options: {
                ...this.state.options,
                xaxis: {
                  ...this.state.options.xaxis,
                  categories: labels
                }
              },
              series: [{ ...this.state.series, data: revenueData }],
              loading: false
            });
          }
        }
      })
      .catch(error => console.log('error', error));
  }
  fetchSummaryData = () => {
    var myHeaders = new Headers();
    myHeaders.append("token", this.props.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/analytics`, requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log(result);
        if (result.code === 401 || result.code === 407) {
          this.props.logoutDispatch();
        }
        else {
          if (result.status === "Success" || result.status === "success") {
            this.setState({ loading: false, total_clicks: result.total_clicks, total_orders: result.total_orders, total_revenue: result.total_revenue });
          }
        }
      })
      .catch(error => console.log('error', error));
  }
  render() {
    return (
      <>
        {this.props.token ? (
          <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
            <div>
              <Topbar />
              <Navbar />
              <Nav />
              {this.state.loading ?
                <>
                  <div>
                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                      <Notice loading={this.state.loading} />
                      <div className='md:flex block bg-[#d9d9d97a] p-2 my-[10px] rounded-lg'>
                        <div className=' md:w-full md:p-4 rounded-lg my-[10px]'>

                          <Skeleton className='w-full rounded-[10px] md:rounded-[20px] cursor-pointer aspect-video' />
                        </div>
                        <div className='block'>
                          <div className=' md:w-fit md:h-fit md:ml-4 text-[#B3B3B3] text-xs rounded-lg my-[10px]'>
                            <div className='md:w-[250px] '>
                              <Skeleton className='w-full rounded-[10px] md:rounded-[20px] cursor-pointer aspect-video' />
                            </div>
                          </div>
                          <div className='bg-[#475157] md:w-fit md:h-fit md:ml-4 text-[#B3B3B3] text-xs rounded-lg my-[10px]'>
                            <div className='w-[250px]'>
                              <Skeleton count={2} className='w-full' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </> :
                <>
                  <div>
                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                      <Notice />
                      <div className="w-full flex gap-6 my-2 overflow-auto">
                        <div className="flex items-center whitespace-nowrap rounded-[0.375rem] md:w-1/3 border-[1px] p-[0.75rem]">
                          <span className="w-14 h-14 bg-[#2388FB] rounded-full flex items-center justify-center text-white">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"></path>
                            </svg>
                          </span>
                          <span className="ml-4">
                            <p className="text-lg text-gray-600">{this.props.t("Dashboard.Total Earnings")}:</p>
                            <p className="font-medium">{this.state.total_revenue}</p>
                          </span>
                        </div>
                        <div className="flex items-center whitespace-nowrap rounded-[0.375rem] md:w-1/3 border-[1px] p-[0.75rem]">
                          <span className="h-14 w-14 p-2 bg-[#2388FB] flex items-center justify-center text-white rounded-full">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="32" width="32" xmlns="http://www.w3.org/2000/svg">
                              <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                            </svg>
                          </span>
                          <span className="ml-4">
                            <p className="text-lg text-gray-600">{this.props.t("Dashboard.Total Order Items")}:</p>
                            <p className="font-medium">{this.state.total_orders}</p>
                          </span>
                        </div>
                        <div className="flex items-center whitespace-nowrap rounded-[0.375rem] md:w-1/3 border-[1px] p-[0.75rem]">
                          <span className="h-14 w-14 p-2 bg-[#2388FB] flex items-center justify-center text-white rounded-full">
                            <img src="https://cdn-icons-png.flaticon.com/512/10199/10199556.png" alt='clicks' className='h-14 w-14 rounded-full' />
                          </span>
                          <span className="ml-4">
                            <p className="text-lg text-gray-600">{this.props.t("Dashboard.Clicks")}</p>
                            <p className="font-medium">{this.state.total_clicks}</p>
                          </span>
                        </div>

                      </div>
                      <div className='md:flex block bg-[#d9d9d97a] p-2 my-[10px] place-content-center rounded-lg'>
                        <div className='bg-[#475157] md:w-[calc(100%-150px)] md:p-4 rounded-lg my-[10px]'>
                          <div className='w-full'>

                            {/* Dont touch this code.... Highly Sensitive */}
                            {/* ------------------- */}
                            <Chart options={this.state.options} series={this.state.series} type="bar" className="w-full h-full" />
                            {/* ------------------- */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              <Footer />
            </div>
            {
              this.state.loading && <Loader />
            }
          </div>
        ) : (
          <div><AccessDenied /></div>
        )}
      </>
    )
  }
}
export default withTranslation()(Dashborad);