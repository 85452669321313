import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

class Notice extends Component {
  render() {
    return (
      <>
        {this.props.loading ?
          <>
            <div className=' text-[10px] mdd:text-xs font-medium border-2 rounded-[20px] border-[#F89C15]'>
              <div className='flex p-[6px]  mdd:p-3'>
                <div className="w-[30%] mt-[2px] ml-1 "><Skeleton /></div>
              </div>
              <div className='sm1:px-8 pl-8 pb-4'>
                <ul className='list-disc'>
                  <div className=""><Skeleton count={4} /></div></ul>
              </div>
            </div>
          </>
          :
          <>
            <div className='bg-[#407BF92E] text-[10px] mdd:text-xs font-medium border-2 rounded-[20px] border-[#F89C15]'>
              <div className='flex p-[6px]  mdd:p-3'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
                <span className=' mt-[2px] ml-1 '>{this.props.t("Notice.Importance Notice")}</span>
              </div>
              <div className='sm1:px-8 pl-8 pb-4'>
                <ul className='list-disc'>
                  <li>{this.props.t("Notice.notice")}.</li>
                  <li>{this.props.t("Notice.notice1")} <Link to={'/banking-details'} className='underline'>{this.props.t("Notice.notice2")}</Link>, {this.props.t("Notice.notice3")}.</li>
                </ul>
              </div>
            </div>
          </>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    affiliate_info: state.affiliate_info,
  };
};
export default connect(
  mapStateToProps,
  null
)(withTranslation()(Notice));