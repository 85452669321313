import { Breadcrumbs } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

class LinkPage extends Component {
    render() {
        return (
            <div>
                {this.props.loading ?
                    <>
                        <div className=' p-5 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                            <div className='flex justify-between mb-2 items-center'>
                                <div className="w-[30%] "><Skeleton /></div>
                                <div className="w-[30%] max-w-[70px]"><Skeleton /></div>
                            </div>
                            <div className='sm1:px-8'>
                                <ul className='list-disc'>
                                <div className=""><Skeleton count={3} /></div></ul>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className='bg-[#407BF92E] p-5 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                            <div className='flex justify-between mb-2 items-center'>
                                <Breadcrumbs aria-label="breadcrumb" separator="›">
                                    <Link to="/dashboard" className="text-[#407BF9] text-[10px] mdd:text-xs font-medium">
                                    {this.props.t("Home")}
                                    </Link>
                                    <Link
                                        className="text-[#407BF9] text-[10px] mdd:text-xs font-medium"
                                        style={{ color: "#000000" }}
                                    >
                                        {this.props.t("LinkPage.Link To Any Page")}
                                    </Link>
                                </Breadcrumbs>
                                <div className=' text-[10px]  mdd:text-xs'>{this.props.t("LinkPage.Tracking ID")}: {this.props.affiliate_info?.tracking_id}</div>
                            </div>
                            <div className='sm1:px-8'>
                                <ul className='list-disc'>
                                    <li className='text-[10px] mdd:text-xs font-medium'>{this.props.t("LinkPage.Note")}.</li>
                                </ul>
                            </div>
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info,
    };
};

export default connect(mapStateToProps, null)(withTranslation()(LinkPage));