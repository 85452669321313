import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const Loader = ({ top }) => {
    return (
        <div className={`w-[100%] h-[100vh] absolute top-[0px] left-0 z-[9999] bg-[#55555590] overflow-hidden flex flex-col justify-center items-center`}>
            <RotatingLines
                strokeColor="white"
                strokeWidth="2"
                animationDuration="0.75"
                width="50"
                visible={true}
            />
            <div className='font-semibold mt-2 text-white'>Loading...</div>
        </div>
    );
}

export default Loader;
