import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

//importing Image
import strip from '../assets/images/strip.png'
import { connect } from 'react-redux'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class SiteStripe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    render() {
        return (
            <>
                {this.props.token ? (
            <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                <div>
                    <Topbar />
                    <Navbar />
                    <Nav />
                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                        <Notice loading={this.state.loading} />
                        {this.state.loading ?
                            <>
                                <div className='p-5 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                    <div className='flex justify-between items-center mb-2'>
                                        <div className="w-[30%] "><Skeleton /></div>
                                        <div className="w-[30%] max-w-[70px]"><Skeleton /></div>
                                    </div>
                                    <div className='md:px-8'>
                                        <ul className=''>
                                        <div><Skeleton count={4} /></div>
                                        </ul>
                                    </div>
                                    <div className=' w-full items-center justify-center mt-4 aspect-square'>
                                    <div className="px-8"><Skeleton /></div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className='bg-[#407BF92E] p-5 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                    <div className='flex justify-between items-center mb-2'>
                                        <Breadcrumbs aria-label="breadcrumb" separator="›">
                                            <Link to="/dashboard" className="text-[#407BF9] text-xs font-medium">
                                            {this.props.t("Home")}
                                            </Link>
                                            <Link
                                                className="text-[#407BF9] text-xs font-medium"
                                                style={{ color: "#000000" }}
                                            >
                                                {this.props.t("SiteStripe.Site Stripe")}
                                            </Link>
                                        </Breadcrumbs>
                                        <div className='text-[10px] md:text-xs'>{this.props.t("SiteStripe.Tracking ID")}:<span className='font-bold'> {this.props.affiliate_info?.tracking_id}</span></div>
                                    </div>
                                    <div className='md:px-8'>
                                        <ul className=''>
                                            <li className='text-[10px] md:text-xs font-medium'>{this.props.t("SiteStripe.Note")}
                                                <span className='underline'> {this.props.t("Learn More")}</span></li>
                                        </ul>
                                    </div>
                                    <div className='flex w-full items-center justify-center mt-4'>
                                        <img src={strip} alt='stirp' />
                                        <div className=' w-11/12 h-[20px] md:w-8/12 lgx:w-[43rem] absolute rounded-3xl smx:h-[35px] bg-transparent border-2 border-[#F89C15]'></div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <Footer />
                </div>
                {
                    this.state.loading && <Loader />
                }
            </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info,
    };
};

export default connect(mapStateToProps, null)(withTranslation()(SiteStripe));