import React, { Component } from "react";
import { Link } from "react-router-dom";
import Topbar from "../component/Topbar";
import { ThreeDots } from "react-loader-spinner";
import Loader from "../component/Loader";
import { withTranslation } from "react-i18next";

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            mobile: "",
            gender: "",
            date: "",
            password: "",
            createButtonDisabled: false,
            otpSent: false,
            submitButtonDisabled: false,
            loading: true
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    render() {
        const setName = (_name) => {
            this.setState({ name: _name });
        };
        const setMail = (_email) => {
            this.setState({ email: _email });
        };
        const setPassword = (_password) => {
            this.setState({ password: _password });
        };
        const setMobile = (_mobile) => {
            if (_mobile.length <= 10) {
                this.setState({ mobile: _mobile });
            }
        };
        const setDate = (_date) => {
            this.setState({ date: _date });
        };
        const setGender = (_gender) => {
            this.setState({ gender: _gender });
        };
        const enterOtp = (val) => {
            this.setState({ otp: val });
        };
        const sendOTP = (e) => {
            if (this.state.name !== "" && this.state.email !== "" && this.state.mobile !== "" && this.state.gender !== "" && this.state.date !== "" && this.state.password !== "") {
                e.preventDefault();
                this.setState({ createButtonDisabled: true });
                setTimeout(() => {
                    this.setState({ createButtonDisabled: false, otpSent: true });
                }, [2000])
            }
            else {
                console.log("Complete the form !");
            }
        }
        const verifyOTP = () => {
            this.setState({ submitButtonDisabled: true })
        }
        return (
            <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
            <>
                <Topbar />
                <div className="mt-[68px] sm:mt-[88px] mb-[20px] px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] w-full">
                    <div className="flex flex-col justify-start items-center w-full max-w-[450px] m-auto">
                        {
                            !this.state.otpSent ?
                                <div className="text-[#697475] space-y-3 border-0 sm:border-2  border-[#697475] rounded-3xl sm:px-9 px-0 w-full">
                                    <h1 className="text-2xl sm:text-3xl font-medium pt-8 text-black mb-2">
                                    {this.props.t("Register.Create Account")}
                                    </h1>
                                    <form className="flex flex-col space-y-3 text-xs">
                                        <label className="flex flex-col font-semibold mb-1" htmlFor="name">
                                        {this.props.t("Register.Your Name")}
                                            <input
                                                className="border-2 py-1 rounded-lg px-3 placeholder:font-normal"
                                                id="name"
                                                type="text"
                                                required
                                                name="name"
                                                minLength={3}
                                                maxLength={20}
                                                placeholder="First and last name"
                                                onChange={(e) => setName(e.target.value)}
                                                value={this.state.name}
                                            />
                                        </label>

                                        <label
                                            className="flex flex-col font-semibold mb-1"
                                            htmlFor="email"
                                        >
                                            {this.props.t("Register.Email")}
                                            <input
                                                id="email"
                                                type="email"
                                                required
                                                className="border-2 py-1 rounded-lg px-3 placeholder:font-normal"
                                                name="email"
                                                onChange={(e) => setMail(e.target.value)}
                                                value={this.state.email}
                                                placeholder="Your mail address"
                                            />
                                        </label>
                                        <label
                                            className="flex flex-col font-semibold mb-1"
                                            htmlFor="mobile"
                                        >
                                            {this.props.t("Register.Mobile")}
                                            <input
                                                id="mobile"
                                                type="number"
                                                required
                                                className="border-2 py-1 rounded-lg px-3 placeholder:font-normal"
                                                name="mobile"
                                                onChange={(e) => setMobile(e.target.value)}
                                                value={this.state.mobile}
                                                placeholder="Your mobile number"
                                            />
                                        </label>
                                        <label
                                            className="flex flex-col font-semibold mb-1"
                                            htmlFor="gender"
                                        >
                                            {this.props.t("Register.Gender")}
                                            <select
                                                required
                                                name="gender"
                                                id="gender"
                                                className="border-2 py-1 rounded-lg px-3 placeholder:font-normal font-normal"
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                <option value="">{this.props.t("Register.Select Your Gender")}</option>
                                                <option value="male">{this.props.t("Register.Male")}</option>
                                                <option value="female">{this.props.t("Register.Female")}</option>
                                                <option value="other">{this.props.t("Register.Other")}</option>
                                            </select>
                                        </label>
                                        <label
                                            className="flex flex-col font-semibold mb-1"
                                            htmlFor="dob"
                                        >
                                            {this.props.t("Register.Date of Birth")}
                                            <div className="flex justify-start items-center">
                                                <input
                                                    type="date"
                                                    onChange={(e) => setDate(e.target.value)}
                                                    required
                                                    className="border-2 py-1 uppercase rounded-lg text-sm px-3 placeholder:font-normal mr-1"
                                                />
                                            </div>
                                        </label>
                                        <label
                                            className="flex flex-col font-semibold mb-1"
                                            htmlFor="password"
                                        >
                                            {this.props.t("Register.Password")}
                                            <input
                                                id="password"
                                                type="password"
                                                minLength={8}
                                                required
                                                className="border-2 py-1 rounded-lg px-3 placeholder:font-normal"
                                                name="password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={this.state.password}
                                                placeholder="Password"
                                            />
                                        </label>
                                        <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.createButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => sendOTP(e)} >
                                            <div className={`${!this.state.createButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Register.Create your account")}
                                            </div>
                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.createButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                <ThreeDots
                                                    height="32"
                                                    width="30"
                                                    radius="9"
                                                    color="#000"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        </button>
                                    </form>
                                    <div className="pb-4 sm:pb-5 border-b-2 text-sm">
                                        <h6>
                                        {this.props.t("Register.Description")}<br />
                                        {this.props.t("Dukkandaar's")} 
                                            <span className="text-[#059CA6] px-1 cursor-pointer">
                                            {this.props.t("Register.Conditions of Use")}
                                            </span>
                                            {this.props.t("Register.and")}
                                            <span className="text-[#059CA6] px-1 cursor-pointer">
                                            {this.props.t("Register.Privacy Policy")}.
                                            </span>
                                        </h6>
                                    </div>
                                    <div className="text-sm pb-4">
                                        <h6>
                                        {this.props.t("Register.Already have an Account?")}
                                            <Link to={"/signin"}>
                                                <span className="text-[#059CA6] px-1 cursor-pointer">
                                                {this.props.t("SignIn")}
                                                </span>
                                            </Link>
                                        </h6>
                                    </div>
                                </div> :
                                <div className="space-y-3 border-0 sm:border-2 border-[#697475] rounded-3xl sm:px-9 px-3 w-full">
                                    <h1 className="text-2xl text-black pt-8 ">{this.props.t("Register.Enter OTP")}</h1>
                                    <form className="flex flex-col space-y-2 sm:pb-8">
                                        <div className="flex text-sm space-x-1 justify-between items-center">
                                            <div className="flex">
                                                <p className="text-[#697475] font-bold">
                                                {this.props.t("Register.+91")} {this.state.mobile}
                                                </p>
                                                <p className="text-[#059CA6] ml-2 cursor-pointer" onClick={() => this.setState({ otpSent: false })}>
                                                {this.props.t("Register.Change")}
                                                </p>
                                            </div>
                                            <p className="text-[#059CA6] text-end text-xs">
                                            {this.props.t("Register.Resend OTP")}
                                            </p>
                                        </div>
                                        <input
                                            id="otp"
                                            type="password"
                                            name="otp"
                                            required
                                            maxLength={6}
                                            className="border-2 py-1 rounded-lg px-3"
                                            onChange={(e) => enterOtp(e.target.value)}
                                            value={this.state.otp}
                                        />
                                        <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.submitButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => verifyOTP(e)} >
                                            <div className={`${!this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                            {this.props.t("Register.Submit")} 
                                            </div>
                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                <ThreeDots
                                                    height="32"
                                                    width="30"
                                                    radius="9"
                                                    color="#000"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        </button>
                                    </form>
                                </div>
                        }
                        <div className="flex text-sm  text-[#059CA6] justify-between sm:justify-center mt-3 sm:space-x-12 w-full">
                            <p className="cursor-pointer">{this.props.t("Register.Conditions of Use")}</p>
                            <p className="cursor-pointer">{this.props.t("Register.Privacy Notice")}</p>
                            <p className="cursor-pointer">{this.props.t("Register.Help")}</p>
                        </div>
                        <div className="text-center text-[#697475] text-sm mt-1">
                            © {this.props.t("@")}
                        </div>

                    </div>
                </div>
            </>
                {
                    this.state.loading && <Loader />
                }
            </div>
        );
    }
}
export default withTranslation()(CreateAccount);