import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'

// importing images
import banner1 from '../assets/images/banner.png'
import banner2 from '../assets/images/banner.png'
import banner3 from '../assets/images/banner3.PNG'
import banner4 from '../assets/images/banner3.PNG'
import { connect } from 'react-redux'
import { toast } from 'react-hot-toast'
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { ThreeDots } from 'react-loader-spinner'
import { withTranslation } from 'react-i18next'
import Accessdenied from '../component/ProtectRoutes/AccessDenied'

class Banners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: '',
            width: '',
            height: '',
            html: '',
            html1: '',
            html2: '',
            html3: '',
            loading: true,
            getButtonDisabled: false,
            getButtonDisabled1: false,
            getButtonDisabled2: false,
            getButtonDisabled3: false,
            getCopyButtonDisabled: false,
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    handleCategoryChange = (event) => {
        this.setState({ category: event.target.value });
    };
    render() {
        const banner = () => {
            this.setState({ getButtonDisabled: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "category_1_id": this.state.category,
                "width": "300",
                "height": "250",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/generate_banner_link`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {

                            this.setState({ html: result.HTML, getButtonDisabled: false })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        const banner12 = () => {
            this.setState({ getButtonDisabled1: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "category_1_id": this.state.category,
                "width": "728",
                "height": "90",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/generate_banner_link`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            this.setState({ html1: result.HTML, getButtonDisabled1: false })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        const banner13 = () => {
            this.setState({ getButtonDisabled2: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "category_1_id": this.state.category,
                "width": "160",
                "height": "600",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/generate_banner_link`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            this.setState({ html2: result.HTML, getButtonDisabled2: false })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        const banner14 = () => {
            this.setState({ getButtonDisabled3: true });
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "category_1_id": this.state.category,
                "width": "300",
                "height": "600",
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/generate_banner_link`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            this.setState({ html3: result.HTML, getButtonDisabled3: false })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        const copyHTML = (textArea) => {
            console.log(textArea);
            if (textArea.value !== "") {
                navigator.clipboard.writeText(textArea.value);
                toast.success("HTML Copied!")
            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            {this.state.loading ?
                                <>
                                    <div>
                                        <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                            <Notice loading={this.state.loading} />
                                            <div className='bg-[#D9D9D97A] p-2 md:p-3 my-5 rounded-lg space-x-5 block'>
                                                <div className="w-[30%] "><Skeleton /></div>
                                                <div className="w-[30%] "><Skeleton count={3} /></div>
                                                <div className='flex flex-row text-center justify-center items-center pt-2 text-xs md:text-sm font-medium'><div className="w-[10%] "><Skeleton /></div> | <div className="w-[10%] "><Skeleton /></div> | <div className="w-[10%] "><Skeleton /></div> | <div className="w-[10%] "><Skeleton /></div>   | <div className="w-[30%] "><Skeleton /></div></div>
                                            </div>
                                            <div className=' mb-8 rounded-xl'>
                                                <div className='bg-[#D9D9D97A] bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <div className="w-[10%] "><Skeleton /></div>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <div className=" "><Skeleton /></div>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <Skeleton className="w-[300px] h-[250px] aspect-square" />
                                                </div>
                                                <div className='mt-1 flex justify-center items-center text-center'>

                                                    <div className=" w-full xxs1:w-[calc(100%/3-10px)] "><Skeleton /></div>

                                                    <div className='w-[5%] py-1 m-1 text-[#565656] px-2 rounded-3xl'> <Skeleton /></div>
                                                </div>
                                                <div className='flex justify-center text-center'>
                                                    <div className='text-center items-center justify-center w-[60%] h-[200px] '>
                                                        <Skeleton className='w-[60%] h-[200px] text-center items-center justify-center' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=' mb-8 rounded-xl'>
                                                <div className='bg-[#D9D9D97A] bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <div className="w-[10%] "><Skeleton /></div>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <div className=" "><Skeleton /></div>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <div className=' items-center justify-center w-[728px] h-[90px]'>
                                                        <Skeleton className="w-[728px] h-[90px] " />
                                                    </div>
                                                </div>
                                                <div className='mt-1 flex justify-center items-center text-center'>

                                                    <div className=" w-full xxs1:w-[calc(100%/3-10px)] "><Skeleton /></div>

                                                    <div className='w-[5%] py-1 m-1 text-[#565656] px-2 rounded-3xl'> <Skeleton /></div>
                                                </div>
                                                <div className=''>
                                                    <div className=' '>
                                                        <Skeleton className='' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=' mb-8 rounded-xl'>
                                                <div className='bg-[#D9D9D97A] bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <div className="w-[10%] "><Skeleton /></div>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <div className=" "><Skeleton /></div>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <div className=' items-center justify-center w-[728px] h-[90px]'>
                                                        <Skeleton className="w-[728px] h-[90px] " />
                                                    </div>
                                                </div>
                                                <div className='mt-1 flex justify-center items-center text-center'>

                                                    <div className=" w-full xxs1:w-[calc(100%/3-10px)] "><Skeleton /></div>

                                                    <div className='w-[5%] py-1 m-1 text-[#565656] px-2 rounded-3xl'> <Skeleton /></div>
                                                </div>
                                                <div className='flex justify-center text-center'>
                                                    <div className='text-center items-center justify-center w-[60%] h-[200px] '>
                                                        <Skeleton className='w-[60%] h-[200px] text-center items-center justify-center' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=' mb-8 rounded-xl'>
                                                <div className='bg-[#D9D9D97A] bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <div className="w-[10%] "><Skeleton /></div>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <div className=" "><Skeleton /></div>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <div className=' items-center justify-center w-[728px] h-[90px]'>
                                                        <Skeleton className="w-[728px] h-[90px] " />
                                                    </div>
                                                </div>
                                                <div className='mt-1 flex justify-center items-center text-center'>

                                                    <div className=" w-full xxs1:w-[calc(100%/3-10px)] "><Skeleton /></div>

                                                    <div className='w-[5%] py-1 m-1 text-[#565656] px-2 rounded-3xl'> <Skeleton /></div>
                                                </div>
                                                <div className='flex justify-center text-center'>
                                                    <div className='text-center items-center justify-center w-[60%] h-[200px] '>
                                                        <Skeleton className='w-[60%] h-[200px] text-center items-center justify-center' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                            <Notice />
                                            <div className='bg-[#D9D9D97A] p-2 md:p-3 my-5 rounded-lg space-x-5 block'>
                                                <div className='pl-3'>
                                                    <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                        <Link to="/dashboard" className="text-[#407BF9] text-xs font-medium">
                                                            {this.props.t("Home")}
                                                        </Link>
                                                        <Link
                                                            className="text-[#407BF9] text-xs font-bold"
                                                            style={{ color: "#000000" }}
                                                        >
                                                            {this.props.t("Banners.Banner Links")}
                                                        </Link>
                                                    </Breadcrumbs>
                                                </div>
                                                <div className=' md:p-3'><span className='text-xs font-semibold'>{this.props.t("Banners.Note")}.</span>
                                                </div>
                                                <div className='text-center pt-2 text-xs md:text-sm font-medium'><Link className='hover:text-[#C7511F] hover:underline mx-1 md:mx-2'>{this.props.t("Banners.300")} x {this.props.t("Banners.250")}</Link> | <Link className='hover:text-[#C7511F] hover:underline mx-1 md:mx-2'>{this.props.t("Banners.728")} x {this.props.t("Banners.90")}</Link> | <Link className='hover:text-[#C7511F] hover:underline mx-1 md:mx-2'>{this.props.t("Banners.160")} x {this.props.t("Banners.600")}</Link> | <Link className='hover:text-[#C7511F] hover:underline mx-1 md:mx-2'>{this.props.t("Banners.300")} x {this.props.t("Banners.600")}</Link>
                                                </div>
                                            </div>
                                            <div className='bg-[#D9D9D97A] mb-8 rounded-xl'>
                                                <div className='bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("Banners.300")} x {this.props.t("Banners.250")}</span>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <span className='font-bold'>{this.props.t("Banners.Directions")}:</span>
                                                    <span className='font-medium'> {this.props.t("Banners.Note1")}.</span>
                                                </div>
                                                <div className='flex items-center justify-center'>
                                                    <img src={banner1} className='w-[300px] h-[250px] border-0 m-0' alt='banner' />
                                                </div>
                                                <div className='mt-1 items-center text-center'>
                                                    <select
                                                        value={this.state.product}
                                                        onChange={this.handleCategoryChange}
                                                        className='py-1 w-full xxs1:w-[calc(100%/3-10px)] px-1 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                    >
                                                        <option value="">{this.props.t("Select category")}</option>
                                                        {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category, i) => <option value={category.category_1_id} key={i}>{category.name}</option>)}
                                                    </select>
                                                    <button className={`border border-[#B3B3B3] py-1 m-1 text-[#565656] px-2 rounded-3xl bg-[#FFD814] relative ${this.state.getButtonDisabled ? 'bg-[#FFD814]' : 'bg-[#FFD814]'}`} onClick={() => banner()}>
                                                        <div className={`${!this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            {this.props.t("Get HTML")}
                                                        </div>
                                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            <ThreeDots
                                                                height="32"
                                                                width="30"
                                                                radius="9"
                                                                color="#000"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className='text-center my-2'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("HTML")}</span>
                                                    <div className='flex items-center justify-center my-2'>
                                                        <textarea id="html-code" rows="4" className="block p-2.5 mdd:w-3/5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html || ""} ></textarea>
                                                    </div>
                                                    <div className='w-full text-center text-sm my-2'>
                                                        <button className='border border-[#B3B3B3] py-2 text-[#565656] px-2 rounded-3xl bg-[#FFD814]' onClick={(e) => copyHTML(e.target.parentElement.parentElement.children[1].children[0])}>
                                                            {this.props.t("Copy HTML")}
                                                        </button>
                                                    </div>

                                                    <span className='text-xs font-bold'>{this.props.t("Note")} :</span> <span className='text-xs'>{this.props.t("Banners.Your Associates ID")} <span className='text-xs font-bold'> {this.props.affiliate_info?.affiliate_store_id} </span>{this.props.t("Banners.ID")}.</span>
                                                </div>
                                            </div>
                                            <div className='bg-[#D9D9D97A] mb-8 rounded-xl'>
                                                <div className='bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("Banners.728")} x {this.props.t("Banners.90")}</span>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <span className='font-bold'>{this.props.t("Banners.Directions")}:</span>
                                                    <span className='font-medium'> {this.props.t("Banners.Note1")}.</span>
                                                </div>
                                                <div className='flex items-center w-full justify-center'>
                                                    <img src={banner2} className='w-[728px] h-[90px] border-0 m-0' alt='banner' />
                                                </div>
                                                <div className='mt-1 items-center text-center'>
                                                    <select
                                                        value={this.state.product}
                                                        onChange={this.handleCategoryChange}
                                                        className='py-1 w-full xxs1:w-[calc(100%/3-10px)] px-1 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                    >
                                                        <option value="">{this.props.t("Select category")}</option>
                                                        {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category, i) => <option value={category.category_1_id} key={i}>{category.name}</option>)}
                                                    </select>
                                                    <button className={`border border-[#B3B3B3] py-1 m-1 text-[#565656] px-2 rounded-3xl relative ${this.state.getButtonDisabled1 ? 'bg-[#FFD814] ' : 'bg-[#FFD814]'}`} onClick={() => banner12()}>
                                                        <div className={`${!this.state.getButtonDisabled1 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            {this.props.t("Get HTML")}
                                                        </div>
                                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled1 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            <ThreeDots
                                                                height="32"
                                                                width="30"
                                                                radius="9"
                                                                color="#000"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className='text-center my-2'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("HTML")}</span>
                                                    <div className='flex items-center justify-center my-2'>
                                                        <textarea id="html-code" rows="4" className="block p-2.5 mdd:w-3/5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html1 || ""} ></textarea>
                                                    </div>
                                                    <div className='w-full text-center text-sm my-2'>
                                                        <button className='border border-[#B3B3B3] py-2 text-[#565656] px-2 rounded-3xl bg-[#FFD814]' onClick={(e) => copyHTML(e.target.parentElement.parentElement.children[1].children[0])}>
                                                            {this.props.t("Copy HTML")}
                                                        </button>
                                                    </div>

                                                    <span className='text-xs font-bold'>{this.props.t("Note")} :</span> <span className='text-xs'>{this.props.t("Banners.Your Associates ID")} <span className='text-xs font-bold'> {this.props.affiliate_info?.affiliate_store_id} </span>{this.props.t("Banners.ID")}.</span>
                                                </div>
                                            </div>
                                            <div className='bg-[#D9D9D97A] mb-8 rounded-xl'>
                                                <div className='bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("Banners.160")} x {this.props.t("Banners.600")}</span>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <span className='font-bold'>{this.props.t("Banners.Directions")}:</span>
                                                    <span className='font-medium'> {this.props.t("Banners.Note1")}.</span>
                                                </div>
                                                <div className='flex'>
                                                    <div className='flex items-center justify-center'>
                                                        <img src={banner3} className='md:p-4 p-1 w-[160px] h-[600px]' alt='banner' />
                                                    </div>
                                                    <div className='text-center self-center w-full md:pr-8 pr-4 my-2'>
                                                        <div className='mt-1 items-center text-center'>
                                                            <select
                                                                value={this.state.product}
                                                                onChange={this.handleCategoryChange}
                                                                className='py-1 w-full xxs1:w-[calc(100%/3-10px)] px-1 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                            >
                                                                <option value="">{this.props.t("Select category")}</option>
                                                                {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category, i) => <option value={category.category_1_id} key={i}>{category.name}</option>)}
                                                            </select>
                                                            <button className={`border border-[#B3B3B3] py-1 m-1 text-[#565656] px-2 rounded-3xl bg-[#FFD814] relative ${this.state.getButtonDisabled2 ? 'bg-[#FFD814] ' : 'bg-[#FFD814] '}`} onClick={() => banner13()}>
                                                                <div className={`${!this.state.getButtonDisabled2 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    {this.props.t("Get HTML")}
                                                                </div>
                                                                <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled2 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    <ThreeDots
                                                                        height="32"
                                                                        width="30"
                                                                        radius="9"
                                                                        color="#000"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>
                                                        <span className='text-[#D90000] text-sm font-bold'>{this.props.t("HTML")}</span>
                                                        <div className='my-2'>
                                                            <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html2 || ""} ></textarea>
                                                        </div>
                                                        <div className='w-full text-center text-sm my-2'>
                                                            <button className='border border-[#B3B3B3] py-2 text-[#565656] px-2 rounded-3xl bg-[#FFD814]' onClick={(e) => copyHTML(e.target.parentElement.parentElement.children[2].children[0])}>
                                                                {this.props.t("Copy HTML")}
                                                            </button>
                                                        </div>

                                                        <span className='text-xs font-bold'>{this.props.t("Note")} :</span> <span className='text-xs'>{this.props.t("Banners.Your Associates ID")} <span className='text-xs font-bold'> {this.props.affiliate_info?.affiliate_store_id} </span>{this.props.t("Banners.ID")}.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-[#D9D9D97A] mb-8 rounded-xl'>
                                                <div className='bg-white border border-[#9A9A9A] p-4 rounded-xl'>
                                                    <span className='text-[#D90000] text-sm font-bold'>{this.props.t("Banners.300")} x {this.props.t("Banners.600")}</span>
                                                </div>
                                                <div className='text-xs p-4'>
                                                    <span className='font-bold'>{this.props.t("Banners.Directions")}:</span>
                                                    <span className='font-medium'> {this.props.t("Banners.Note1")}.</span>
                                                </div>
                                                <div className='sm3:flex block'>
                                                    <div className='flex items-center h-full justify-center'>
                                                        <img src={banner4} className='md:p-4 p-1 w-[300px] h-[600px]' alt='banner' />
                                                    </div>
                                                    <div className='text-center self-center my-2 w-full md:pr-8 pr-4'>
                                                        <div className='mt-1 items-center text-center'>
                                                            <select
                                                                value={this.state.product}
                                                                onChange={this.handleCategoryChange}
                                                                className='py-1 w-full xxs1:w-[calc(100%/3-10px)] px-1 rounded-xl bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                            >
                                                                <option value="">{this.props.t("Select category")}</option>
                                                                {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category, i) => <option value={category.category_1_id} key={i}>{category.name}</option>)}
                                                            </select>
                                                            <button className={`border border-[#B3B3B3] py-1 m-1 text-[#565656] px-2 rounded-3xl  relative ${this.state.getButtonDisabled3 ? 'bg-[#FFD814]' : 'bg-[#FFD814]'}`} onClick={() => banner14()}>
                                                                <div className={`${!this.state.getButtonDisabled3 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    {this.props.t("Get HTML")}
                                                                </div>
                                                                <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.getButtonDisabled3 ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                    <ThreeDots
                                                                        height="32"
                                                                        width="30"
                                                                        radius="9"
                                                                        color="#000"
                                                                        ariaLabel="three-dots-loading"
                                                                        wrapperStyle={{}}
                                                                        wrapperClassName=""
                                                                        visible={true}
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>
                                                        <span className='text-[#D90000] text-sm font-bold'>{this.props.t("HTML")}</span>
                                                        <div className='flex items-center justify-center my-2'>
                                                            <textarea id="html-code" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300" disabled={true} value={this.state.html3 || ""} ></textarea>
                                                        </div>
                                                        <div className='w-full text-center text-sm my-2'>
                                                            <button className='border border-[#B3B3B3] py-2 text-[#565656] px-2 rounded-3xl bg-[#FFD814]' onClick={(e) => copyHTML(e.target.parentElement.parentElement.children[2].children[0])}>
                                                                {this.props.t("Copy HTML")}
                                                            </button>
                                                        </div>

                                                        <span className='text-xs font-bold'>{this.props.t("Note")} :</span> <span className='text-xs'>{this.props.t("Banners.Your Associates ID")} <span className='text-xs font-bold'> {this.props.affiliate_info?.affiliate_store_id} </span>{this.props.t("Banners.ID")}.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <Footer />
                            {/* <ToastContainer /> */}
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><Accessdenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        categories: state.categories,
        affiliate_info: state.affiliate_info,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(Banners));