import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashborad from "./pages/Dashborad";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Signin from "./pages/Signin";
import OnBoarding from "./pages/OnBoarding";
import ProductLinking from "./pages/ProductLinking";
import Banners from "./pages/Banners";
import IdeaHub from "./pages/IdeaHub";
import SiteStripe from "./pages/SiteStripe";
import LinkChecker from "./pages/LinkChecker";
import ConsolSummery from "./pages/ConsolSummery";
import Report from "./pages/Report";
import Fees from "./pages/Fees";
import Bounties from "./pages/Bounties";
import Schedule from "./pages/Schedule";
import Terms from "./pages/Terms";
import Policy from "./pages/Policy";
import ContactUs from "./pages/ContactUs";
import Tracking from "./pages/Tracking";
import BankingDetails from "./pages/BankingDetails";
import Notfound from "./component/ProtectRoutes/error404";

export default class AppRoutes extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route exact path="/dashboard" element={<Dashborad token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/product-linking" element={<ProductLinking token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/banner" element={<Banners token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/ideahub" element={<IdeaHub token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/sitestripe" element={<SiteStripe token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/linkchecker" element={<LinkChecker token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/summary" element={<ConsolSummery token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/report" element={<Report token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/Fees" element={<Fees token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/bounties" element={<Bounties token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/schedule" element={<Schedule token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    {/* <Route exact path="/payhistory" element={<PayHis token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} /> */}
                    <Route exact path="/contact" element={<ContactUs token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/tracking" element={<Tracking token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/" element={<Home loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/register" element={<Register loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/signin" element={<Signin loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/terms-condition" element={<Terms loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/policy" element={<Policy loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/onboarding" element={<OnBoarding token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="/banking-details" element={<BankingDetails token={this.props.token} loadingCompleted={this.props.loadingCompleted} />} />
                    <Route exact path="*" element={<Notfound />} />
                </Routes>
            </BrowserRouter>
        )
    }
}
