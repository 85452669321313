import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

class HomeFooter extends Component {
    render() {
        return (
            <div>
                <div className="text-[#9A9A9A] md:flex text-center border-t-2 mt-4 border-[#e7e7e7] justify-center relative">
                    <div className="my-4 mx-4 md1:mx-7">
                    <Link to="/policy">
                        <div className="my-1 text-[10px] sm4:text-xs text-[#007185] font-normal">
                            <p className="cursor-pointer whitespace-nowrap">{this.props.t("Policy.Privacy Policy")}</p>
                        </div>
                        </Link>
                    </div>
                    <div className="my-4 mx-4 md1:mx-7">
                        <Link to="/terms-condition">
                        <div className="my-1 text-[10px] sm4:text-xs text-[#007185] font-normal">
                            <p className="cursor-pointer whitespace-nowrap">{this.props.t("MainPage.Footer.Conditions of Use")}</p>
                        </div>
                        </Link>
                    </div>
                    <div className="my-4 mx-4 md1:mx-7">
                        <Link to="/contact">
                        <div className="my-1 text-[10px] sm4:text-xs text-[#007185] font-normal">
                            <p className="cursor-pointer whitespace-nowrap">{this.props.t("MainPage.Footer.Contact Us")}</p>
                        </div>
                        </Link>
                    </div>
                    <div className="my-4 mx-4 md1:mx-7">
                        <div className="my-1 text-[10px] sm4:text-xs text-[#0F1111] font-normal">
                            <p className="cursor-pointer whitespace-nowrap">© {this.props.t("@")}</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }
}
export default withTranslation()(HomeFooter);