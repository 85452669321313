import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import TrackingCard from '../component/TrackingCard'
import { toast } from 'react-hot-toast'
import { logout } from '../actions'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            query: '',
            loading: true
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    handleClick = () => {
        this.setState({ showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    render() {
        const checkId = () => {
            if (this.state.query !== '') {
                var myHeaders = new Headers();
                myHeaders.append("token", this.props.token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    tracking_tag: this.state.query
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/customer_check_tracking_tag`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();
                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                toast.success(result.msg)
                            } else {
                                toast.error(result.msg)
                            }
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                toast.error('Field is Empty')
            }
        }

        const createId = () => {
            if (this.state.query !== '') {
                var myHeaders = new Headers();
                myHeaders.append("token", this.props.token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    tracking_tag: this.state.query
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/customer_add_tracking_tag`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();
                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                window.location.reload();
                                toast.success(result.status)
                            } else {
                                toast.error(result.status)
                            }
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                toast.error('Field is Empty')
            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div><Toaster
                                position="top-center"
                                reverseOrder={false}
                            /></div>
                            <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                <Notice loading={this.state.loading} />
                                {this.state.loading ?
                                    <>
                                        <div className='bg-[#D7D7D72E] md:p-5 p-2 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='border-b border-[565656] mb-2'>
                                                <div className="w-[50%]"><Skeleton /></div>
                                            </div>
                                            <div>
                                                <div className='md:text-sm text-xs w-[20%] font-medium'><Skeleton /></div>
                                            </div>
                                            <div>
                                                <div className='text-[10px] md:text-xs mt-2 w-[60%]'><Skeleton /></div>
                                            </div>
                                            <div className="flex mt-6 ">
                                                <div className='w-[10%]'><Skeleton height={20} /></div>
                                            </div>
                                        </div>
                                        <div className='bg-[#D7D7D72E] border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='border-b border-[#697475]'>
                                                <div className='md:text-sm text-xs w-[20%] md:p-3 p-2 font-medium'><Skeleton /></div>
                                            </div>
                                            <div className=" p-4">
                                                <div className="relative overflow-x-auto">
                                                    <div className="w-full text-sm text-left text-black">
                                                        <div className="bg-gray-100 flex justify-between items-center">
                                                            <div className="py-4 px-6 font-medium text-black w-[20%]">
                                                                <Skeleton height={20} />
                                                            </div>
                                                            <div className="py-4 text-center border-l w-[20%] border-[#697573]">
                                                                <Skeleton height={30} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {Array.from({ length: 5 }, (_t, i) =>
                                                    <TrackingCard key={i} token={this.props.token} loading={true} />
                                                )}

                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='bg-[#D7D7D72E] md:p-5 p-2 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='border-b border-[565656] mb-2'>
                                                <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                    <Link to="/dashboard" className="text-[#407BF9] text-xs font-medium">
                                                        {this.props.t("Home")}
                                                    </Link>
                                                    <Link
                                                        className="text-[#407BF9] text-xs font-medium"
                                                    >
                                                        {this.props.t("Your Associates Accounts")}
                                                    </Link>
                                                    <Link
                                                        className="text-[#407BF9] text-xs font-medium"
                                                        style={{ color: "#000000" }}
                                                    >
                                                        {this.props.t("Tracking.Manage Your Tracking IDs")}
                                                    </Link>
                                                </Breadcrumbs>
                                            </div>
                                            <div>
                                                <div className='md:text-sm text-xs text-[#F89C15] font-medium'>{this.props.t("Tracking.Selected Tracking ID")}: <span className='text-[#000]'>{this.props.affiliate_info?.tracking_id}</span></div>
                                            </div>
                                            <div>
                                                <p className='text-[10px] md:text-xs mt-2'>{this.props.t("Tracking.note")}</p>
                                            </div>
                                            <div className="flex mt-6 ">
                                                <button onClick={this.handleClick} className="bg-[#FFD814] px-2 py-[6px] text-[#565656] text-xs rounded-full font-medium box-shadow whitespace-nowrap">{this.props.t("Tracking.Add Tracking ID")}</button>
                                            </div>
                                            {this.state.showModal && (
                                                <div className="fixed inset-0 flex items-center justify-center z-50">
                                                    <div className="bg-white rounded-lg">
                                                        <div className="relative">
                                                            <div className='flex justify-between items-center rounded-t-lg bg-[#F0F2F2]'>
                                                                <div className='p-3 left-2.5 text-[#F89C15] text-sm font-medium'>{this.props.t("Tracking.Add New Tracking ID")}</div>
                                                                <button type="button" onClick={this.closeModal} className="absolute right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="popup-modal">
                                                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                            <div className="text-center sm3:flex block w-full sm3:w-[500px] sm3:h-[150px]">
                                                                <div className="w-full">
                                                                    <div className="bg-white rounded-lg shadow-md p-6 text-center sm:text-left">
                                                                        <div><span className="text-lg font-semibold">{this.props.t("Tracking.Add a Tracking ID")}</span></div>
                                                                        <div className="py-4 flex items-center">
                                                                            <form className="w-full"><input type="text" name="" id="" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} className="border border-[#B3B3B3] px-4 py-2 text-sm w-full focus:outline-none focus:border-[#FFD814] rounded-md transition-all" placeholder={this.props.t("Tracking.Tracking Id")} required /></form>
                                                                        </div>
                                                                        <div className="flex justify-center sm:justify-between md:px-8">
                                                                            <div><button onClick={() => checkId()} className="bg-[#FFD814] rounded-md px-6 py-2 text-[#565656] text-sm font-medium whitespace-nowrap hover:bg-[#FFC700] focus:outline-none focus:ring focus:border-[#FFD814] transition-all">{this.props.t("Tracking.Check")}</button></div>
                                                                            <div className="mt-4 sm:mt-0"><button onClick={() => createId()} className="bg-[#FFD814] rounded-md px-6 py-2 text-[#565656] text-sm font-medium whitespace-nowrap hover:bg-[#FFC700] focus:outline-none focus:ring focus:border-[#FFD814] transition-all">{this.props.t("Tracking.Create")}</button></div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )}
                                        </div>
                                        <div className='bg-[#D7D7D72E] border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='border-b border-[#697475]'>
                                                <div className='md:text-sm text-xs text-[#F89C15] md:p-3 p-2 font-medium'>{this.props.t("Tracking.Search Tracking ID")}</div>
                                            </div>
                                            <div className=" p-4">
                                                <div className="relative overflow-x-auto">
                                                    <div className="w-full text-sm text-left text-black">
                                                        <div className="bg-gray-100 flex justify-between items-center">
                                                            <div className="py-4 px-6 font-medium text-black whitespace-nowrap">
                                                                {this.props.t("Tracking.Tracking Id")}
                                                            </div>
                                                            <div className="py-4 text-center w-1/3 md:w-1/5 border-l border-[#697573]">
                                                                {this.props.t("Tracking.Active")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {this.props.tracking_id && this.props.tracking_id.length > 0 && this.props.tracking_id.map((tracking, i) =>
                                                    <TrackingCard key={i} token={this.props.token} tracking={tracking} />
                                                )}
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

const mapStateToProps = (state) => {
    return {
        token: state.token,
        tracking_id: state.tracking_id,
        affiliate_info: state.affiliate_info
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Tracking));