import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

import captcha from '../assets/images/captcha.png'
import { Link } from 'react-router-dom'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-hot-toast'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: '',
            email: '',
            subject: '',
            associate_id: '',
            website_url: '',
            payee_name: '',
            comments: '',
            captcha: '',
            addButtonDisabled: false,

        };
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onChangeAssociate_id = this.onChangeAssociate_id.bind(this);
        this.onChangeWebsite_url = this.onChangeWebsite_url.bind(this);
        this.onChangePayee_name = this.onChangePayee_name.bind(this);
        this.onChangeComments = this.onChangeComments.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }
    onChangeName(e) {
        this.setState({ name: e.target.value });
    }
    onChangeEmail(e) {
        this.setState({ email: e.target.value });
    }
    onChangeSubject(e) {
        this.setState({ subject: e.target.value });
    }
    onChangeAssociate_id(e) {
        this.setState({ associate_id: e.target.value });
    }
    onChangeWebsite_url(e) {
        this.setState({ website_url: e.target.value });
    }
    onChangePayee_name(e) {
        this.setState({ payee_name: e.target.value });
    }
    onChangeComments(e) {
        this.setState({ comments: e.target.value });
    }
    onChangeCaptcha(e) {
        this.setState({ captcha: e.target.value });
    }

    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    render() {
        const ContactUs = async (e) => {
            if (this.state.name !== "" && this.state.email !== "" && this.state.subject !== "" && this.state.comments !== "") {
                e.preventDefault();
                this.setState({ addButtonDisabled: true });
                var myHeaders = new Headers();
                myHeaders.append("token", this.props.token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    name: this.state.name,
                    email: this.state.email,
                    subject: this.state.subject,
                    // associate_id: this.state.associate_id,
                    // website_url: this.state.website_url,
                    // payee_name: this.state.payee_name,
                    comments: this.state.comments
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/contact_us`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 401 || result.code === 407) {
                            this.props.logoutDispatch();
                        }
                        else {
                            if (result.status === "Success" || result.status === "success") {
                                this.setState({ formsubmited: true });
                                toast.success('Response Submitted')
                            }
                            else {
                                toast.error(result.msg)
                            }
                        }
                    })
                    .catch((error) => {
                        toast.error("Error in server !")
                    });
                setTimeout(() => {
                    this.setState({ addButtonDisabled: false });
                }, 1500);
            }
            // else {
            //     setTimeout(() => {
            //         this.setState({ addButtonDisabled: false });
            //     }, 1500);
            //     toast.error('Please Fill all Fields')

            // }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                <Notice loading={this.state.loading} />
                                {this.state.loading ?
                                    <>
                                        <div>
                                            <div className='border-2 bg-[#D7D7D72E] border-[#F89C15] p-2 rounded-xl mt-2'>
                                                <div className='text-base md:text-3xl text-[#D90000] font-medium'><div className="w-[60%] "><Skeleton /></div></div>
                                            </div>
                                            <div className='text-base'>
                                                <div className='border-2 bg-[#D7D7D72E] border-[#F89C15] p-4 rounded-xl mt-2'>
                                                    <div className='text-[#D90000] md:text-xl font-medium'><div className="w-[40%] "><Skeleton /></div></div>
                                                    <div className='text-[10px] md:text-xs pt-1 text-[#565656]'><div className="w-[50%] "><Skeleton /></div></div>

                                                    <div className='text-base md:p-2'>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[10%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div>
                                                            <div className=' rounded-full block text-xs font-medium w-[20%] mt-2' ><Skeleton height={50} /></div>
                                                            <Link className='text-xs text-[#697475]'><div className="w-[10%] "><Skeleton /></div></Link>
                                                            <div>
                                                                <label className='text-xs text-[#697475]'><div className="w-[10%] "><Skeleton /></div></label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="w-[30%] text-xs font-medium ml-1 mb-1"><Skeleton /></div>
                                                            <div className=' rounded-full block text-xs font-medium w-full max-w-[400px]' ><Skeleton height={25} /></div>
                                                        </div>
                                                        <div className='w-[10%] text-[10px] md:text-xs text-[#565656] mt-2'><div><Skeleton height={20} /></div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>
                                            <div className='border-2 bg-[#D7D7D72E] border-[#F89C15] p-2 rounded-xl mt-2'>
                                                <h1 className='text-base md:text-3xl text-[#D90000] font-medium'>{this.props.t("ContactUs.Contact Associates Customer Service")}</h1>
                                            </div>
                                            <div className='text-base'>
                                                <div className='border-2 bg-[#D7D7D72E] border-[#F89C15] p-4 rounded-xl mt-2'>
                                                    <h3 className='text-[#D90000] md:text-xl font-medium'>{this.props.t("ContactUs.Email Dukkandaar.com Associate Support")}:</h3>
                                                    <p className='text-[10px] md:text-xs pt-1 text-[#565656]'>{this.props.t("ContactUs.Note")}.</p>

                                                    <form className='text-base md:p-2'>
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="name">{this.props.t("ContactUs.Your Name")}: <span className='text-[#D90000]'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name='name'
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                value={this.state.name || ""}
                                                                onChange={this.onChangeName}
                                                                required />
                                                        </div>
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="email">{this.props.t("ContactUs.Primary E-mail Address")}: <span className='text-[#D90000]'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name='email'
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                placeholder='xyz@gmail.com'
                                                                value={this.state.email || ""}
                                                                onChange={this.onChangeEmail}
                                                                required />
                                                        </div>
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="subject">{this.props.t("ContactUs.Subject")}: <span className='text-[#D90000]'>*</span></label>
                                                            <input type="text"
                                                                value={this.state.subject || ""}
                                                                name='subject'
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                onChange={this.onChangeSubject}
                                                                required />
                                                        </div>
                                                        {/* <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="associate_id">{this.props.t("ContactUs.Associate ID")}:</label>
                                                            <input
                                                                type="text"
                                                                name='associate_id'
                                                                value={this.state.associate_id || ""}
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                placeholder='mywork20'
                                                                onChange={this.onChangeAssociate_id}
                                                                required />
                                                        </div>
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="website_url">{this.props.t("ContactUs.Website URL")}: <span className='text-xs text-[#697475]'>({this.props.t("ContactUs.recommended")})</span></label>
                                                            <input type="text"
                                                                name='website_url'
                                                                value={this.state.website_url || ""}
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                onChange={this.onChangeWebsite_url}
                                                                required />
                                                        </div>
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="payee_name">{this.props.t("ContactUs.Payee Name")}: <span className='text-xs text-[#697475]'>({this.props.t("ContactUs.optional")})</span></label>
                                                            <input
                                                                type="text"
                                                                name='payee_name'
                                                                value={this.state.payee_name || ""}
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                onChange={this.onChangePayee_name}
                                                                required />
                                                            <label className='text-[10px] md:text-xs text-[#697475]'>{this.props.t("ContactUs.Note1")}.</label>
                                                        </div> */}
                                                        <div>
                                                            <label className='text-xs font-medium ml-1 mb-1' htmlFor="comments">{this.props.t("ContactUs.Comments")}: <span className='text-[#D90000]'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name='comments'
                                                                value={this.state.comments || ""}
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                onChange={this.onChangeComments}
                                                                required />
                                                            {/* <label className='text-[10px] md:text-xs text-[#697475]'>{this.props.t("ContactUs.Note2")}.</label> */}
                                                        </div>
                                                        {/* <div>
                                                            <img src={captcha} className='w-[9rem]' alt='captcha' />
                                                            <label className='text-xs text-[#697475]'>{this.props.t("ContactUs.Try a different image")}</label>
                                                            <div>
                                                                <label className='text-xs text-[#697475]'>{this.props.t("ContactUs.Try audio captcha")}</label>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <label className='text-[10px] md:text-xs font-medium ml-1 mb-1' htmlFor="captcha">{this.props.t("ContactUs.Type the characters in the above image")}  <span className='text-[#D90000]'>*</span></label>
                                                            <input
                                                                type="text"
                                                                name='captcha'
                                                                value={this.state.captcha || ""}
                                                                className='border border-[#697475] rounded-full block focus:outline-none px-2 py-1 text-xs font-medium w-full max-w-[400px]'
                                                                onChange={this.onChangeCaptcha}
                                                                required />
                                                        </div> */}
                                                        {/* <button className='bg-[#FFD814] p-1 md:p-2 rounded-3xl mt-2 ' onClick={(e) => { ContactUs(e); }}><div className='text-[10px] md:text-xs text-[#565656]'>{this.props.t("ContactUs.Send E-mail")}</div></button> */}
                                                        <button
                                                            className={`bg-[#FFD814] p-1 md:p-2 rounded-3xl mt-2 text-[10px] md:text-xs text-[#565656] ${this.state.addButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'} relative`}
                                                            onClick={(e) => { ContactUs(e); }}
                                                            disabled={this.state.addButtonDisabled}
                                                        >
                                                            <div className={`${!this.state.addButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                {this.props.t("ContactUs.Send E-mail")}
                                                            </div>
                                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.addButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                                <ThreeDots
                                                                    height="32"
                                                                    width="30"
                                                                    radius="9"
                                                                    color="#000"
                                                                    ariaLabel="three-dots-loading"
                                                                    wrapperStyle={{}}
                                                                    wrapperClassName=""
                                                                    visible={true}
                                                                />
                                                            </div>
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token
    };
};
export default connect(mapStateToProps, null)(withTranslation()(ContactUs));