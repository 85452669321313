import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import { ThreeDots } from 'react-loader-spinner'
import { toast } from 'react-hot-toast';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Nav from '../component/Nav';
import Navbar from '../component/Navbar';
import Loader from '../component/Loader';
import { withTranslation } from 'react-i18next';
import AccessDenied from '../component/ProtectRoutes/AccessDenied';

class BankingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitButtonDisabled: false,
            name: "",
            bank_name: "",
            ac_n: "",
            c_ac_n: "",
            ifsc: "",
            branch: "",
            navigateTo: null,
            loading: true
        }
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    render() {
        const submit = (e) => {
            this.setState({ submitButtonDisabled: true })
            if (
                this.state.name === "" ||
                this.state.bank_name === "" ||
                this.state.ac_n === "" ||
                this.state.c_ac_n === "" ||
                this.state.ifsc === "" ||
                this.state.branch === ""
            ) {
                toast.error("Please fill all the details correctly!")
                this.setState({ submitButtonDisabled: false });
            }
            else {
                if (this.state.ac_n !== this.state.c_ac_n) {
                    toast.error("Account Number does not match!")
                    this.setState({ submitButtonDisabled: false });
                }
                else {

                    e.preventDefault();
                    var myHeaders = new Headers();
                    myHeaders.append("token", this.props.token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "name": this.state.bank_name,
                        "account_holder_name": this.state.name,
                        "account_number": this.state.ac_n,
                        "ifsc_code": this.state.ifsc,
                        "location": this.state.branch,
                        "currency": "INR"
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_bank_details`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    toast.success("Bank details added successfully !");
                                    this.setState({ navigateTo: "/dashboard" })
                                }
                                else {
                                    toast.error(result.msg)
                                }
                                this.setState({ submitButtonDisabled: false });
                            }
                        })
                        .catch(error => {
                            toast.error("Server Error!")
                        });
                }
            }
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            {
                                this.state.navigateTo && <Navigate to={this.state.navigateTo} />
                            }
                            <Topbar />
                            <Navbar />
                            <div className='mt-[104px] sm:mt-[114px] mb-[20px] px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%]'>
                                <div className='my-[20px] text-2xl font-medium text-center'>{this.props.t("BankDetail.Enter your Banking Details")}</div>
                                <div className='w-full max-w-[500px] m-auto'>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="name" className='ml-1'>{this.props.t("BankDetail.Account Holder Name")}</label>
                                        <input type="text" name="name" id="name" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="bank_name" className='ml-1'>{this.props.t("BankDetail.Name of the Bank")}</label>
                                        <input type="text" name="bank_name" id="bank_name" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.bank_name} onChange={(e) => this.setState({ bank_name: e.target.value })} />
                                    </div>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="account" className='ml-1'>{this.props.t("BankDetail.Account Number")}</label>
                                        <input type="password" name="account" id="account" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.ac_n} onChange={(e) => this.setState({ ac_n: e.target.value })} />
                                    </div>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="confirm_account" className='ml-1'>{this.props.t("BankDetail.Confirm Account Number")}</label>
                                        <input type="text" name="confirm_account" id="confirm_account" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.c_ac_n} onChange={(e) => this.setState({ c_ac_n: e.target.value })} />
                                    </div>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="ifsc" className='ml-1'>{this.props.t("BankDetail.IFSC Code")}</label>
                                        <input type="text" name="ifsc" id="ifsc" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.ifsc} onChange={(e) => this.setState({ ifsc: e.target.value })} />
                                    </div>
                                    <div className='text-xs font-medium my-[10px]'>
                                        <label htmlFor="branch" className='ml-1'>{this.props.t("BankDetail.Branch")}</label>
                                        <input type="text" name="branch" id="branch" className='focus:outline-none rounded-full border-2 py-1 px-3 block w-full mt-1' value={this.state.branch} onChange={(e) => this.setState({ branch: e.target.value })} />
                                    </div>
                                    <div className='flex gap-3'>
                                        <button type="submit" className={`text-xs text-black rounded-full font-semibold box-shadow relative px-3 py-1 ${this.state.submitButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => submit(e)} >
                                            <div className={`${!this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                {this.props.t("Submit")}
                                            </div>
                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                <ThreeDots
                                                    height="25"
                                                    width="30"
                                                    radius="9"
                                                    color="#000"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        </button>
                                        <button type="submit" className={`text-xs text-white rounded-full font-semibold box-shadow relative px-3 py-1 bg-[#407BF9]`} onClick={(e) => this.setState({ navigateTo: '/dashboard' })} >
                                            <div className={`${!this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                {this.props.t("BankDetail.Skip")}
                                            </div>
                                        </button>

                                    </div>
                                </div>
                            </div>
                            {/* <ToastContainer /> */}
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
export default connect(
    mapStateToProps,
    null
)(withTranslation()(BankingDetails));