import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import Loader from '../component/Loader'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'

class LinkChecker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            url: '',
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    handleCheck = () => {
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "url": this.state.url
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/link_checker`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    this.props.logoutDispatch();
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        toast.success(result.msg)
                    }
                }
            })
            .catch(error => console.log('error', error));
    }
    render() {
        const changeUrl = (e) => {
            this.setState({ url: e.target.value });
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                <Notice loading={this.state.loading} />
                                {this.state.loading ?
                                    <>
                                        <div className='p-5 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='flex justify-between items-center mb-2'>
                                                <div className="w-[30%] "><Skeleton /></div>
                                            </div>
                                            <div className='md:px-8'>
                                                <ul className=''>
                                                    <div><Skeleton count={4} /></div>
                                                </ul>
                                            </div>
                                            <div className=' w-full items-center justify-center mt-4 aspect-square'>
                                                <div className="px-8"><Skeleton /></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='bg-[#D7D7D72E] md:p-5 p-2 border-2 mt-4 rounded-[20px] border-[#F89C15]'>
                                            <div className='flex justify-between mb-2'>
                                                <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                    <Link to="/dashboard" className="text-[#407BF9] text-xs font-medium">
                                                        {this.props.t("Home")}
                                                    </Link>
                                                    <Link
                                                        className="text-[#407BF9] text-xs font-medium"
                                                        style={{ color: "#000000" }}
                                                    >
                                                        {this.props.t("LinkChecker.Link Checker")}
                                                    </Link>
                                                </Breadcrumbs>
                                            </div>
                                            <div className='md:px-8'>
                                                <ul className=''>
                                                    <li className='md:text-xs text-[10px] font-medium'>{this.props.t("LinkChecker.Description")}.</li>
                                                </ul>
                                            </div>
                                            <div className="flex mt-6 md:px-8 md:py-4">
                                                <input type="text" name="" id="" onChange={(e) => changeUrl(e)} value={this.state.url} placeholder={this.props.t("LinkChecker.Enter your link here")} className="border border-[#B3B3B3] bg-[#F0F2F2] rounded-full pl-2 pr-8 py-1 text-xs w-2/3 placeholder:text-[#565656]" />
                                                <button onClick={this.handleCheck} className="bg-[#FFD814] px-2 py-[6px] text-[#565656] text-xs -ml-7 rounded-full font-medium box-shadow whitespace-nowrap">{this.props.t("Highlight HTML")}</button>
                                            </div>
                                            {/* on click show */}
                                            <div className='hidden'>
                                                <div className='flex md:px-8'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 stroke-[#D90000]">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                    <span className='md:text-xs text-[10px] text-[#D90000] font-medium mt-[2px] ml-1'>{this.props.t("LinkChecker.Fail")}: {this.props.t("LinkChecker.FailNote")}.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(LinkChecker));