import React, { Component } from 'react'
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { logout } from '../actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (
            this.state.showFilters &&
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.setState({ showFilters: false });
        };
    }
    render() {
        const showFilter = () => {
            this.setState({ showFilters: true });
        };
        const logout = () => {
            this.props.logoutDispatch();
        }
        return (
            <div className="w-full fixed top-[38px] md:top-[38px] py-[6px] px-[2%] sm:px-[5%] mdd:px-[15%] lg1:px-[20%] z-10 bg-white text-[8px] sm1:text-xs lg1:text-sm">
                <div className='flex justify-between items-center'>
                    <div className='hidden md:flex items-center'>
                            <Link to={"/Dashboard"} className='w-[140px]'>
                                <img src={logo} alt="logo" className="w-full" />
                            </Link>
                        </div>
                    <div className='md:hidden flex items-center'>
                        <div >
                            <svg width="30" onClick={() => showFilter()} height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_346_13)">
                                    <path d="M3.5 3.5C3.5 2.94772 3.94772 2.5 4.5 2.5H13.5C14.0523 2.5 14.5 2.94772 14.5 3.5C14.5 4.05228 14.0523 4.5 13.5 4.5H4.5C3.94772 4.5 3.5 4.05228 3.5 3.5Z" fill="#347AE2" stroke="#F89C15" />
                                    <path d="M3.5 10.5C3.5 9.94772 3.94772 9.5 4.5 9.5H19.5C20.0523 9.5 20.5 9.94772 20.5 10.5C20.5 11.0523 20.0523 11.5 19.5 11.5H4.5C3.94772 11.5 3.5 11.0523 3.5 10.5Z" fill="#347AE2" stroke="#F89C15" />
                                    <path d="M3.5 17.5C3.5 16.9477 3.94772 16.5 4.5 16.5H25.5C26.0523 16.5 26.5 16.9477 26.5 17.5C26.5 18.0523 26.0523 18.5 25.5 18.5H4.5C3.94772 18.5 3.5 18.0523 3.5 17.5Z" fill="#347AE2" stroke="#F89C15" />
                                </g>
                                <defs>
                                    <filter id="filter0_d_346_13" x="0" y="0" width="30" height="23" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dy="1" />
                                        <feGaussianBlur stdDeviation="1.5" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.47 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_346_13" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_346_13" result="shape" />
                                    </filter>
                                </defs>
                            </svg>
                        </div>
                        {this.state.showFilters && (
                            <div
                                ref={this.wrapperRef}
                                className="absolute rounded-xl mt-7 bg-[#407BF9] z-50 top-0"
                            >
                                <div className="overflow-y-auto w-[157px] h-[180px] text-white text-sm font-bold">
                                    <ul className='block pt-2'>
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/dashboard'}>{this.props.t("Home")}</Link></li>
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/product-linking'}>{this.props.t("Navbar.Product Linking")}</Link></li>
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/banner'}>{this.props.t("Nav.Banners")}</Link></li>
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/ideahub'}>{this.props.t("Navbar.Promotions")}</Link></li>
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/linkchecker'}>{this.props.t("Navbar.Tools")}</Link></li>
                                        {/* <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/report'}>{this.props.t("Navbar.Reports")}</Link></li> */}
                                        <li className='pl-6 py-1 hover:bg-[#222E3E] hover:text-[#F89C15]'><Link to={'/contact'}>{this.props.t("Navbar.Help")}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className='text-xs'>
                        <div className="relative group">
                            <div className="flex items-center">
                                {this.props.token === null ?
                                    <div className="flex items-center">
                                        <Link to={"/signin"} className="mx-[10px] flex">
                                            <div className="font-semibold whitespace-nowrap">
                                            {this.props.t("SignIn")}
                                            </div>
                                        </Link>
                                    </div> :
                                    <div>
                                        <button className="py-3 px-2 flex"><span>{this.props.affiliate_info?.email}</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <div className="hidden absolute bg-white py-1 w-48 shadow-lg rounded-md group-hover:block z-[20]">
                                            {/* <Link to={'/setings'} className="block px-2 md:px-4 py-2 text-gray-800 hover:underline hover:text-[#F89C15]">{this.props.t("Navbar.Account Settings")}</Link> */}
                                            {/* <Link to={'/payhistory'} className="block px-2 md:px-4 py-2 text-gray-800 hover:underline hover:text-[#F89C15]">{this.props.t("Navbar.Payment History")}</Link> */}
                                            <Link to={'/tracking'} className="block px-2 md:px-4 py-2 text-gray-800 hover:underline hover:text-[#F89C15]">{this.props.t("Navbar.Manage Your Tracking")}</Link>
                                            <Link to={'/'} ><div onClick={() => logout()} className="block px-2 md:px-4 py-2 text-gray-800 hover:underline hover:text-[#F89C15]">{this.props.t("Navbar.Sign Out")}</div></Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};
const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Navbar));