import React, { Component } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-hot-toast';
import { logout } from '../actions';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { withTranslation } from 'react-i18next';

class TrackingCard extends Component {
    render() {
        const trackActive = () => {
            var myHeaders = new Headers();
            myHeaders.append("token", this.props.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                customer_tracking_tag_id: this.props.tracking.customer_tracking_tag_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/customer_active_tracking_tag`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401 || result.code === 407) {
                        this.props.logoutDispatch();
                    }
                    else {
                        if (result.status === "Success" || result.status === "success") {
                            window.location.reload();
                            toast.success(result.status)
                        }
                        else {
                            toast.error(result.status)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
        return (
            <div>
                <div className="relative overflow-x-auto">
                    {
                        this.props.loading ?
                            <div className="w-full text-sm text-left text-black">
                                <div className=''>

                                    <div className="bg-white flex justify-between items-center">
                                        <div className="px-6 py-4 font-mediumn w-[20%] text-black whitespace-nowrap">
                                            <Skeleton height={20} />
                                        </div>
                                        <div className="py-4 text-center w-[20%] border-l border-[#697573]">
                                            <div className="rounded-full "><Skeleton height={30} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="w-full text-sm text-left text-black">
                                <div className=''>

                                    <div className="bg-white flex justify-between items-center">
                                        <div className="px-6 py-4 font-medium text-black whitespace-nowrap">
                                            {this.props.tracking.tracking_id}
                                        </div>
                                        {(this.props.tracking && this.props.tracking.active && this.props.tracking.active === true) ?
                                            <div className="py-4 text-center w-1/3 md:w-1/5 border-l border-[#697573]">
                                                <button className="rounded-full bg-[#F89C15] p-2">{this.props.t("Activated")}</button>
                                            </div> :
                                            <div className="py-4 text-center  w-1/3 md:w-1/5 border-l border-[#697573]">
                                                <button onClick={() => trackActive()} className="rounded-full bg-[#F89C15] p-2">{this.props.t("Activate")}</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch) => {
    const logoutDispatch = () => {
        dispatch(logout());
    }
    return {
        logoutDispatch
    }
};

export default connect(null, mapDispatchToProps)(withTranslation()(TrackingCard));