import { combineReducers } from "redux";
import loginToken from "./loginToken";
import affiliateInfo from "./affiliateInfo";
import allAddresses from "./allAddresses";
import trackingid from "./trackingId";
import category from "./category";

export default combineReducers({
    token: loginToken,
    affiliate_info: affiliateInfo,
    all_addresses: allAddresses,
    tracking_id: trackingid,
    categories: category
});
