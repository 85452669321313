import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import ProductCard from '../component/ProductCard'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { connect } from 'react-redux'
import Loader from '../component/Loader'
import InfiniteScroll from 'react-infinite-scroll-component'
import { RotatingLines } from 'react-loader-spinner'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class IdeaHub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            products: null,
            total: 0,
            category: '',
            name: '',
            loading: true,
            url: null,
            page: 1
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
        if (this.state.products === null) {
            this.getData(this.state.page, this.state.query, this.state.category, this.state.name);
        }
    }
    getData = (page, query, category, name, type) => {
        var myHeaders = new Headers();
        myHeaders.append("token", this.props.token);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        let url = `${process.env.REACT_APP_BASE_URL}/filter_products/${page}?featured_content=${query}&category_4_id=${category}&name=${name}`;
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code === 401 || result.code === 407) {
                    this.props.logoutDispatch();
                }
                else {
                    if (result.status === "Success" || result.status === "success") {
                        let p = [];
                        if (type === 'more') {
                            if (this.state.products) {
                                p = [...this.state.products];
                            }
                            p = [...p, ...result.products]
                        }
                        else {
                            p = [...result.products];
                        }
                        this.setState({ products: p, total: result.total_items });
                    }
                }
            })
            .catch(error => console.log('error', error));

    }
    render() {
        const fetchMore = () => {
            let page = this.state.page;
            this.setState({ page: page + 1 })
            this.getData(page + 1, this.state.query, this.state.category, this.state.name, 'more');
        }
        const changeCategory = (e) => {
            this.setState({ category: e.target.value, page: 1 });
            this.getData(1, this.state.query, e.target.value, this.state.name, 'over');
        }
        const changeName = (e) => {
            this.setState({ name: e.target.value, page: 1 });
            this.getData(1, this.state.query, this.state.category, e.target.value, 'over');
        }
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />
                            <div>
                                <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                    <Notice loading={this.state.loading} />
                                    <div className='md:my-7 my-3'>
                                        {this.state.loading ?
                                            <div className="w-[30%] max-w-[100px]"><Skeleton /></div>
                                            :
                                            <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                <Link to="/dashboard" className="text-[#407BF9] text-xs font-medium">
                                                    {this.props.t("Home")}
                                                </Link>
                                                <Link
                                                    className="text-[#407BF9] text-xs font-bold"
                                                    style={{ color: "#000000" }}
                                                >
                                                    {this.props.t("Idea Hub.Idea Hub")}
                                                </Link>
                                            </Breadcrumbs>
                                        }
                                    </div>
                                    <div className='bg-[#D9D9D97A] mb-8 rounded-lg '>
                                        <div className='md:space-x-5 block p-3'>
                                            {this.state.loading ?
                                                <div className="w-[30%] max-w-[100px]"><Skeleton /></div>
                                                :
                                                <div><h2 className='text-xl md:px-5 font-medium'>{this.props.t("Idea Hub.Idea Hub")}</h2></div>
                                            }
                                            {this.state.loading ?
                                                <div><Skeleton count={3} /></div>
                                                :
                                                <>
                                                    <div><span className='text-[10px] md:text-xs font-semibold'>{this.props.t("Idea Hub.Contant")}</span></div>
                                                    <div><span className='text-[10px] md:text-xs font-semibold text-[#697475]'>{this.props.t("Idea Hub.Contant1")}.</span></div>
                                                </>
                                            }
                                        </div>
                                        <div className=' border-t border-[#B3B3B3]'>
                                            <div className='w-full p-2 pl-8'>
                                                {this.state.loading ?
                                                    <div className="w-[30%] max-w-[100px]"><Skeleton /></div>
                                                    :
                                                    <button className='border text-xs border-[#B3B3B3] py-1 text-[#565656] px-2 rounded-3xl bg-[#FFD814]'>
                                                        {this.props.t("Idea Hub.Filter")} & {this.props.t("Idea Hub.Sort")}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className=' mb-4'>
                                            {this.state.loading ?
                                                <div className="w-[30%] max-w-[100px]"><Skeleton /></div>
                                                :
                                                <span className='text-xs font-medium'>{this.props.t("Idea Hub.Find ideas by")}</span>
                                            }
                                        </div>
                                        {this.state.loading ?
                                            <div className='grid grid-cols-3 gap-4 w-full text-center mb-8 '>
                                                <div><Skeleton /></div>
                                                <div><Skeleton /></div>
                                                <div><Skeleton /></div>
                                            </div>
                                            :
                                            <>
                                                <div className='grid grid-cols-3 gap-4 text-center mb-8 '>
                                                    <div onClick={() => this.getData(1, "interesting-finds", this.state.category, this.state.name, 'over')} className='bg-white border border-[#9A9A9A] cursor-pointer rounded-xl'>
                                                        <button onClick={() => this.setState({ query: "interesting-finds" })} className={`text-[#697475] md:p-4 text-[10px] md:text-xs font-bold  ${this.state.query === "interesting-finds" && 'text-black border-black'}`}>{this.props.t("Idea Hub.Interesting Finds")}</button>
                                                    </div>
                                                    <div onClick={() => this.getData(1, "most-liked", this.state.category, this.state.name, 'over')} className='bg-white border border-[#9A9A9A] cursor-pointer rounded-xl'>
                                                        <button onClick={() => this.setState({ query: "most-liked" })} className={`text-[#697475] md:p-4 text-[10px] md:text-xs font-bold  ${this.state.query === "most-liked" && 'text-black border-black'}`}>{this.props.t("Idea Hub.Most Liked")}</button>
                                                    </div>
                                                    <div onClick={() => this.getData(1, "top-rated", this.state.category, this.state.name, 'over')} className='bg-white border border-[#9A9A9A]  cursor-pointer rounded-xl'>
                                                        <button onClick={() => this.setState({ query: "top-rated" })} className={`text-[#697475] md:p-4 text-[10px] w-full h-full md:text-xs font-bold  ${this.state.query === "top-rated" && 'text-black border-black'}`}>{this.props.t("Idea Hub.Top Rated")}</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="flex justify-center items-center ">
                                        {this.state.loading ?
                                            <div className="w-[30%]"><Skeleton /></div>
                                            :
                                            <div className="w-1/4 bg-white rounded-r-md shadow">
                                                <select
                                                    value={this.state.category}
                                                    onChange={(e) => changeCategory(e)}
                                                    className='py-1 w-full px-1 rounded-md bg-[#F0F2F2] border-[#B3B3B3] border drop-shadow-[#00000040]'
                                                >
                                                    <option value="">{this.props.t("Idea Hub.All")}</option>
                                                    {this.props.categories && this.props.categories.length > 0 && this.props.categories.map((category, i) => <option key={i} value={category.category_1_id}>{category.name}</option>)}
                                                </select>
                                            </div>
                                        }
                                        {this.state.loading ?
                                            <div className="w-[70%] ml-1"><Skeleton /></div>
                                            :
                                            <div className="w-3/4 ml-1">
                                                <input
                                                    value={this.state.name}
                                                    onChange={(e) => changeName(e)}
                                                    type="text"
                                                    placeholder={this.props.t("Idea Hub.Search for products...")}
                                                    className="w-full px-4 py-1 rounded-md border border-[#B3B3B3]"
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <div>
                                            {this.state.loading ?
                                                <div className="w-[30%]"><Skeleton /></div>
                                                :
                                                <span className='text-xs font-medium'>{this.props.t("Idea Hub.Trending deals in your top categories")}</span>
                                            }
                                        </div>

                                        {this.state.products && <div>
                                            <InfiniteScroll
                                                className='grid md:grid-cols-4 grid-cols-2 gap-5 rounded-xl mb-6 no-scrollbar'
                                                next={fetchMore}
                                                dataLength={this.state.products.length}
                                                hasMore={this.state.products.length < this.state.total}
                                                loader={<RotatingLines
                                                    strokeColor="black"
                                                    strokeWidth="2"
                                                    animationDuration="0.75"
                                                    width="20"
                                                    visible={true}
                                                />}
                                            >
                                                {(this.state.products && this.state.products.length > 0) ? this.state.products.map((product, i) =>
                                                    <ProductCard key={product.product_id} token={this.props.token} product={product} />
                                                ) :
                                                    <>
                                                        {
                                                            this.state.products.map((product, i) =>
                                                                <ProductCard key={product.product_id} token={this.props.token} loading={this.state.loading} />
                                                            )
                                                        }
                                                    </>
                                                }


                                            </InfiniteScroll>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        categories: state.categories,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(IdeaHub));