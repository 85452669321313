import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Topbar from "../component/Topbar";
import { ThreeDots } from "react-loader-spinner";
import { connect } from "react-redux";
import { setToken } from "../actions";
import { toast } from "react-hot-toast";
import Loader from "../component/Loader";
import { withTranslation } from "react-i18next";

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobile: "",
            password: "",
            otp: "",
            credentialsAdded: false,
            loginWithPasswordButtonDisabled: false,
            loginWithOTPButtonDisabled: false,
            passwordType: "",
            loggedin: false,
            loading: true
        };
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate(prevProps) {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
        if (this.props.token !== prevProps.token && this.props.token !== null) {
            toast.success("Loggedin Successfully !")
            let DukandaarAffiliate = window.localStorage.getItem('Dukandaar-Affiliate');
            if (!DukandaarAffiliate) {
                DukandaarAffiliate = {};
            }
            else {
                DukandaarAffiliate = JSON.parse(DukandaarAffiliate);
            }
            DukandaarAffiliate.token = this.props.token;
            window.localStorage.setItem("Dukandaar-Affiliate", JSON.stringify(DukandaarAffiliate));
            setTimeout(() => {
                this.setState({ loggedin: true, submitButtonDisabled: false })
            }, 1000);
        }
    }
    render() {
        const setPassword = (_password) => {
            this.setState({ password: _password });
        };
        const setMobile = (_mobile) => {
            if (_mobile.length <= 10) {
                this.setState({ mobile: _mobile });
            }
        };
        const setOTP = (_otp) => {
            if (_otp.length <= 6) {
                this.setState({ otp: _otp });
            }
        };
        const continueLogin = (e, _passwordType) => {
            if (this.state.mobile.length === 10) {
                e.preventDefault();
                if (_passwordType === "password") {
                    this.setState({ loginWithPasswordButtonDisabled: true });
                }
                else {
                    this.setState({ loginWithOTPButtonDisabled: true });
                }
                setTimeout(() => {
                    this.setState({ credentialsAdded: true, passwordType: _passwordType });
                }, 1000);
            }
            else {
                e.preventDefault();
                toast.error("Enter correct mobile number !")
            }
        }
        const changeCredentials = () => {
            this.setState({ mobile: "", credentialsAdded: false, loginWithOTPButtonDisabled: false, loginWithPasswordButtonDisabled: false, password: "" });
        }
        const login = (e) => {
            e.preventDefault();
            if (this.state.passwordType === "password") {
                if (this.state.password.length < 8) {
                    toast.error("Password Wrong !")
                }
                else {

                    this.setState({ submitButtonDisabled: true });
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        "number": this.state.mobile,
                        "password": this.state.password
                    });

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };

                    fetch(`${process.env.REACT_APP_BASE_URL}/affiliate_login`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 401 || result.code === 407) {
                                this.props.logoutDispatch();
                            }
                            else {
                                if (result.status === "Success" || result.status === "success") {
                                    this.props.loginDispatch(result.token);
                                }
                                else {
                                    toast.error(result.msg)
                                }
                                this.setState({ submitButtonDisabled: false })
                            }
                        })
                        .catch(error => {
                            toast.error("Server Error!")
                        });
                }
            }
            else {
                console.log(this.state.otp);
            }
        }
        return (
            <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                <>
                    {
                        this.state.loggedin &&
                        <Navigate to={'/onboarding'} />
                    }
                    <Topbar />
                    <div className="mt-[68px] sm:mt-[88px] mb-[20px] px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] w-full">
                        <div className="flex flex-col justify-start items-center w-full max-w-[450px] m-auto">
                            {
                                !this.state.credentialsAdded ?
                                    <div className="text-[#697475] space-y-3 border-0 sm:border-2  border-[#697475] rounded-3xl sm:px-9 px-0 w-full">
                                        <h1 className="text-2xl sm:text-3xl font-medium pt-8 text-black mb-2">
                                            {this.props.t("SignIn")}
                                        </h1>
                                        <form className="flex flex-col space-y-3 text-xs">
                                            <label
                                                className="flex flex-col font-semibold mb-1"
                                                htmlFor="mobile"
                                            >
                                                {this.props.t("Signin.Mobile")}
                                                <input
                                                    id="mobile"
                                                    type="number"
                                                    required
                                                    className="border-2 py-1 rounded-lg px-3 placeholder:font-normal"
                                                    name="mobile"
                                                    minLength={10}
                                                    maxLength={10}
                                                    onChange={(e) => setMobile(e.target.value)}
                                                    value={this.state.mobile}
                                                    placeholder="Your mobile number"
                                                />
                                            </label>
                                            <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.loginWithPasswordButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => continueLogin(e, "password")} >
                                                <div className={`${!this.state.loginWithPasswordButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                    {this.props.t("Signin.Login with Password")}
                                                </div>
                                                <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.loginWithPasswordButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                    <ThreeDots
                                                        height="32"
                                                        width="30"
                                                        radius="9"
                                                        color="#000"
                                                        ariaLabel="three-dots-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClassName=""
                                                        visible={true}
                                                    />
                                                </div>
                                            </button>
                                            {/* <div className="py-[10px] w-full relative">
                                            <div className="h-[0.5px] bg-[#697475] w-full"></div>
                                            <div className="absolute top-[50%] left-[50%] py-2 px-4 translate-x-[-50%] translate-y-[-50%] bg-white">or</div>
                                        </div>
                                        <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.loginWithOTPButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => continueLogin(e, "otp")} >
                                            <div className={`${!this.state.loginWithOTPButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                Login with OTP
                                            </div>
                                            <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.loginWithOTPButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                <ThreeDots
                                                    height="32"
                                                    width="30"
                                                    radius="9"
                                                    color="#000"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={true}
                                                />
                                            </div>
                                        </button> */}
                                        </form>
                                        <div className="pb-4 sm:pb-5 border-b-2 text-sm">
                                            <h6>
                                                {this.props.t("Signin.By creating an account or logging in, you agree to")} <br />
                                                {this.props.t("Dukkandaar's")}
                                                <span className="text-[#059CA6] px-1 cursor-pointer">
                                                    {this.props.t("Signin.Conditions of Use")}
                                                </span>
                                                {this.props.t("Signin.and")}
                                                <span className="text-[#059CA6] px-1 cursor-pointer">
                                                    {this.props.t("Signin.Privacy Policy")}.
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="text-sm pb-4">
                                            <h6>
                                                {this.props.t("Signin.New to Dukkandaar")}?
                                                <Link to={"https://www.dukkandaar.com/register"}>
                                                    <span className="text-[#059CA6] px-1 cursor-pointer">
                                                        {this.props.t("Signin.Register Now")}
                                                    </span>
                                                </Link>
                                            </h6>
                                        </div>
                                    </div> :
                                    (
                                        this.state.passwordType === "otp" ?
                                            <div className="space-y-3 border-0 sm:border-2 border-[#697475] rounded-3xl sm:px-9 px-3 w-full">
                                                <h1 className="text-2xl text-black pt-8 ">{this.props.t("Signin.Enter OTP")}</h1>
                                                <form className="flex flex-col space-y-2 sm:pb-8">
                                                    <div className="flex text-sm space-x-1 justify-between items-center">
                                                        <div className="flex">
                                                            <p className="text-[#697475] font-bold">
                                                                {this.props.t("Signin.+91")} {this.state.mobile}
                                                            </p>
                                                            <p className="text-[#059CA6] ml-2 cursor-pointer" onClick={() => changeCredentials()}>
                                                                {this.props.t("Signin.Change")}
                                                            </p>
                                                        </div>
                                                        <p className="text-[#059CA6] text-end text-xs">
                                                            {this.props.t("Signin.Resend OTP")}
                                                        </p>
                                                    </div>
                                                    <input
                                                        id="otp"
                                                        type="password"
                                                        name="otp"
                                                        required
                                                        maxLength={6}
                                                        className="border-2 py-1 rounded-lg px-3"
                                                        value={this.state.otp}
                                                        onChange={(e) => setOTP(e.target.value)}
                                                    />
                                                    <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.submitButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => login(e)} >
                                                        <div className={`${!this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            {this.props.t("Signin.Submit")}
                                                        </div>
                                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            <ThreeDots
                                                                height="32"
                                                                width="30"
                                                                radius="9"
                                                                color="#000"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </button>
                                                </form>
                                            </div> :
                                            <div className="space-y-3 border-0 sm:border-2 border-[#697475] rounded-3xl sm:px-9 px-3 w-full">
                                                <h1 className="text-2xl text-black pt-8 ">{this.props.t("Signin.Enter Password")}</h1>
                                                <form className="flex flex-col space-y-2 sm:pb-8">
                                                    <div className="flex text-sm space-x-1 justify-between items-center">
                                                        <div className="flex">
                                                            <p className="text-[#697475] font-bold">
                                                                {this.props.t("Signin.+91")} {this.state.mobile}
                                                            </p>
                                                            <p className="text-[#059CA6] ml-2 cursor-pointer" onClick={() => changeCredentials()}>
                                                                {this.props.t("Signin.Change")}
                                                            </p>
                                                        </div>
                                                        <p className="text-[#059CA6] text-end text-xs">
                                                            {this.props.t("Signin.Forgot Password")}
                                                        </p>
                                                    </div>
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        required
                                                        minLength={8}
                                                        className="border-2 py-1 rounded-lg px-3"
                                                        value={this.state.password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <button type="submit" className={`text-black rounded-full font-semibold box-shadow h-[32px] relative ${this.state.submitButtonDisabled ? 'bg-[#a38b0ea1]' : 'bg-[#FFD814]'}`} onClick={(e) => login(e)} >
                                                        <div className={`${!this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            {this.props.t("Submit")}
                                                        </div>
                                                        <div className={`absolute top-0 left-[50%] translate-x-[-50%] ${this.state.submitButtonDisabled ? "opacity-100" : "opacity-0"} duration-75 ease-in`}>
                                                            <ThreeDots
                                                                height="32"
                                                                width="30"
                                                                radius="9"
                                                                color="#000"
                                                                ariaLabel="three-dots-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClassName=""
                                                                visible={true}
                                                            />
                                                        </div>
                                                    </button>
                                                </form>
                                            </div>
                                    )
                            }
                            <div className="flex text-sm  text-[#059CA6] justify-between sm:justify-center mt-3 sm:space-x-12 w-full">
                                <p className="cursor-pointer">{this.props.t("Signin.Conditions of Use")}</p>
                                <p className="cursor-pointer">{this.props.t("Signin.Privacy Notice")}</p>
                                <p className="cursor-pointer">{this.props.t("Signin.Help")}</p>
                            </div>
                            <div className="text-center text-[#697475] text-sm mt-1">
                                © {this.props.t("@")}
                            </div>
                        </div>
                    </div>
                </>
                {
                    this.state.loading && <Loader />
                }
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    const loginDispatch = (token) => {
        dispatch(setToken(token));
    };

    return {
        loginDispatch
    };
};

const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Signin));