import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import Navbar from '../component/Navbar'
import Nav from '../component/Nav'
import Notice from '../component/Notice'
import Footer from '../component/HomeFooter';
import { Breadcrumbs } from '@mui/material'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

//importing image
import news from '../assets/images/news.png'
import ReactApexChart from 'react-apexcharts'
import Loader from '../component/Loader'
import { withTranslation } from 'react-i18next'
import AccessDenied from '../component/ProtectRoutes/AccessDenied'

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product: '',
            showFilters: false,
            options: {
                series: [
                    {
                        name: 'Fees',
                        type: 'line',
                        data: ['0', '0.50', '1.00', '1.50', '0.50', '1.00', '1.50', '0.50', '1.00', '1.50', '0.50'],
                        style: {
                            color: "#275272"
                        }
                    }],
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#F0B83A'],
                stroke: {
                    width: [3, 0],
                    curve: 'straight'
                },
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: [1]
                },
                labels: ['1', '3', '6', '9', '12', '15', '18', '21', '24', '27', '30'],
                xaxis: {
                    type: 'numbers',
                    labels: {
                        style: {
                            colors: '#275272',
                        }
                    },
                    axisBorder: {
                        show: true,
                        color: '#58E2C2',
                    },
                },
                yaxis: [
                    {
                        axisBorder: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: '#275272',
                            }
                        },
                    }],
            },
            loading: true
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    handleProductChange = (event) => {
        this.setState({ product: event.target.value });
    };
    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }
    handleClickOutside(event) {
        if (
            this.state.showFilters &&
            this.wrapperRef &&
            !this.wrapperRef.current.contains(event.target)
        ) {
            this.setState({ showFilters: false });
        };
    }
    render() {
        const showFilter = () => {
            this.setState({ showFilters: true });
        };
        return (
            <>
                {this.props.token ? (
                    <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                        <div>
                            <Topbar />
                            <Navbar />
                            <Nav />

                            {this.state.loading ?
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice loading={this.state.loading} />

                                        <div className='bg-[#FEECB3] mt-4 p-2 px-4'>
                                            <Link className='flex text-[#347AE2] '>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="md:w-5 md:h-5 w-4 h-4">
                                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                                </svg>
                                                <div className="w-[20%] "><Skeleton /></div>
                                            </Link>
                                        </div>
                                        <div className='w-full h-[300px] mt-4 md:h-[450px]'><Skeleton className='w-full h-[300px] md:h-[450px]' /></div>
                                        <div className='block md:flex'>
                                            <div className='w-full md:w-3/5'>
                                                <div className=' text-xs text-[#275272] font-medium mt-4 rounded-lg '>
                                                    <div className='w-full'><Skeleton className='w-full ' height={100} /></div>
                                                </div>
                                                <div className=' text-xs md:text-sm text-[#275272] font-medium mt-2 rounded-lg'>
                                                    <div className='w-full'><Skeleton className='w-full ' height={100} /></div>
                                                </div>
                                                <div className=' text-xs md:text-sm text-[#275272] font-medium mt-2 rounded-lg border-[#F89C15]'>
                                                    <ul className='flex w-full'>
                                                        <div className='w-full'><Skeleton className='w-full ' height={80} /></div>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='w-full md:w-2/5 p-2'>
                                                <div className=' text-xs md:text-sm text-[#275272] font-medium mt-2 rounded-lg'>
                                                    <div className='w-full'><Skeleton className='w-full h-[300px]' /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='w-full mb-3 px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] mt-[100px] md:mt-[150px] rounded-[10px] md:rounded-[20px]'>
                                        <Notice />
                                        <div className='bg-[#FEECB3] mt-4 p-2 px-4'>
                                            <Link className='flex text-[#347AE2] '>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="md:w-5 md:h-5 w-4 h-4">
                                                    <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                                                </svg>
                                                <div className='text-xs md:text-sm font-medium'>{this.props.t("Consolidated Summary")}</div>
                                            </Link>
                                        </div>
                                        <div className='bg-[#D7D7D72E] border-2 mt-4 rounded-[20px] p-2 md:pl-8 border-[#F89C15]'>
                                            <div className='flex justify-between'>
                                                <Breadcrumbs aria-label="breadcrumb" separator="›">
                                                    <Link to="/report" className="text-[#407BF9] text-xs font-medium">
                                                        {this.props.t("Report")}
                                                    </Link>
                                                    <Link
                                                        className="text-[#407BF9] text-xs font-medium"
                                                        style={{ color: "#000000" }}
                                                    >
                                                        {this.props.t("Summary")}
                                                    </Link>
                                                </Breadcrumbs>
                                                <div className='block md:hidden '>
                                                    <div className='mr-2'>
                                                        <svg width="110" onClick={() => showFilter()} height="31" viewBox="0 0 143 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g filter="url(#filter0_d_271_710)">
                                                                <rect x="4" width="135" height="33" rx="8" fill="#D9DEE4" />
                                                            </g>
                                                            <defs>
                                                                <filter id="filter0_d_271_710" x="0" y="0" width="143" height="41" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                    <feOffset dy="4" />
                                                                    <feGaussianBlur stdDeviation="2" />
                                                                    <feComposite in2="hardAlpha" operator="out" />
                                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_271_710" />
                                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_271_710" result="shape" />
                                                                </filter>
                                                            </defs>
                                                        </svg>

                                                        {this.state.showFilters && (
                                                            <div
                                                                ref={this.wrapperRef}
                                                                className="absolute rounded-xl right-3.5 bg-[#F0F2F2] z-50"
                                                            >
                                                                <div className="w-[140px] h-fit text-[#0B0909] text-xs font-medium">
                                                                    <ul className='block my-1'>
                                                                        <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Fee Schedule")}</li>
                                                                        <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Payment History")}</li>
                                                                        <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Download Reports")}</li>
                                                                        <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Feedback")}</li>
                                                                        <li className='p-1 pl-5 hover:bg-[#BEBEBE] hover:text-[#fff]'>{this.props.t("Help")}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='md:block hidden border-l-2 -mr-1 border-b-2 place-self-center -mt-4 border-[#F89C15] bg-[#D7D7D72E] rounded-tr-xl rounded-bl-xl'>
                                                    <ul className='flex text-xs text-[#565656]'>
                                                        <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                            <span> {this.props.t("Fee Schedule")}</span>
                                                        </li>
                                                        <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                            <span>{this.props.t("Payment History")}</span>
                                                        </li>
                                                        <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                            <span>{this.props.t("Download Reports")}</span>
                                                        </li>
                                                        <li className='border-r-2 border-[#F89C15] p-1 px-1'>
                                                            <span>{this.props.t("Feedback")}</span>
                                                        </li>
                                                        <li className='p-1 px-1'>
                                                            <span>{this.props.t("Help")}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='flex justify-between'>
                                                <div className='flex text-[10px] md:text-xs text-[#565656]'>
                                                    <span>{this.props.t("May 25 2023 - Jun 23 2023 / Last 30 Days")}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                    </svg>
                                                    <span>{this.props.t("Tracking ID")}: {this.props.t("All")}</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                                        <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <span className='text-xs hidden md:block text-[#565656]'>{this.props.t("Last Updated")}: {this.props.t("Jun 23 2023 + 00:00")}</span>
                                                </div>
                                            </div>
                                            <div className='flex text-xs mt-2'>
                                                <Link to={'/report'} className='md:w-1/6 w-1/3'>
                                                    <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                        <div className='text-[#565656]'>{this.props.t("Summary")}</div>
                                                        <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                                    </div>
                                                </Link>
                                                <Link to={'/fees'} className='md:w-1/6 w-1/3'>
                                                    <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                        <div className='text-[#565656]'>{this.props.t("Fees")}</div>
                                                        <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                                    </div>
                                                </Link>
                                                <Link to={'/bounties'} className='md:w-1/6 w-1/3'>
                                                    <div className='border m-1 block font-medium -[#D9D9D9] rounded-lg p-3 border-[#F89C15] bg-white'>
                                                        <div className='text-[#565656]'>{this.props.t("Bounties")}</div>
                                                        <div className='text-sm font-semibold md:py-2'>{this.props.t("₹0.00")}</div>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className='md:pr-8 pr-2'>
                                                <div id="chart">
                                                    <ReactApexChart options={this.state.options} series={this.state.options.series} type="line" height={350} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='block md:flex'>
                                            <div className='w-full md:w-3/5'>
                                                <div className='bg-[#FFFFFF] text-xs text-[#275272] font-medium border mt-4 rounded-lg border-[#F89C15]'>
                                                    <div className='text-[#D90000] text-sm my-2 pl-2 md:pl-8'>{this.props.t("Reports.Fees Summary")}</div>
                                                    <div className='mb-2'>
                                                        <ul className='flex w-full'>
                                                            <li className='border border-[#F89C15] w-1/4 border-l-0'>
                                                                <div className=' block'>
                                                                    <div className='p-2 px-4'>{this.props.t("Reports.Clicks")}</div>
                                                                    <div className='p-2 px-4'>{this.props.t("1")}</div>
                                                                </div>
                                                            </li>
                                                            <li className='border border-[#F89C15] w-1/4 border-l-0'>
                                                                <div className=' block'>
                                                                    <div className='p-2 px-4'>{this.props.t("Reports.Ordered")}</div>
                                                                    <div className='p-2 px-4'>{this.props.t("0")}</div>
                                                                </div>
                                                            </li>
                                                            <li className='border border-[#F89C15] w-1/4 border-l-0'>
                                                                <div className=' block'>
                                                                    <div className='p-2 px-4'>{this.props.t("Reports.Shipped")}</div>
                                                                    <div className='p-2 px-4'>{this.props.t("Reports.0")}</div>
                                                                </div>
                                                            </li>
                                                            <li className='border border-[#F89C15] w-1/4 border-l-0 border-r-0'>
                                                                <div className=' block'>
                                                                    <div className='p-2 md:px-4'>{this.props.t("Reports.")}</div>
                                                                    <div className='p-2 px-4'>{this.props.t("0.00")}%</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className='text-sm text-end md:p-3 pr-10 md:pr-20 text-[#000000]'>
                                                        <span>{this.props.t("Earnings")}:</span>
                                                        <span className='ml-2'>{this.props.t("₹0.00")}</span>
                                                    </div>
                                                </div>
                                                <div className='bg-[#FFFFFF] text-xs md:text-sm text-[#275272] font-medium border mt-2 rounded-lg border-[#F89C15]'>
                                                    <div className='text-[#D90000] text-sm my-2 pl-2 md:pl-8'>{this.props.t("Reports.Bountie Summary")}</div>
                                                    <div>
                                                        <ul className='flex w-full'>
                                                            <li className='border border-[#F89C15] w-3/4 border-l-0 border-b-0'>
                                                                <div className=' block'>
                                                                    <div className='p-2 px-4'>{this.props.t("Reports.Total Number of referrals")}</div>
                                                                    <div className='p-2 px-4'>{this.props.t("0")}</div>
                                                                </div>
                                                            </li>
                                                            <li className='border-t border-[#F89C15] w-1/4'>
                                                                <div className=' block'>
                                                                    <div className='p-2 px-4 font-bold text-[#000]'>{this.props.t("Earnings")}</div>
                                                                    <div className='p-2 px-4 text-[#128400]'>{this.props.t("₹0.00")}</div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='bg-[#FFFFFF] text-xs md:text-sm text-[#275272] font-medium border mt-2 rounded-lg border-[#F89C15]'>
                                                    <ul className='flex w-full'>
                                                        <li className='w-3/4 border-l-0 border-b-0'>
                                                            <div className='p-2 px-4 text-[#D90000] border-r border-[#F89C15]'>{this.props.t("Reports.Total Earnings Summary")}</div>
                                                        </li>
                                                        <li className='w-1/4'>
                                                            <div className='p-2 px-4 font-bold text-[#000]'>{this.props.t("₹0.00")}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='w-full md:w-2/5 p-2'>
                                                <div className='bg-[#FFFFFF] text-xs md:text-sm text-[#275272] font-medium border mt-2 rounded-lg border-[#F89C15]'>
                                                    <div className='text-[#D90000] py-3 border-b border-[#F89C15]'>
                                                        <span className='ml-4'>{this.props.t("Reports.Top News & Promotions")}</span>
                                                    </div>
                                                    <div>
                                                        <img src={news} alt='news' className='rounded-lg' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <Footer />
                        </div>
                        {
                            this.state.loading && <Loader />
                        }
                    </div>
                ) : (
                    <div><AccessDenied /></div>
                )}
            </>
        )
    }
}
export default withTranslation()(Report);