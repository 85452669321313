import React, { Component } from 'react'
import Topbar from '../component/Topbar'
import banner from '../assets/images/home-banner.png'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux';
import Loader from '../component/Loader';
import tap from '../assets/icons/tap.png'
import invoice from '../assets/icons/invoice.png'
import salary from '../assets/icons/salary.png'
import HomeFooter from '../component/HomeFooter';
import logo from '../assets/images/logo.png'
import carousel_0 from '../assets/images/carousel_0.jpg';
import carousel_1 from '../assets/images/carousel_1.jpg';
import carousel_2 from '../assets/images/carousel_2.jpg';
import carousel_3 from '../assets/images/carousel_3.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Carousel from '../component/Carousel';
import { withTranslation } from 'react-i18next';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirected: null,
            loading: true,
            data: [
                {
                    image: carousel_0,
                    para: `${this.props.t("MainPage.carousel_0")}`,
                    name: `${this.props.t("MainPage.BuzzFeed")}`,
                },
                {
                    image: carousel_1,
                    para: `${this.props.t("MainPage.carousel_1")}`,
                    name: `${this.props.t("MainPage.Fire Food Chef")}`,
                },
                {
                    image: carousel_2,
                    para: `${this.props.t("MainPage.carousel_2")}`,
                    name: `${this.props.t("MainPage.Impremedia")}`,
                },
                {
                    image: carousel_3,
                    para: `${this.props.t("MainPage.carousel_3")}`,
                    name: `${this.props.t("MainPage.Domino")}`,
                },
            ]
        }
    }
    componentDidMount() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
    }
    componentDidUpdate() {
        if (this.state.loading && this.props.loadingCompleted) {
            this.setState({ loading: false });
        }
        if (this.props.token && this.props.affiliate_info && !this.state.redirected) {
            if (this.props.affiliate_info.affiliate_profile_info) {
                if (this.props.affiliate_info.affiliate_bank_details_info) this.setState({ redirected: "dashboard" });
                else this.setState({ redirected: "banking-details" });
            }
            else {
                this.setState({ redirected: "onboarding" });
            }
        }
    }
    render() {
        return (
            <div className={`relative w-full ${this.state.loading ? 'h-[100vh]' : 'h-auto'} overflow-hidden`}>
                <div>
                    {
                        this.state.redirected && <Navigate to={`/${this.state.redirected}`} />
                    }
                    <Topbar />
                    <div className='mt-[68px] sm:mt-[88px] px-[2%] sm:px-[5%] md:px-[15%] lg1:px-[20%] w-full'>
                        <div className='w-full aspect-[calc(1372/374)] relative mb-[30px] sm:mb-[50px]'>
                            <img src={banner} className='w-full' alt="" />
                            <div className='absolute w-full h-full top-0 left-0 bg-[#00000070] flex flex-col justify-center items-center'>
                                <div className='text-white font-semibold text-sm dxs:text-base sm:text-xl'>{this.props.t("MainPage.Recommend Products")}</div>
                                <Link to={'/signin'} className='bg-[#FFD814] text-[#565656] border border-[#B3B3B3] mt-[5px] sm:mt-[10px] rounded-full py-1 sm:py-2 px-3 sm:px-4 cursor-pointer text-[10px] sm:text-xs box-shadow font-medium'>{this.props.t("Continue")}</Link>
                            </div>
                        </div>
                        <div className='text-center'>
                            <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Dukkandaar Associates")}</p>
                            <p className='text-xs leading-5'><span className='font-medium'>{this.props.t("MainPage.Dukkandaar Associates1")}.</span> <br />
                            {this.props.t("MainPage.Dukkandaar Associates2")}.</p>
                        </div>
                        <div>
                            <div className='md:flex items-center justify-between'>
                                <div className='m-2 mt-4 grid place-items-center'>
                                    <div className='w-12'><img src={tap} alt='icon' /></div>
                                    <div className='p-2'><div className='bg-black flex items-center justify-center rounded-full w-5 h-5 text-center text-white font-bold'>{this.props.t("1")}</div></div>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("Sign up")}</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Sign up1")}.</p>
                                </div>
                                <div className='m-2 mt-4 grid place-items-center'>
                                    <div className='w-12'><img src={invoice} alt='icon' /></div>
                                    <div className='p-2'><div className='bg-black flex items-center justify-center rounded-full w-5 h-5 text-center text-white font-bold'>{this.props.t("2")}</div></div>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Recommend")}</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Recommend1")}.</p>
                                </div>
                                <div className='m-2 mt-4 grid place-items-center'>
                                    <div className='w-12'><img src={salary} alt='icon' /></div>
                                    <div className='p-2'><div className='bg-black flex items-center justify-center rounded-full w-5 h-5 text-center text-white font-bold'>{this.props.t("3")}</div></div>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Earn")}</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Earn1")}.</p>
                                </div>
                            </div>
                        </div>
                        <div className='md:my-16'>
                            <Swiper
                                modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                loop={true}
                            >
                                {this.state.data.map((carousel, i) =>
                                <SwiperSlide key={i}>
                                    <Carousel carousel={carousel} />
                                </SwiperSlide>
                                )}
                            </Swiper>

                        </div>
                        <div>
                            <div className='text-xl my-5 font-medium'>{this.props.t("MainPage.Frequently Asked Questions")}</div>
                            <div className='md:flex'>
                                <div className=' md:w-[50%] p-2'>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Ques1")}?</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Ans1")} <span className='hover:underline hover:text-[#C7511F] text-[#007185] cursor-pointer'>{this.props.t("Learn more")}.</span></p>
                                </div>
                                <div className=' md:w-[50%] p-2'>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Ques2")}?</p>
                                    <p className='text-xs leading-5'> {this.props.t("MainPage.Ans2")} <span className='hover:underline hover:text-[#C7511F] text-[#275272] cursor-pointer'>{this.props.t("Learn more")}.</span></p>
                                </div>
                            </div>
                            <div className='md:flex'>
                                <div className=' md:w-[50%] p-2'>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Ques3")}?</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Ans3")} <span className='hover:underline hover:text-[#C7511F] text-[#275272] cursor-pointer'>{this.props.t("Learn more")}.</span></p>
                                    <p className='text-xs leading-5 mt-2'>{this.props.t("MainPage.Ans31")} <span className='hover:underline hover:text-[#C7511F] text-[#275272] cursor-pointer'>{this.props.t("MainPage.Ans32")}.</span></p>
                                </div>
                                <div className=' md:w-[50%] p-2'>
                                    <p className='text-lg font-semibold mb-2 '>{this.props.t("MainPage.Ques4")}?</p>
                                    <p className='text-xs leading-5'>{this.props.t("MainPage.Ans4")} <span className='hover:underline hover:text-[#C7511F] text-[#275272] cursor-pointer'>{this.props.t("here")}.</span></p>
                                    <p className='text-xs leading-5 mt-2'>{this.props.t("MainPage.Ans41")}. <span className='hover:underline hover:text-[#C7511F] text-[#275272] cursor-pointer'>{this.props.t("Learn more")}.</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='bg-[#fff9e9] hidden md:flex mt-10'>
                            <div className='border-r border-[#e7e7e7] w-[30%]'><img src={logo} className='p-16  mix-blend-multiply' alt='logo' /></div>
                            <div>
                                <p className='p-8 text-xl'>{this.props.t("MainPage.Recommend Products")}</p>
                                <div className='p-8'><Link to={'/signin'} className='bg-[#FFD814] text-[#565656] border border-[#B3B3B3] mt-[5px] sm:mt-[10px] rounded-2xl py-1 sm:py-1 px-2 sm:px-3 cursor-pointer text-[10px] sm:text-xs box-shadow font-medium'>{this.props.t("Continue")}</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.loading && <Loader />
                }
                <HomeFooter />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        token: state.token,
        affiliate_info: state.affiliate_info
    };
};
export default connect(
    mapStateToProps,
    null
)(withTranslation()(Home));